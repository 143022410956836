import React from "react";
import {
    Typography,
    TextField,
    Button,
    FormControl,
    FormHelperText,
    CircularProgress,
    Card,
    CardContent,
    Tabs,
    Tab,
    Snackbar
} from "@material-ui/core";
import "./SignUpStyles.css";
import { connect } from "react-redux";
import axios from "axios";

import { api } from "../../services";
import { mapStateToProps } from "../../redux";
import { signUpDefault, signOut } from "../../Actions/authActions/actions";
import { setLanguage, setDirectlyLanguage } from "../../Actions/languageAction/actions";

import logo from "../../Images/logo_hyponav_beta.svg";

class SignUp extends React.Component {
    state = {
        email: "",
        password: "",
        firstName: "",
        prefix: "",
        lastName: "",
        confirmPassword: "",
        companyName: "",
        open: false,
        errors: {
            email: false,
            password: false,
            firstName: false,
            prefix: false,
            lastName: false,
            confirmPassword: false,
            companyName: false
        },
        companyErrorMessage: "",
        emailErrorMessage: "",
        isLoading: false,
        role: "basic",
        invitation: false,
        loading: true
    };

    componentDidMount = () => {
        if (window.location.href.indexOf("invitation-token") > -1) {
            this.getRoleFromInvite();
        } else {
            this.setState({ loading: false });
        }
    };

    handleCloseSnack = () => {
        this.setState({ open: false });
    };

    extractToken = token => {
        if (token.includes("token")) {
            return token.replace("?invitation-token=", "");
        } else {
            return null;
        }
    };

    onInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    signUp = () => {
        const { email, password, confirmPassword, firstName, lastName, errors, role, companyName, prefix } = this.state;
        const { lang } = this.props;
        this.setState({ isLoading: true });

        if (firstName.length === 0) {
            this.setState({ errors: { ...errors, firstName: true }, isLoading: false });
            return;
        } else {
            this.setState({ errors: { ...errors, firstName: false } });
        }

        if (lastName.length === 0) {
            this.setState({ errors: { ...errors, lastName: true }, isLoading: false });
            return;
        } else {
            this.setState({ errors: { ...errors, lastName: false } });
        }

        if (role !== "basic" && companyName.length === 0) {
            this.setState({ errors: { ...errors, companyName: true }, isLoading: false });
            return;
        } else {
            this.setState({ errors: { ...errors, companyName: false } });
        }

        //checking if the 2 passwords match
        if (confirmPassword !== password) {
            this.setState({ errors: { ...errors, confirmPassword: true }, isLoading: false });
            return;
        } else {
            this.setState({ errors: { ...errors, confirmPassword: false } });
        }

        this.props
            .signUpDefault({
                firstName: firstName,
                prefix: prefix,
                lastName: lastName,
                email: email,
                password: password,
                userName: firstName,
                confirmPassword: confirmPassword,
                role: role,
                companyName: companyName,
                invitation_token: this.extractToken(this.props.location.search)
            })
            .then(response => {
                if (response.code === "email/sent") {
                    this.props.history.push("/registratie-bevestiging");
                }
            })
            .catch(error => {
                this.setState({
                    errors: {
                        ...errors,
                        email: error.code.includes("email") || error.code === "email/taken" ? true : false,
                        prefix: error.code.includes("prefix") ? true : false,
                        password: error.code.includes("password") ? true : false,
                        companyName: error.code.includes("companyName") ? true : false
                    },
                    isLoading: false,
                    open:
                        error.message === undefined || error.message[0].message === undefined
                            ? false
                            : error.message[0].message.includes("Company with name")
                            ? true
                            : false,
                    companyErrorMessage: error.code.includes("companyName")
                        ? "Please enter a valid company name."
                        : "There is already a registered account with this company name. If you want to make a second account please contact the owner of the first account and create a manager account in his own advisor panel. In case you don't know who is the owner of the first account please contact the support.",
                    emailErrorMessage:
                        error.code === "email/taken"
                            ? lang.registration.email_address_validation_existing
                            : lang.registration.email_address_validation_format_incorrect
                });
            });
    };

    getRoleFromInvite = () => {
        this.setState({ loading: true });
        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url: `${api.url}/resources`,
                // url: `http://localhost:3000/v1/resources`,
                data: {
                    query: `query{
                    get_invitation(token:"${this.extractToken(this.props.location.search)}"){
                        role
                        language
                        email
                        first_name
                        prefix
                        last_name
                    }
                  }`
                },
                withCredentials: false
            })
                .then(response => {
                    this.setState({ role: response.data.data.get_invitation.role });
                    this.setState({ invitation: true });
                    this.setState({ loading: false });
                    this.setState({ firstName: response.data.data.get_invitation.first_name });
                    this.setState({ prefix: response.data.data.get_invitation.prefix });
                    this.setState({ lastName: response.data.data.get_invitation.last_name });
                    this.setState({ email: response.data.data.get_invitation.email });
                    this.props.setDirectlyLanguage(response.data.data.get_invitation.language);
                    resolve(response.data.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    };

    redirectToLogin = () => {
        this.props.history.push("/inloggen");
    };

    handleTabChange = (event, role) => {
        this.setState({ role: role });
    };

    render() {
        const { errors, isLoading, emailErrorMessage, role, companyErrorMessage, open, invitation } = this.state;
        const { lang } = this.props;

        return this.state.loading ? (
            <div />
        ) : (
            <div className={"dom"}>
                <img src={logo} alt="Logo" style={{ width: "300px", margin: "40px 0" }} />
                <Card className={"card"}>
                    <CardContent className={"card-content"}>
                        <Typography variant={"h2"} style={{ margin: "20px 0" }}>
                            {lang.registration.title}
                        </Typography>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => {
                                this.props.setLanguage(null);
                            }}
                        >
                            {lang.registration.button_language}
                        </Button>

                        {invitation === true ? (
                            <Tabs value={role} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                                <Tab label={role === "basic" ? "consumer" : role} value={role} className={"tabs"} />
                            </Tabs>
                        ) : (
                            <Tabs value={role} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                                <Tab label={lang.registration.type_consumer_label} value="basic" className={"tabs"} />
                                <Tab label={lang.registration.type_advisor_label} value="advisor" className={"tabs"} />
                            </Tabs>
                        )}
                        {role === "advisor" && invitation === false ? (
                            <FormControl error={errors.companyName} className={"sign-form-input"}>
                                <TextField
                                    error={errors.companyName}
                                    label={lang.registration.company_name_label}
                                    variant={"outlined"}
                                    className={"sign-input"}
                                    type="text"
                                    name="companyName"
                                    onChange={this.onInputChange}
                                    margin="normal"
                                />
                                {errors.companyName ? (
                                    <FormHelperText className={"error-message"}>{companyErrorMessage}</FormHelperText>
                                ) : (
                                    undefined
                                )}
                            </FormControl>
                        ) : null}
                        <FormControl error={errors.firstName} className={"sign-form-input"}>
                            <TextField
                                error={errors.firstName}
                                label={lang.registration.first_name_label}
                                variant={"outlined"}
                                className={"sign-input"}
                                type="text"
                                name="firstName"
                                onChange={this.onInputChange}
                                margin="normal"
                                value={this.state.firstName}
                            />
                            {errors.firstName ? (
                                <FormHelperText className={"error-message"}>The field must not be empty.</FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>
                        <FormControl error={errors.prefix} className={"sign-form-input"}>
                            <TextField
                                error={errors.prefix}
                                label={lang.registration.infix_label}
                                variant={"outlined"}
                                className={"sign-input"}
                                type="text"
                                name="prefix"
                                onChange={this.onInputChange}
                                margin="normal"
                                value={this.state.prefix}
                            />
                            {errors.prefix ? (
                                <FormHelperText className={"error-message"}>{lang.registration.infix_validation}</FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>
                        <FormControl error={errors.lastName} className={"sign-form-input"}>
                            <TextField
                                error={errors.lastName}
                                label={lang.registration.last_name_label}
                                variant={"outlined"}
                                className={"sign-input"}
                                type="text"
                                name="lastName"
                                onChange={this.onInputChange}
                                margin="normal"
                                value={this.state.lastName}
                            />
                            {errors.lastName ? (
                                <FormHelperText className={"error-message"}>The field must not be empty.</FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>
                        <FormControl error={errors.email} className={"sign-form-input"}>
                            <TextField
                                error={errors.email}
                                label={lang.registration.email_address_label}
                                variant={"outlined"}
                                className={"sign-input"}
                                type="email"
                                name="email"
                                onChange={this.onInputChange}
                                autoComplete="email"
                                margin="normal"
                                value={this.state.email}
                            />
                            {errors.email ? <FormHelperText className={"error-message"}>{emailErrorMessage}</FormHelperText> : undefined}
                        </FormControl>

                        <FormControl error={errors.password} className={"sign-form-input"}>
                            <TextField
                                error={errors.password}
                                label={lang.registration.password_label}
                                className={"sign-input"}
                                variant={"outlined"}
                                type="password"
                                name="password"
                                onChange={this.onInputChange}
                                autoComplete="current-password"
                                margin="normal"
                            />
                            {errors.password ? (
                                <FormHelperText className={"error-message"}>
                                    {lang.registration.password_validation_size_incorrect}
                                </FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>

                        <FormControl error={errors.confirmPassword} className={"sign-form-input"}>
                            <TextField
                                error={errors.confirmPassword}
                                label={lang.registration.password_confirm_label}
                                className={"sign-input"}
                                variant={"outlined"}
                                type="password"
                                name="confirmPassword"
                                onChange={this.onInputChange}
                                autoComplete="current-password"
                                margin="normal"
                            />
                            {errors.confirmPassword ? (
                                <FormHelperText className={"error-message"}>{lang.registration.password_confirm_validation}</FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>
                        <Button size="large" color="secondary" variant="contained" className={"sign-up-button"} onClick={this.signUp}>
                            {isLoading ? <CircularProgress className={"loader"} /> : lang.registration.button_register}
                        </Button>

                        {invitation === false ? (
                            <div className={"sign-help"}>
                                <Button variant="text" color="primary" onClick={this.redirectToLogin}>
                                    {lang.registration.button_login}
                                </Button>
                            </div>
                        ) : null}
                    </CardContent>
                </Card>
                <Snackbar
                    open={open}
                    onClose={this.handleCloseSnack}
                    message={<span id="message-id">{companyErrorMessage}</span>}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, { signUpDefault, signOut, setLanguage, setDirectlyLanguage })(SignUp);
