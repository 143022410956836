import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, Tooltip, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useContext, useState, useEffect } from "react";

import { createLog } from "../../../Actions/logActions/actions";
import { mapStateToProps } from "../../../redux";

import { CalculatorContext } from "../../../Contexts/CalculatorContext";

import { BootstrapInput } from "../../Inputs/BootstrapInput";

function MortgageDurationDialog(props) {
    const context = useContext(CalculatorContext);

    const [availableOptions] = useState([
        { label: "15", value: 15 },
        { label: "16", value: 16 },
        { label: "17", value: 17 },
        { label: "18", value: 18 },
        { label: "19", value: 19 },
        { label: "20", value: 20 },
        { label: "21", value: 21 },
        { label: "22", value: 22 },
        { label: "23", value: 23 },
        { label: "24", value: 24 },
        { label: "25", value: 25 },
        { label: "26", value: 26 },
        { label: "27", value: 27 },
        { label: "28", value: 28 },
        { label: "29", value: 29 },
        { label: "30", value: 30 },
    ]);

    useEffect(() => {
        if (props.open) {
            context.state.checkPoint();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    const handleMainAction = () => {
        const { onClose, createLog, auth, role } = props;
        const { functions, product } = context;
        //some action
        functions.requestProduct(product.code).then((newProduct) => {
            functions.requestCalculation().then(() => {
                functions.requestMaxMortgageFromIncomeCalculation().then((newMaxMortgageIncome) => {
                    createLog(auth, { ...context, product: newProduct, maxMortgageAmountIncome: newMaxMortgageIncome }, role);
                });

                onClose();
            });
        });
    };

    const handleCancelAction = () => {
        const { onClose } = props;

        context.state.rollback().then(() => {
            onClose();
        });
    };

    const { parts } = context;
    const { open, lang, theme } = props;

    return (
        <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
            <DialogTitle>{lang.duration.modal_title}</DialogTitle>
            <DialogContent className={"dialog-content"}>
                <div className={"content-wrapper"}>
                    <div className={"content-side"}>
                        <div className={"dialog-input"}>
                            <div className={"input-label"}>
                                <label htmlFor={`duration`}>{lang.duration.duration_part_1_label}</label>
                            </div>
                            <div className={"input-help-wrapper"}>
                                <div className={"input-field"}>
                                    <Select
                                        style={{ width: "39%", marginRight: 5 }}
                                        value={parts.parts[0].duration.value}
                                        onChange={(event) => {
                                            parts.setPartDuration(event.target.value, 0);
                                            if (typeof parts.parts[1] !== "undefined") {
                                                parts.setPartDuration(event.target.value, 1);
                                            }
                                        }}
                                        input={<BootstrapInput name={`duration`} id={`duration`} />}
                                    >
                                        {availableOptions.map((option, index) => {
                                            return (
                                                <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </div>
                                <div className={"input-help"}>
                                    <Tooltip title={lang.duration.duration_part_1_help} disableFocusListener disableTouchListener>
                                        <Help style={{ color: theme.palette.secondary.main }} />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"content-side"}>
                        <Typography>{lang.duration.duration_explanation_par_1}</Typography>
                        <Typography>{lang.duration.duration_explanation_par_2}</Typography>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelAction} color="secondary">
                    {lang.duration.button_cancel}
                </Button>
                <Button onClick={handleMainAction} color="secondary" variant={"contained"}>
                    {lang.duration.button_apply}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

MortgageDurationDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default connect(mapStateToProps, { createLog })(withTheme(MortgageDurationDialog));
