import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, Tooltip, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { createLog } from "../../../Actions/logActions/actions";
import { mapStateToProps } from "../../../redux";

import { CalculatorContext } from "../../../Contexts/CalculatorContext";

import { BootstrapInput } from "../../Inputs/BootstrapInput";

function MortgageInterestFixedPeriodDialog(props) {
    const context = useContext(CalculatorContext);

    const [options] = useState([
        { name: "Variable", value: "variabel" },
        { name: "1", value: 1 },
        { name: "2", value: 2 },
        { name: "3", value: 3 },
        { name: "5", value: 5 },
        { name: "6", value: 6 },
        { name: "7", value: 7 },
        { name: "10", value: 10 },
        { name: "12", value: 12 },
        { name: "15", value: 15 },
        { name: "20", value: 20 },
        { name: "25", value: 25 },
        { name: "30", value: 30 },
    ]);

    useEffect(() => {
        if (props.open === true) {
            context.state.checkPoint().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    const handleMainAction = () => {
        const { onClose, createLog, auth, role } = props;
        const { functions, product } = context;

        functions.requestProduct(product.code).then((newProduct) => {
            functions.requestCalculation().then(() => {
                functions.requestMaxMortgageFromIncomeCalculation().then((newMaxMortgageIncome) => {
                    createLog(auth, { ...context, product: newProduct, maxMortgageAmountIncome: newMaxMortgageIncome }, role);
                });

                onClose();
            });
        });
    };

    const handleCancelAction = () => {
        const { onClose } = props;

        context.state.rollback().then(() => {
            onClose();
        });
    };

    const checkAvailability = (option, availableOptions) => {
        for (let availableOptionIndex = 0; availableOptionIndex < availableOptions.length; availableOptionIndex++) {
            const value = availableOptions[availableOptionIndex];
            if (option.value.toString() === value) {
                return true;
            }
        }
        return false;
    };

    const { parts, product } = context;
    const { open, lang, theme } = props;

    return (
        <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
            <DialogTitle>{lang.interestFixed.modal_title}</DialogTitle>
            <DialogContent className={"dialog-content"}>
                <div className={"content-wrapper"}>
                    <div className={"content-side"}>
                        {parts.parts.map((part, key) => {
                            return (
                                <div key={key} className={"dialog-input"}>
                                    <div className={"input-label"}>
                                        <label htmlFor={`ifp-part-${key}`}>
                                            {parts.parts.length > 1
                                                ? `${lang.interestFixed.interest_fixed_period_part_label} ${key + 1}`
                                                : lang.interestFixed.interest_fixed_period_label}
                                        </label>
                                    </div>
                                    <div className={"input-help-wrapper"}>
                                        <div className={"input-field"}>
                                            <Select
                                                style={{ width: "39%", marginRight: 5 }}
                                                value={part.interestFixedPeriod.value}
                                                onChange={(event) => {
                                                    context.parts.setPartInterestFixedPeriod(event.target.value, key);
                                                }}
                                                input={<BootstrapInput name={`ifp-${key}`} id={`ifp-part-${key}`} />}
                                            >
                                                {options.map((option, index) => {
                                                    //the checking is done because when the user switches from 1 part to 2 there are no available_products props in
                                                    //the porduct(as it is a product with 1 part) so tot takle the problem if the periods are not found fallback
                                                    //to the first items available products(which will always be valid as the new product is a copy of the
                                                    //new, just with a different mortgage amount)
                                                    const available_periods =
                                                        product.available_periods[key] !== undefined
                                                            ? product.available_periods[key].available_periods
                                                            : product.available_periods[0].available_periods;

                                                    const isOptionAvailable = checkAvailability(option, available_periods);

                                                    return (
                                                        <MenuItem key={index} value={option.value} disabled={!isOptionAvailable}>
                                                            {option.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                        <div className={"input-help"}>
                                            <Tooltip
                                                title={lang.interestFixed.interest_fixed_period_help}
                                                disableFocusListener
                                                disableTouchListener
                                            >
                                                <Help style={{ color: theme.palette.secondary.main }} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={"content-side"}>
                        <Typography>{lang.interestFixed.interest_fixed_period_explanation_par_1}</Typography>
                        <Typography>{lang.interestFixed.interest_fixed_period_explanation_par_2}</Typography>
                        <Typography>{lang.interestFixed.interest_fixed_period_explanation_par_3}</Typography>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelAction} color="secondary">
                    {lang.interestFixed.button_cancel}
                </Button>
                <Button onClick={handleMainAction} color="secondary" variant={"contained"}>
                    {lang.interestFixed.button_apply}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

MortgageInterestFixedPeriodDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default connect(mapStateToProps, { createLog })(withTheme(MortgageInterestFixedPeriodDialog));
