import { SET_LANGUAGE, GET_LANGUAGE } from "./types";
import _ from "lodash";
import axios from "axios";
import { api } from "../../services";

import english from "../../Languages/English.json";
import dutch from "../../Languages/Dutch.json";

//ref to the debounce function which is called when setting the new language
const debounceUpdateLanguageDatabase = _.debounce(function(token, currentLanguage) {
    updateLanguageDatabase(token, currentLanguage);
}, 200);

/**
 * Pushes an update the the database with the new language key
 * @param token the token object
 * @param language the new language key as string
 */
const updateLanguageDatabase = (token, language) => {
    axios({
        method: "post",
        url: `${api.url}/resources`,
        data: { query: `mutation{update_user(data:{language:"${language}"}){language}}` },
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` }
    });
};

/**
 * Changes the language of the website
 * @param {string} token
 */
export const setDirectlyLanguage = language => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            localStorage.setItem("lang", language);

            dispatch({
                type: SET_LANGUAGE,
                payload: language === "English" ? english : dutch
            });

            resolve();
        } catch (error) {
            reject(error);
            console.error("SET_LANGUAGE::FAIL", error);
        }
    });
};

/**
 * Changes the language of the website
 * @param {string} token
 */
export const setLanguage = token => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            const lang = localStorage.getItem("lang");

            const newLang = lang === "Dutch" ? english : dutch;

            localStorage.setItem("lang", newLang.dashboard.currentLanguage);

            dispatch({
                type: SET_LANGUAGE,
                payload: newLang
            });

            if (token !== null) {
                debounceUpdateLanguageDatabase(token, newLang.dashboard.currentLanguage);
            }

            resolve();
        } catch (error) {
            reject(error);
            console.error("SET_LANGUAGE::FAIL", error);
        }
    });
};

/**
 * Gets the language from the db and sets it to the state
 * @param {string} token
 */
export const getLanguage = token => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            const lang = localStorage.getItem("lang");

            if (lang === null) {
                axios({
                    method: "post",
                    url: `${api.url}/resources`,
                    data: { query: "query{user{language}}" },
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${token}` }
                })
                    .then(res => {
                        if (res.data.data.user.language !== null) {
                            const currentLang = res.data.data.user.language === "Dutch" ? dutch : english;

                            dispatch({
                                type: GET_LANGUAGE,
                                payload: currentLang
                            });

                            //because the setItem is synchronous push the update last
                            localStorage.setItem("lang", currentLang.dashboard.currentLanguage);

                            resolve();
                        }
                    })
                    .catch(error => {
                        reject(error);
                        console.error("GET_LANGUAGE::FAIL", error);
                    });
            }
        } catch (error) {
            reject(error);
            console.error("GET_LANGUAGE::FAIL", error);
        }
    });
};

export const translateDate = (date, lang) => {
    let splitDate = date.split(" ");
    switch (splitDate[0]) {
        case "Monday":
            splitDate[0] = lang.date.day_monday;
            break;
        case "Tuesday":
            splitDate[0] = lang.date.day_tuesday;
            break;
        case "Wednesday":
            splitDate[0] = lang.date.day_wednesday;
            break;
        case "Thursday":
            splitDate[0] = lang.date.day_thursday;
            break;
        case "Friday":
            splitDate[0] = lang.date.day_friday;
            break;
        case "Saturday":
            splitDate[0] = lang.date.day_saturday;
            break;
        case "Sunday":
            splitDate[0] = lang.date.day_sunday;
            break;
        default:
            break;
    }
    switch (splitDate[2]) {
        case "Jan":
            splitDate[2] = lang.date.month_january;
            break;
        case "Feb":
            splitDate[2] = lang.date.month_february;
            break;
        case "Mar":
            splitDate[2] = lang.date.month_march;
            break;
        case "Apr":
            splitDate[2] = lang.date.month_april;
            break;
        case "May":
            splitDate[2] = lang.date.month_may;
            break;
        case "June":
            splitDate[2] = lang.date.month_june;
            break;
        case "July":
            splitDate[2] = lang.date.month_july;
            break;
        case "Aug":
            splitDate[2] = lang.date.month_august;
            break;
        case "Sept":
            splitDate[2] = lang.date.month_september;
            break;
        case "Oct":
            splitDate[2] = lang.date.month_october;
            break;
        case "Nov":
            splitDate[2] = lang.date.month_november;
            break;
        case "Dec":
            splitDate[2] = lang.date.month_december;
            break;
        default:
            break;
    }
    return splitDate.join(" ");
};
