export const createMortgageLogMutation = `
mutation createMortgageLog($data: CreateMortgageLogInput!, $product: CreateMortgageLogProductInput!, $calculations: CreateMortgageLogCalculationsInput!, $shared_uuid: String, $are_logs_shared: Boolean){
    create_mortgage_log( data: $data, product: $product, calculations: $calculations, shared_uuid: $shared_uuid, are_logs_shared: $are_logs_shared){
        id
        input {
            mortgage_amount
            value_house
            purchase_price_house
            parts {
                mortgage_amount
                mortgage_type
                duration
                interest_fixed_period
                interest_rate
            }
            incomes
        }
        product {
            code
            description
            provider {
                name
                logo
            }
        }
        calculations{
            max_mortgage_from_income
        }
        created_at
    }
}`;

export const createMortgageComparisonMutation = `
mutation createMortgageComparison($shared_uuid: String, $are_comparisons_shared: Boolean, $first_comparison_id: Int, $second_comparison_id: Int){
    create_mortgage_comparison(shared_uuid: $shared_uuid, are_comparisons_shared: $are_comparisons_shared, first_comparison_id: $first_comparison_id, second_comparison_id: $second_comparison_id){
        id
        first_comparison_id
        second_comparison_id
    }
}`;

export const softDeleteLogMutation = `
mutation softDeleteLog($id: Int!){
    paranoid_delete_mortgage_log(id: $id){
      id
      deleted_at
    }
}`;

export const softDeleteComparisonMutation = `
mutation softDeleteComparison($id: Int!){
    paranoid_delete_mortgage_comparison(id: $id){
      id
      deleted_at
    }
}`;
