import React, { Component, Fragment } from "react";
import { Card, CardContent, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { Add, Remove } from "@material-ui/icons";
import { connect } from "react-redux";
import moment from "moment";

import { CalculatorContext } from "../../Contexts/CalculatorContext";

import { mapStateToProps } from "../../redux";
import { unloadLog, unloadCurrentLog, createLog } from "../../Actions/logActions/actions";
import { openDialog } from "../../Store/ui/actions";

import { format } from "../../helperFunctions";

import ChartContainer from "../../Components/ChartContainer/ChartContainer";
import MortgageDialog from "../../Components/Dialogs/MortgageDialog/MortgageDialog";
import ApplicantsDialog from "../../Components/Dialogs/ApplicantsDialog/ApplicantsDialog";
import StartDateDialog from "../../Components/Dialogs/StartDateDialog/StartDateDialog";
import PartsDialog from "../../Components/Dialogs/PartsDialog/PartsDialog";
import ProductDialog from "../../Components/Dialogs/ProductDialog/ProductDialog";
import MortgageTypeDialog from "../../Components/Dialogs/MortgageTypeDialog/MortgageTypeDialog";
import MortgageDurationDialog from "../../Components/Dialogs/MortgageDurationDialog/MortgageDurationDialog";
import MortgageInterestFixedPeriodDialog from "../../Components/Dialogs/MortgageInterestFixedPeriodDialog/MortgageInterestFixedPeriodDialog";
import ErrorDialog from "../../Components/Dialogs/ErrorDialog/ErrorDialog";
import Template from "../../Components/Template/Template";
import TemplateForCalculation from "../../Components/Template/TemplateForCalculation";
import BottomSelectionLogBar from "./../../Components/BottomSelectionLogBar/BottomSelectionLogBar";
import CheckBlock from "./CheckBlock/CheckBlock";

import Label from "./Components/Label";

import "./CalculatorStyles.css";

class Calculator extends Component {
    static contextType = CalculatorContext;

    constructor(props) {
        super(props);

        const { lang } = props;

        document.title = lang.dashboard.title_tag;

        this.state = {
            mortgageDialogTriggerElement: "none",
            isProductDialogOpen: false,
            isRatesErrorDialogOpen: false,
            isMortgageDialogOpen: false,
            isApplicantsDialogOpen: false,
            isStartDateDialogOpen: false,
            isMortgageTypeDialogOpen: false,
            isMortgageDurationDialogOpen: false,
            isMortgageFixedPeriodDialogOpen: false,
            isNumberOfPartsAndMortgageAmountDialogOpen: false,
            isInterestChenged: false,
        };
    }

    componentDidMount() {
        const { logs } = this.props;

        const viewOnlyLog = logs.currentLog;

        if (viewOnlyLog !== null) {
            this.context.functions.loadFromLog(viewOnlyLog).then(() => {
                this.context.functions.requestCalculation();
            });
        } else if (logs.loadedLog !== null) {
            this.context.functions.loadFromLog(logs.loadedLog).then(() => {
                this.context.functions.requestProduct(logs.loadedLog.product.code).then((product) => {
                    //product.rates[0]["nhg"] = 0.013
                    const diff = this.checkIfThreIsADiff(product, logs);
                    if (diff) {
                        this.context.functions.requestCalculation().then(() => {
                            this.context.functions.requestMaxMortgageFromIncomeCalculation().then(() => {
                                this.setState({ isInterestChenged: true });
                            });
                        });
                    } else {
                        this.context.functions.requestCalculation().then(() => {
                            this.context.functions.requestMaxMortgageFromIncomeCalculation();
                        });
                    }
                });
            });
        }
    }

    openDialog = (dialogStateVariableName, callback) => {
        const { logs } = this.props;

        if (logs.currentLog === null) {
            this.setState({ [dialogStateVariableName]: true }, () => {
                if (typeof callback !== "undefined") {
                    callback();
                }
            });
        }
    };

    closeDialog = (dialogStateVariableName, inputs) => {
        this.setState({ [dialogStateVariableName]: false }, () => {
            inputs.forEach((input) => {
                if (input !== null) {
                    input.blur();
                }
            });
        });
    };

    handleSwitchChange = () => {
        this.setState((state) => ({
            nhg: !state.nhg,
        }));
    };

    removeCurrentLog = () => {
        this.context.state.rollback().then(() => {
            this.props.unloadCurrentLog();
        });
    };

    removeLog = () => {
        this.context.state.rollback().then(() => {
            this.props.unloadLog();
        });
    };

    changeTypeLanguage = (name) => {
        const { lang } = this.props;
        let newName;
        name === "annuity"
            ? (newName = lang.dashboard.input_mortgage_type_part_value_annuity)
            : (newName = lang.dashboard.input_mortgage_type_part_value_linear);
        return newName;
    };

    thousandSeparator = (number) => {
        const parts = number.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
    };

    checkIfThreIsADiff(product, logs) {
        for (let index = 0; index < product.rates.length; index++) {
            const part = product.rates[index];

            const rateKeys = Object.keys(part);
            let lookUpKey = "nhg";
            if (
                logs.loadedLog.calculations.is_nhg_possible === false ||
                (logs.loadedLog.calculations.is_nhg_possible === true && logs.loadedLog.input.force_no_nhg === true)
            ) {
                lookUpKey = "ltv";
            }
            for (let rateIndex = 0; rateIndex < rateKeys.length; rateIndex++) {
                const key = rateKeys[rateIndex];

                if (key.indexOf(lookUpKey) > -1 && part[key] !== logs.loadedLog.product.rates[index][key]) {
                    return true;
                }
            }
        }
        return false;
    }

    render() {
        const { logs, lang } = this.props;
        const calculatorContext = this.context;

        const {
            isProductDialogOpen,
            isMortgageDialogOpen,
            isNumberOfPartsAndMortgageAmountDialogOpen,
            isApplicantsDialogOpen,
            isStartDateDialogOpen,
            isMortgageTypeDialogOpen,
            isMortgageDurationDialogOpen,
            isMortgageFixedPeriodDialogOpen,
            mortgageDialogTriggerElement,
        } = this.state;

        function getDescription() {
            if (calculatorContext.product.description === "Example product with the lowest rates") {
                return lang.dashboard.input_product_default_value;
            } else {
                return calculatorContext.product.description;
            }
        }

        return (
            <Fragment>
                <Template title={lang.dashboard.title_tag} shouldIncludeSideBar={true} history={this.props.history}>
                    <TemplateForCalculation />
                    <div style={{ padding: 10 }}>
                        <div className={"input-container"}>
                            <Card style={{ flexWrap: "wrap", width: "100%" }}>
                                <CardContent className={"input-block"}>
                                    <div className="grid-container">
                                        <div className="applicants">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Label
                                                    className={"number-of-applicants-label"}
                                                    label={lang.dashboard.input_applicants_number_label}
                                                    value={calculatorContext.applicants.incomes.length}
                                                    onClick={() => {
                                                        this.openDialog("isApplicantsDialogOpen");
                                                    }}
                                                    disabled={logs.currentLog !== null}
                                                ></Label>
                                                {calculatorContext.applicants.incomes.map((income, key) => {
                                                    return (
                                                        <Label
                                                            key={key}
                                                            label={`${lang.dashboard.input_applicants_income_person_label} ${key + 1}`}
                                                            value={this.thousandSeparator(income.value)}
                                                            symbol={"€"}
                                                            onClick={() => {
                                                                this.openDialog("isApplicantsDialogOpen");
                                                            }}
                                                            disabled={logs.currentLog !== null}
                                                        ></Label>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="general">
                                            <div
                                                style={{
                                                    display: "flex",
                                                }}
                                            >
                                                <Label
                                                    label={lang.dashboard.input_mortgage_amount_label}
                                                    symbol={"€"}
                                                    value={this.thousandSeparator(calculatorContext.mortgageAmount.value)}
                                                    onClick={() => {
                                                        this.openDialog("isMortgageDialogOpen", () => {
                                                            this.setState({ mortgageDialogTriggerElement: "none" });
                                                        });
                                                    }}
                                                    disabled={logs.currentLog !== null}
                                                ></Label>
                                                <Label
                                                    className={"gen-input"}
                                                    label={lang.dashboard.input_nhg_label}
                                                    value={
                                                        calculatorContext.isNhgPossible.value
                                                            ? !calculatorContext.forceNoNhg.value
                                                                ? lang.dashboard.input_nhg_value_yes
                                                                : lang.dashboard.input_nhg_value_no
                                                            : lang.dashboard.input_nhg_value_no
                                                    }
                                                    onClick={() => {
                                                        this.openDialog("isMortgageDialogOpen", () => {
                                                            this.setState({ mortgageDialogTriggerElement: "none" });
                                                        });
                                                    }}
                                                    disabled={logs.currentLog !== null}
                                                ></Label>
                                                <Label
                                                    className={"gen-input"}
                                                    label={lang.dashboard.input_value_house_label}
                                                    value={this.thousandSeparator(calculatorContext.valueHouse.value)}
                                                    prefix={"€"}
                                                    onClick={() => {
                                                        this.openDialog("isMortgageDialogOpen", () => {
                                                            this.setState({ mortgageDialogTriggerElement: "valueHouse" });
                                                        });
                                                    }}
                                                    disabled={logs.currentLog !== null}
                                                ></Label>
                                                <Label
                                                    className={"gen-input"}
                                                    label={lang.dashboard.input_loan_to_value_label}
                                                    value={`${calculatorContext.ltv.value}%`}
                                                    onClick={() => {
                                                        this.openDialog("isMortgageDialogOpen", () => {
                                                            this.setState({ mortgageDialogTriggerElement: "none" });
                                                        });
                                                    }}
                                                    disabled={logs.currentLog !== null}
                                                ></Label>
                                            </div>
                                        </div>
                                        <div className="parts">
                                            <table className={"input-container-parts"}>
                                                <tbody>
                                                    <tr>
                                                        <td>{lang.dashboard.input_parts_label}:</td>
                                                        <td>{lang.dashboard.input_mortgage_amount_label}:</td>
                                                        <td>{lang.dashboard.input_mortgage_type_part_label}:</td>
                                                        <td>{lang.dashboard.input_mortgage_duration_part_label}:</td>
                                                        <td>{lang.dashboard.input_mortgage_interest_fixed_period_part_label}:</td>
                                                        <td>{lang.dashboard.input_mortgage_interest_rate_part_label}:</td>
                                                        <td
                                                            style={{
                                                                minWidth: 115,
                                                                width: "15%",
                                                            }}
                                                        ></td>
                                                    </tr>
                                                    {calculatorContext.parts.parts.map((part, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{key + 1}</td>
                                                                <td>
                                                                    <Label
                                                                        value={this.thousandSeparator(part.mortgageAmount.value)}
                                                                        onClick={() => {
                                                                            this.openDialog("isNumberOfPartsAndMortgageAmountDialogOpen");
                                                                        }}
                                                                        symbol={"€"}
                                                                        disabled={logs.currentLog !== null}
                                                                    ></Label>
                                                                </td>
                                                                <td>
                                                                    <Label
                                                                        value={this.changeTypeLanguage(part.mortgageType.value)}
                                                                        onClick={() => {
                                                                            this.openDialog("isMortgageTypeDialogOpen");
                                                                        }}
                                                                        disabled={logs.currentLog !== null}
                                                                    ></Label>
                                                                </td>
                                                                <td>
                                                                    <Label
                                                                        value={part.duration.value}
                                                                        onClick={() => {
                                                                            this.openDialog("isMortgageDurationDialogOpen");
                                                                        }}
                                                                        disabled={logs.currentLog !== null}
                                                                    ></Label>
                                                                </td>
                                                                <td>
                                                                    <Label
                                                                        value={part.interestFixedPeriod.value}
                                                                        onClick={() => {
                                                                            this.openDialog("isMortgageFixedPeriodDialogOpen");
                                                                        }}
                                                                        disabled={logs.currentLog !== null}
                                                                    ></Label>
                                                                </td>
                                                                <td>
                                                                    <Label
                                                                        value={`${format(part.interestRate.value * 100, 2, 3, ".", ",")}%`}
                                                                        onClick={() => {
                                                                            this.openDialog("isProductDialogOpen");
                                                                        }}
                                                                        disabled={logs.currentLog !== null}
                                                                    ></Label>
                                                                </td>
                                                                <td>
                                                                    {calculatorContext.parts.parts.length === 1 && key === 0 ? (
                                                                        <IconButton
                                                                            style={{ padding: 5 }}
                                                                            color={"secondary"}
                                                                            onClick={() => {
                                                                                this.openDialog(
                                                                                    "isNumberOfPartsAndMortgageAmountDialogOpen",
                                                                                );
                                                                            }}
                                                                        >
                                                                            <Add />
                                                                        </IconButton>
                                                                    ) : calculatorContext.parts.parts.length === 2 && key === 1 ? (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                paddingLeft: 5,
                                                                            }}
                                                                        >
                                                                            <IconButton
                                                                                style={{ padding: 5 }}
                                                                                color={"secondary"}
                                                                                onClick={() => {
                                                                                    this.openDialog(
                                                                                        "isNumberOfPartsAndMortgageAmountDialogOpen",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <Remove />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                style={{ padding: 5 }}
                                                                                color={"secondary"}
                                                                                onClick={() => {
                                                                                    this.openDialog(
                                                                                        "isNumberOfPartsAndMortgageAmountDialogOpen",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <Add />
                                                                            </IconButton>
                                                                        </div>
                                                                    ) : null}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="product">
                                            <Label
                                                label={"Product"}
                                                value={getDescription()}
                                                onClick={() => {
                                                    this.openDialog("isProductDialogOpen");
                                                }}
                                                disabled={logs.currentLog !== null}
                                            ></Label>
                                        </div>
                                        <div className="start-date">
                                            <Label
                                                label={lang.dashboard.input_start_date_label}
                                                value={calculatorContext.startDate.value}
                                                onClick={() => {
                                                    this.openDialog("isStartDateDialogOpen");
                                                }}
                                                disabled={logs.currentLog !== null}
                                            ></Label>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <CheckBlock calculatorContext={calculatorContext} />
                        </div>
                        <ChartContainer
                            chartColor={"#448AFF"}
                            chartData={calculatorContext.calculations.netPaymentCalcualtions}
                            chartHeight={150}
                            chartTitle={lang.dashboard.graph_net_payments_title}
                            chartTooltipOnClick={this.props.openUIDialog.bind(this, "tooltip", {
                                title: lang.dashboard.graph_net_payments_title,
                                text: lang.dashboard.graph_net_payments_help,
                            })}
                            chartTotalTitle={lang.dashboard.total_net_title}
                            chartTotalTooltipOnClick={this.props.openUIDialog.bind(this, "tooltip", {
                                title: lang.dashboard.total_net_title,
                                text: lang.dashboard.total_net_help,
                            })}
                            chartType={"BarChart"}
                            isChartDataLoading={calculatorContext.calculations.isLoading}
                            totals={[calculatorContext.calculations.totals.ifp.net, calculatorContext.calculations.totals.duration.net]}
                        />
                        <ChartContainer
                            chartData={calculatorContext.calculations.grossPaymentCalcualtions}
                            chartHeight={150}
                            chartTitle={lang.dashboard.graph_gross_payments_title}
                            chartTooltipOnClick={this.props.openUIDialog.bind(this, "tooltip", {
                                title: lang.dashboard.graph_gross_payments_title,
                                text: lang.dashboard.graph_gross_payments_help,
                            })}
                            chartTotalTitle={lang.dashboard.total_gross_title}
                            chartTotalTooltipOnClick={this.props.openUIDialog.bind(this, "tooltip", {
                                title: lang.dashboard.total_gross_title,
                                text: lang.dashboard.total_gross_help,
                            })}
                            chartType={"BarChart"}
                            isChartDataLoading={calculatorContext.calculations.isLoading}
                            totals={[calculatorContext.calculations.totals.ifp.gross, calculatorContext.calculations.totals.duration.gross]}
                        />
                        <ChartContainer
                            chartData={calculatorContext.calculations.averageInterestRateCalcualtions}
                            chartHeight={150}
                            chartInfoDialog={true}
                            chartTitle={lang.dashboard.graph_interest_rate_title}
                            chartTooltipOnClick={this.props.openUIDialog.bind(this, "tooltip", {
                                title: lang.dashboard.graph_interest_rate_title,
                                text: lang.dashboard.graph_interest_rate_help,
                            })}
                            chartTotalTitle={lang.dashboard.average_interest_title}
                            chartTotalTooltipOnClick={this.props.openUIDialog.bind(this, "tooltip", {
                                title: lang.dashboard.average_interest_title,
                                text: lang.dashboard.average_interest_help,
                            })}
                            chartTooltipType={"InterestRateTooltip"}
                            chartType={"LineChart"}
                            isChartDataLoading={calculatorContext.calculations.isLoading}
                            isTotalValueWholeNumber={false}
                            totals={[
                                calculatorContext.calculations.totals.ifp.avgRate,
                                calculatorContext.calculations.totals.duration.avgRate,
                            ]}
                        />
                        <ChartContainer
                            chartData={calculatorContext.calculations.mortgageAmountCalcualtions}
                            chartTitle={lang.dashboard.graph_mortgage_amount_title}
                            chartTooltipOnClick={this.props.openUIDialog.bind(this, "tooltip", {
                                title: lang.dashboard.graph_mortgage_amount_title,
                                text: lang.dashboard.graph_mortgage_amount_help,
                            })}
                            chartType={"AreaChart"}
                            isChartDataLoading={calculatorContext.calculations.isLoading}
                        />
                    </div>
                    {logs.currentLog !== null ? <div style={{ marginTop: 50 }} /> : undefined}
                    <BottomSelectionLogBar
                        selectedLog={logs.currentLog === null ? null : logs.currentLog}
                        closeAction={this.removeCurrentLog}
                        text={
                            logs.currentLog === null
                                ? null
                                : `${lang.history.bar_calculation_loaded_title_view} ${moment(logs.currentLog.created_at, "x").format(
                                      "DD-MM-YYYY, HH:mm",
                                  )} - ${lang.history.bar_calculation_loaded_title_change}`
                        }
                        primaryAction={this.removeCurrentLog}
                        primaryActionText={lang.history.bar_calculation_loaded_title_button_close}
                    />
                </Template>
                <MortgageDialog
                    open={isMortgageDialogOpen}
                    triggerElement={mortgageDialogTriggerElement}
                    onClose={this.closeDialog.bind(this, "isMortgageDialogOpen", [])}
                />
                <ProductDialog open={isProductDialogOpen} onClose={this.closeDialog.bind(this, "isProductDialogOpen", [])} />
                <ApplicantsDialog open={isApplicantsDialogOpen} onClose={this.closeDialog.bind(this, "isApplicantsDialogOpen", [])} />
                <StartDateDialog open={isStartDateDialogOpen} onClose={this.closeDialog.bind(this, "isStartDateDialogOpen", [])} />
                <MortgageTypeDialog open={isMortgageTypeDialogOpen} onClose={this.closeDialog.bind(this, "isMortgageTypeDialogOpen", [])} />
                <MortgageDurationDialog
                    open={isMortgageDurationDialogOpen}
                    onClose={this.closeDialog.bind(this, "isMortgageDurationDialogOpen", [])}
                />
                <MortgageInterestFixedPeriodDialog
                    open={isMortgageFixedPeriodDialogOpen}
                    onClose={this.closeDialog.bind(this, "isMortgageFixedPeriodDialogOpen", [])}
                />
                <ErrorDialog
                    open={calculatorContext.rateError}
                    onCancel={() => {
                        calculatorContext.state.rollback().then(() => {
                            calculatorContext.state.checkPoint().then();
                        });
                    }}
                    onApply={() => {
                        this.openDialog("isProductDialogOpen");
                    }}
                />
                <PartsDialog
                    open={isNumberOfPartsAndMortgageAmountDialogOpen}
                    onClose={this.closeDialog.bind(this, "isNumberOfPartsAndMortgageAmountDialogOpen", [])}
                />
                <Dialog open={this.state.isInterestChenged} aria-labelledby="draggable-change-dialog" fullWidth={true} maxWidth={"sm"}>
                    <DialogTitle id="draggable-change-dialog">{"Warning!"}</DialogTitle>
                    <DialogContent>
                        The interest rate for this calculation has changed. Do you want to continue with loading the calculation
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ isInterestChenged: false });
                                this.removeLog();
                                this.props.history.push("/geschiedenis");
                            }}
                            color="secondary"
                        >
                            cancel
                        </Button>
                        <Button
                            onClick={() => {
                                this.props.createLog(this.props.auth, this.context, this.props.role).then(() => {
                                    this.props.unloadLog();
                                    this.setState({ isInterestChenged: false });
                                });
                            }}
                            variant="contained"
                            color="secondary"
                        >
                            Load
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps, { unloadLog, createLog, unloadCurrentLog, openUIDialog: openDialog })(withTheme(Calculator));
