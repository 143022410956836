import React from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";

import { closeDialog } from "../../Store/ui/actions";

import Dialog from "../Dialog";

const TooltipDialog = (props) => {
    const { open, text, title } = props;
    const { closeDialog } = props;

    return (
        <Dialog open={open} title={title} onClose={closeDialog.bind(this, "tooltip")}>
            <Typography>{text}</Typography>
        </Dialog>
    );
};

const mapStateToProps = (props) => {
    const { ui } = props;

    return {
        open: ui.dialogs.tooltip ? ui.dialogs.tooltip.open === true : false,
        text: ui.dialogs.tooltip && ui.dialogs.tooltip.text ? ui.dialogs.tooltip.text : "",
        title: ui.dialogs.tooltip && ui.dialogs.tooltip.title ? ui.dialogs.tooltip.title : "",
    };
};

export default connect(mapStateToProps, { closeDialog })(TooltipDialog);
