import React, { useState } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Typography, Paper, IconButton, Collapse } from "@material-ui/core";
import { Warning, CheckCircle, Help } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { withTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { format } from "./../../../helperFunctions";

import { mapStateToProps } from "../../../redux";
import { openDialog } from "../../../Store/ui/actions";

function CheckBlock(props) {
    const { calculatorContext, lang, classes, css, openUIDialog } = props;

    const [expanded, setExpanded] = useState(false);

    return (
        <div className={"check-block-container"}>
            <Paper className={"check-block-paper"}>
                <div className={"check-block-header-row"}>
                    <div className={"check-block-header"}>
                        <Typography variant={"h1"} style={{ marginRight: "5px" }}>
                            {lang.dashboard.maximum_mortgage_title}
                        </Typography>
                        <IconButton
                            color="secondary"
                            onClick={openUIDialog.bind(this, "tooltip", {
                                title: lang.dashboard.maximum_mortgage_title,
                                text: `${lang.dashboard.maximum_mortgage_title} help dialog`,
                            })}
                            style={{ padding: 0 }}
                        >
                            <Help />
                        </IconButton>
                    </div>
                    <div className={"check-block-icon-button"}>
                        <IconButton
                            className={classNames(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={setExpanded.bind(this, !expanded)}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </div>
                </div>
                <Collapse in={css.isMobile ? expanded : true} timeout="auto" unmountOnExit>
                    <div className={"check-block-item"}>
                        <Typography variant={"h2"}>{lang.dashboard.maximum_mortgage_house}</Typography>
                        <div className={"check-block-item-wrapper"}>
                            <Typography>{`€ ${format(calculatorContext.maxMortgageHouse.value, 0, 3, ".", ",")}`}</Typography>
                            {calculatorContext.mortgageAmount.value > calculatorContext.maxMortgageHouse.value ? (
                                <Warning style={{ color: "#F44336" }} />
                            ) : (
                                <CheckCircle style={{ color: "#66BB6A" }} />
                            )}
                        </div>
                    </div>
                    <div className={"check-block-item"}>
                        <Typography variant={"h2"}>{lang.dashboard.maximum_mortgage_income}</Typography>
                        <div className={"check-block-item-wrapper"}>
                            <Typography>{`€ ${format(calculatorContext.maxMortgageAmountIncome, 0, 3, ".", ",")}`}</Typography>
                            {calculatorContext.mortgageAmount.value > calculatorContext.maxMortgageAmountIncome ? (
                                <Warning style={{ color: "#F44336" }} />
                            ) : (
                                <CheckCircle style={{ color: "#66BB6A" }} />
                            )}
                        </div>
                    </div>
                </Collapse>
            </Paper>
        </div>
    );
}

const styles = (theme) => ({
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
        padding: "0",
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
});

export default connect(mapStateToProps, { openUIDialog: openDialog })(withTheme(withStyles(styles)(CheckBlock)));
