import React, { Fragment, useContext, useState } from "react";
import { Help } from "@material-ui/icons";
import { Typography, Collapse, Radio, FormControlLabel, RadioGroup, Tooltip, IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";

import { mapStateToProps } from "../../redux";

import { CalculatorContext } from "../../Contexts/CalculatorContext";

import ApplicantsDialog from "../../Components/Dialogs/ApplicantsDialog/ApplicantsDialog";

import "../../Views/FirstTimeVisit/FirstTimeVisit.css";

function FirstTimeVisitIncomeContent(props) {
    const { id, title, textUnderTitle, radioQuestion, inputQuestion, lang, css } = props;

    const [isDialogOpened, setIsDialogOpened] = useState(false);

    const context = useContext(CalculatorContext);

    const thousandSeparator = (number) => {
        const parts = number.toString().split(".");

        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        return parts.join(",");
    };

    return (
        <Fragment>
            <div className={"first-time-visit-title-wrapper"}>
                <Typography variant="h6" color="inherit" noWrap className={"first-time-visit-step-title"}>
                    {title}
                </Typography>
                <Typography className={"first-time-visit-step-info"}>{textUnderTitle}</Typography>
            </div>
            <div className={"first-time-visit-question"}>
                <div className={"first-time-visit-yes-no"}>
                    <div className={"first-time-visit-question-text"}>{radioQuestion}</div>
                    <div className={"first-time-visit-radio-button"}>
                        <RadioGroup
                            aria-label="position"
                            name="position"
                            value={String(context.applicants.incomes.length)}
                            onChange={(event) => {
                                if (event.target.value === "1") {
                                    context.applicants.removeApplicant();
                                } else {
                                    context.applicants.addApplicant();
                                }
                            }}
                            row
                        >
                            <FormControlLabel value="2" control={<Radio />} label={lang.inputDataSteps.no} labelPlacement="end" />
                            <FormControlLabel value="1" control={<Radio />} label={lang.inputDataSteps.yes} labelPlacement="end" />
                        </RadioGroup>
                    </div>
                    <div className={"first-time-visit-tooltip"}>
                        <Tooltip title={lang.inputDataSteps.step_8.applicant_is_1_help} disableFocusListener disableTouchListener>
                            <Help style={{ color: props.theme.palette.secondary.main }} />
                        </Tooltip>
                    </div>
                </div>
                <div className={"first-time-visit-collapse"}>
                    <div className={"dialog-input"} style={{ justifyContent: "normal", marginTop: "16px", alignItems: "center" }}>
                        <div className={"first-time-visit-question-text"}>
                            <label htmlFor={id}>{context.applicants.incomes.length === 2 ? `${inputQuestion} 1` : inputQuestion}</label>
                        </div>
                        <div className={"input-field"} style={css.isMobile ? { width: "100%", paddingLeft: "4%" } : { width: "80px" }}>
                            <div id={id} style={{ width: "80px" }}>
                                {thousandSeparator(context.applicants.incomes[0].value)}
                            </div>
                            {css.isMobile ? (
                                <Fragment>
                                    <div>
                                        <IconButton
                                            aria-label="edit"
                                            style={{ color: props.theme.palette.secondary.main, padding: "0 12px" }}
                                            onClick={() => {
                                                setIsDialogOpened(true);
                                            }}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </div>
                                    <div className={"first-time-visit-input-help"}>
                                        <Tooltip title={lang.inputDataSteps.step_8.income_1_help} disableFocusListener disableTouchListener>
                                            <Help style={{ color: props.theme.palette.secondary.main }} />
                                        </Tooltip>
                                    </div>
                                </Fragment>
                            ) : null}
                        </div>
                        {css.isMobile ? null : (
                            <Fragment>
                                <div className={"first-time-visit-input-help"}>
                                    <Tooltip title={lang.inputDataSteps.step_8.income_1_help} disableFocusListener disableTouchListener>
                                        <Help style={{ color: props.theme.palette.secondary.main }} />
                                    </Tooltip>
                                </div>
                                <div>
                                    <IconButton
                                        aria-label="edit"
                                        style={{ color: props.theme.palette.secondary.main }}
                                        onClick={() => {
                                            setIsDialogOpened(true);
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>

                <Collapse in={context.applicants.incomes.length === 2} timeout="auto" unmountOnExit className={"first-time-visit-collapse"}>
                    {context.applicants.incomes.length === 2 ? (
                        <div className={"dialog-input"} style={{ justifyContent: "normal", marginTop: "16px", alignItems: "center" }}>
                            <div className={"first-time-visit-question-text"}>
                                <label htmlFor={id}>{context.applicants.incomes.length === 2 ? `${inputQuestion} 2` : inputQuestion}</label>
                            </div>
                            <div className={"input-field"} style={css.isMobile ? { width: "100%", paddingLeft: "4%" } : { width: "80px" }}>
                                <div id={id} style={{ width: "80px" }}>
                                    {thousandSeparator(context.applicants.incomes[1].value)}
                                </div>
                                {css.isMobile ? (
                                    <Fragment>
                                        <div>
                                            <IconButton
                                                aria-label="edit"
                                                style={{ color: props.theme.palette.secondary.main, padding: "0 12px" }}
                                                onClick={() => {
                                                    setIsDialogOpened(true);
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                        </div>
                                        <div className={"first-time-visit-input-help"}>
                                            <Tooltip
                                                title={lang.inputDataSteps.step_8.income_1_help}
                                                disableFocusListener
                                                disableTouchListener
                                            >
                                                <Help style={{ color: props.theme.palette.secondary.main }} />
                                            </Tooltip>
                                        </div>
                                    </Fragment>
                                ) : null}
                            </div>
                            {css.isMobile ? null : (
                                <Fragment>
                                    <div className={"first-time-visit-input-help"}>
                                        <Tooltip title={lang.inputDataSteps.step_8.income_1_help} disableFocusListener disableTouchListener>
                                            <Help style={{ color: props.theme.palette.secondary.main }} />
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <IconButton
                                            aria-label="edit"
                                            style={{ color: props.theme.palette.secondary.main }}
                                            onClick={() => {
                                                setIsDialogOpened(true);
                                            }}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    ) : null}
                </Collapse>
            </div>
            <ApplicantsDialog
                open={isDialogOpened}
                onClose={() => {
                    setIsDialogOpened(false);
                }}
            />
        </Fragment>
    );
}

export default connect(mapStateToProps)(withTheme(FirstTimeVisitIncomeContent));
