import React from "react";
import { withTheme } from "@material-ui/core/styles";

function Line(props) {
    return (
        <div className={"dialog-input"} style={{ margin: 0 }}>
            <div className={"input-label"} />
            <div className={"input-field"}>
                <div className={"line"} style={{ backgroundColor: props.theme.palette.primary.main, height: 1 }} />
            </div>
            <div className={"input-help"} />
        </div>
    );
}

export default withTheme(Line);
