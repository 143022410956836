import React from "react";
import { CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    mask: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.6)",
    },
}));

const LoadingMask = (props) => {
    const { visible } = props;

    const classes = useStyles();

    return visible ? (
        <div className={classes.mask}>
            <CircularProgress color={"secondary"}></CircularProgress>
        </div>
    ) : null;
};

LoadingMask.propTypes = {
    visible: PropTypes.bool,
};

export default LoadingMask;
