export const LOG_CREATE = "LOG_CREATE";
export const LOG_GET = "LOG_GET";
export const LOG_UNLOAD = "LOG_UNLOAD";
export const LOG_DELETE = "LOG_DELETE";
export const LOG_DELETE_LOCAL = "LOG_DELETE_LOCAL";
export const LOG_GET_LAST_LOG = "LOG_GET_LAST_LOG";
export const LOG_LOAD_LOGS = "LOG_LOAD_LOGS";
export const LOG_LOAD_LOGS_NEWER = "LOG_LOAD_LOGS_NEWER";
export const LOG_LOAD_LOGS_OLDER = "LOG_LOAD_LOGS_OLDER";
export const LOG_RESET = "LOG_RESET";
export const LOG_LOAD = "LOG_LOAD";
export const LOG_UNLOAD_CURRENT = "LOG_UNLOAD_CURRENT";
export const CHECK_IF_FIRST_TIME_USER = "CHECK_IF_FIRST_TIME_USER";
export const COMPARISON_CREATE = "COMPARISON_CREATE";
export const LOG_LOAD_COMPARISONS = "LOG_LOAD_COMPARISONS";
export const COMPARISON_DELETE = "COMPARISON_DELETE";
export const COMPARISON_DELETE_LOCAL = "COMPARISON_DELETE_LOCAL";
export const COMPARISONS_RESET = "COMPARISONS_RESET";
