import axios from "axios";

import { getMortgageLogQuery } from "../../Graphql/queries/logQueries";

import { api } from "../../services";

export const getLog = (id, shared_uuid, token) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${api.url}/logs`,
            // url: `http://localhost:3000/v1/logs`,
            withCredentials: false,
            data: {
                query: getMortgageLogQuery,
                variables: {
                    id,
                    shared_uuid
                }
            },
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => {
                resolve(response.data.data.mortgage_log);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const requestCalculation = (query, token) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${api.url}/calculators`,
            withCredentials: false,
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: {
                query: query
            }
        })
            .then(response => {
                resolve(response.data.data.mortgage_calculation); 
            })
            .catch(error => {
                reject(error);
            });
    });
};
