import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware, combineReducers } from "redux";

import authReducer from "../Reducers/authReducer";
import menuReducer from "../Reducers/menuReducer";
import logReducer from "../Reducers/logReducer";
import langReducer from "../Reducers/languageReducer";
import cssReducer from "../Reducers/cssReducer";
import roleReducer from "../Reducers/roleReducer";
import comparisonReducer from "../Reducers/comparisonReducer";
import uiReducer from "./ui/reducer";

// import calculationReducer from "./calculationReducer";
// import inputReducer from "./inputReducer";

const initialState = {};

export const store = createStore(
    combineReducers({
        auth: authReducer,
        menu: menuReducer,
        logs: logReducer,
        lang: langReducer,
        css: cssReducer,
        role: roleReducer,
        comparison: comparisonReducer,
        ui: uiReducer,
    }),
    initialState,
    composeWithDevTools(applyMiddleware(thunk)),
);
