import { SELECT_CUSTOMER_TO_WORK_WITH, SELECT_ADVISOR_AS_CUSTOMER, RESET_CUSTOMER } from "../Actions/roleActions/types";

const initialState = {
    selectedCustomer: null,
    selectedAdvisor: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SELECT_CUSTOMER_TO_WORK_WITH:
            return {
                ...state,
                selectedCustomer: action.payload,
            };
        case RESET_CUSTOMER:
            return {
                ...state,
                selectedCustomer: action.payload,
            };
        case SELECT_ADVISOR_AS_CUSTOMER:
            return {
                ...state,
                selectedAdvisor: action.payload,
            };
        default:
            return state;
    }
}
