import React, { Component } from "react";
import { FormControl, TextField, FormHelperText, CircularProgress, Button, Card, CardContent, Typography } from "@material-ui/core";
import "./SignInStyles.css";
import { connect } from "react-redux";
import { mapStateToProps } from "../../redux";
import { signInDefault } from "../../Actions/authActions/actions";
import { setDirectlyLanguage } from "../../Actions/languageAction/actions";
import logo from "../../Images/logo_hyponav_beta.svg";
import { setLanguage } from "../../Actions/languageAction/actions";

class SignIn extends Component {
    state = {
        email: "",
        password: "",
        errors: {
            email: false,
            password: false
        },
        emailErrorMessage: "",
        isLoading: false
    };

    onInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    signIn = () => {
        const { email, password, errors } = this.state;
        const { lang } = this.props;
        this.setState({ isLoading: true });

        let _errors = errors;

        this.props
            .signInDefault({ email: email, password: password })
            .then(res => {
                this.props.setDirectlyLanguage(res.user.language);
            })
            .catch(error => {
                _errors.email = error.code.includes("email");

                _errors.password = error.code.includes("password");

                this.setState({
                    errors: _errors,
                    isLoading: false,
                    emailErrorMessage:
                        error.code === "email/not-verified" ? "Please verify your email" : lang.login.email_address_validation_non_existing
                });
            });
    };

    redirectToSignUp = () => {
        this.props.history.push("/registratie");
    };

    redirectToResetPassword = () => {
        this.props.history.push("/wachtwoord-vergeten");
    };

    render() {
        const { errors, isLoading, emailErrorMessage } = this.state;
        const { lang } = this.props;
        return (
            <div className={"dom"}>
                <img src={logo} alt="Logo" style={{ width: "300px", margin: "40px 0" }} />
                <Card className={"card"}>
                    <CardContent className={"card-content"}>
                        <Typography variant={"h2"} style={{ margin: "20px 0" }}>
                            {lang.login.title}
                        </Typography>
                        <Button
                            color="primary"
                            variant={"text"}
                            onClick={() => {
                                this.props.setLanguage(null);
                            }}
                        >
                            {lang.login.button_language}
                        </Button>
                        <FormControl error={errors.email} className={"sign-form-input"}>
                            <TextField
                                error={errors.email}
                                label={lang.login.email_address_label}
                                variant={"outlined"}
                                className={"sign-input"}
                                type="email"
                                name="email"
                                onChange={this.onInputChange}
                                autoComplete="email"
                                margin="normal"
                            />
                            {errors.email ? <FormHelperText className={"error-message"}>{emailErrorMessage}</FormHelperText> : undefined}
                        </FormControl>

                        <FormControl error={errors.password} className={"sign-form-input"}>
                            <TextField
                                error={errors.password}
                                label={lang.login.password_label}
                                className={"sign-input"}
                                variant={"outlined"}
                                type="password"
                                name="password"
                                onChange={this.onInputChange}
                                autoComplete="current-password"
                                margin="normal"
                            />
                            {errors.password ? (
                                <FormHelperText className={"error-message"}>
                                    {lang.login.password_validation_incorrect}
                                </FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>

                        <Button size="large" color="secondary" variant="contained" className={"sign-in-button"} onClick={this.signIn}>
                            {isLoading ? <CircularProgress className={"loader"} /> : lang.login.button_inloggen}
                        </Button>
                        <div className={"sign-help"}>
                            <Button color="primary" variant={"text"} onClick={this.redirectToResetPassword}>
                                {lang.login.button_forgot_password}
                            </Button>
                            <Button color="primary" variant={"text"} onClick={this.redirectToSignUp}>
                                {lang.login.button_login}
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default connect(mapStateToProps, { signInDefault, setLanguage, setDirectlyLanguage })(SignIn);
