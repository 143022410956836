import { Add, Remove } from "@material-ui/icons";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useContext } from "react";

import { mapStateToProps } from "../../../redux";
import { createLog } from "../../../Actions/logActions/actions";

import { CalculatorContext } from "../../../Contexts/CalculatorContext";

import Input from "../../Inputs/InputField/InputField";

function PartsDialog(props) {
    const context = useContext(CalculatorContext);

    useEffect(() => {
        if (props.open) {
            context.state.checkPoint();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    const handleMainAction = () => {
        const { onClose, createLog, auth, role } = props;
        const { functions, product } = context;

        //some action
        functions.requestProduct(product.code).then((newProduct) => {
            functions.requestCalculation().then(() => {
                functions.requestMaxMortgageFromIncomeCalculation().then((newMaxMortgageIncome) => {
                    createLog(auth, { ...context, product: newProduct, maxMortgageAmountIncome: newMaxMortgageIncome }, role);
                });

                onClose();
            });
        });
    };

    const thousandSeparator = (number) => {
        const parts = number.toString().split(".");

        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        return parts.join(",");
    };

    const handleCancelAction = () => {
        const { onClose } = props;

        context.state.rollback().then(() => {
            onClose();
        });
    };

    const { parts } = context;
    const { open, lang } = props;

    return (
        <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
            <DialogTitle>{lang.parts.modal_title}</DialogTitle>
            <DialogContent className={"dialog-content"}>
                <div className={"content-wrapper"}>
                    <div className={"content-side"}>
                        {parts.parts.map((part, key) => {
                            return (
                                <Input
                                    key={key}
                                    id={`part-${key + 1}-mortgage-amount`}
                                    label={`${lang.parts.mortgage_amount_part_label} ${key + 1}`}
                                    type={"text"}
                                    value={thousandSeparator(part.mortgageAmount.value)}
                                    onChange={(event) => {
                                        parts.setPartMortgageAmount(key, event.target.value.split(".").join(""));
                                    }}
                                    readOnly={key === 0 ? true : false}
                                    tooltipText={
                                        key === 0 ? lang.parts.mortgage_amount_part_1_help : lang.parts.mortgage_amount_part_2_help
                                    }
                                    error={part.mortgageAmount.error}
                                    errors={{
                                        min: `${lang.mortgageAmount.min_price} 5.000`,
                                        max: `${lang.mortgageAmount.max_price}  9.999.999`,
                                    }}
                                />
                            );
                        })}
                        <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "10%" }}>
                            {parts.parts.length === 2 ? (
                                <Button
                                    variant={"contained"}
                                    color={"secondary"}
                                    onClick={() => {
                                        parts.removePart();
                                    }}
                                >
                                    <Remove /> {lang.parts.input_button_remove}
                                </Button>
                            ) : (
                                <Button
                                    variant={"contained"}
                                    color={"secondary"}
                                    onClick={() => {
                                        parts.addPart();
                                    }}
                                >
                                    <Add /> {lang.parts.input_button_add}
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className={"content-side"}>
                        <Typography>{lang.parts.mortgage_amount_explanation_par_1}</Typography>
                        <Typography>{lang.parts.mortgage_amount_explanation_par_2}</Typography>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelAction} color="secondary">
                    {lang.parts.button_cancel}
                </Button>
                <Button onClick={handleMainAction} color="secondary" variant={"contained"}>
                    {lang.parts.button_apply}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

PartsDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default connect(mapStateToProps, { createLog })(withTheme(PartsDialog));
