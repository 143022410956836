import React, { Fragment } from "react";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";
import Draggable from "react-draggable";
import axios from "axios";

import Template from "../../Components/Template/Template";
import { setLanguage } from "../../Actions/languageAction/actions";
import { signOut } from "../../Actions/authActions/actions";
import { api } from "../../services";

import {
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Paper
} from "@material-ui/core";

import "./ProfilePage.css";

function ProfilePage(props) {
    const [isResetPassDialogOpen, setIsResetPassDialogOpen] = React.useState(false);

    const resetPass = () => {
        const { auth, signOut } = props;

        axios({
            method: "post",
            url: `${api.url}/auth/forgot`,
            // url: `http://localhost:3000/v1/auth/forgot`,
            data: {
                url: `${api.url}/auth/reset`,
                email: auth.user.email
            }
        })
            .then(response => {
                if (response.data.data.code === "email/sent") {
                    signOut();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const { auth, history, lang, setLanguage } = props;

    return (
        <Fragment>
            <Template
                title={lang.profile.title_page}
                shouldIncludeSideBar={true}
                contentFullHeight={true}
                history={history}
                shouldIncludeSecoundTopBar={false}
            >
                <div className={"profile-page-wrapper"}>
                    <div className={"profile-page-row"}>
                        <div className={"profile-page-cell"}>{lang.profile.user_first_name_label} </div>
                        <div className={"profile-page-cell"}>{auth.user.first_name}</div>
                    </div>
                    <div className={"profile-page-row"}>
                        <div className={"profile-page-cell"}>{lang.profile.user_infix_label} </div>
                        <div className={"profile-page-cell"}>{auth.user.prefix}</div>
                    </div>
                    <div className={"profile-page-row"}>
                        <div className={"profile-page-cell"}>{lang.profile.user_last_name_label} </div>
                        <div className={"profile-page-cell"}>{auth.user.last_name}</div>
                    </div>
                    <div className={"profile-page-row"}>
                        <div className={"profile-page-cell"}>{lang.profile.user_email_address_label} </div>
                        <div className={"profile-page-cell"}>{auth.user.email}</div>
                    </div>
                    <div className={"profile-page-row"}>
                        <div className={"profile-page-cell"}>{lang.profile.user_language_label} </div>
                        <div className={"profile-page-cell"}>
                            <RadioGroup
                                aria-label="position"
                                name="position"
                                value={lang.dashboard.currentLanguage}
                                onChange={() => {
                                    setLanguage(auth.token);
                                }}
                                row
                            >
                                <FormControlLabel value="Dutch" control={<Radio />} label={lang.profile.user_language_dutch} labelPlacement="end" />
                                <FormControlLabel value="English" control={<Radio />} label={lang.profile.user_language_english} labelPlacement="end" />
                            </RadioGroup>
                        </div>
                    </div>
                    <div className={"profile-page-row"}>
                        <Button
                            color="secondary"
                            variant={"contained"}
                            onClick={() => {
                                setIsResetPassDialogOpen(true);
                            }}
                        >
                            {lang.profile.button_reset_password}
                        </Button>
                    </div>
                </div>
            </Template>
            <Dialog
                open={isResetPassDialogOpen}
                onClose={() => {
                    setIsResetPassDialogOpen(false);
                }}
                PaperComponent={props => {
                    return (
                        <Draggable>
                            <Paper {...props} />
                        </Draggable>
                    );
                }}
                aria-labelledby="draggable-delete-dialog"
            >
                <DialogTitle id="draggable-delete-dialog">{lang.profile.reset_password_modal_title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{lang.profile.reset_password_modal_text}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setIsResetPassDialogOpen(false);
                        }}
                        color="secondary"
                    >
                        {lang.profile.button_reset_password_cancel}
                    </Button>
                    <Button onClick={resetPass} variant="contained" color="secondary">
                        {lang.profile.button_reset_password_ok}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default connect(mapStateToProps, { setLanguage, signOut })(ProfilePage);
