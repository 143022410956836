import { MENU_CLOSE, MENU_OPEN, MENU_TOGGLE } from "../Actions/menuActions/types";

const initialState = {
    isOpen: false,
    menuOpenWidth: 200,
    menuCloseWidth: 57,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case MENU_CLOSE:
            return {
                ...state,
                isOpen: action.payload,
            };
        case MENU_OPEN:
            return {
                ...state,
                isOpen: action.payload,
            };
        case MENU_TOGGLE:
            return {
                ...state,
                isOpen: !state.isOpen,
            };
        default:
            return state;
    }
}
