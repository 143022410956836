import React, { Component, Fragment } from "react";
import { format } from "../../../helperFunctions";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Collapse, Typography } from "@material-ui/core";
import Input from "../../Inputs/InputField/InputField";
import Radio from "../../Inputs/RadioField/RadioField";
import { CalculatorContext } from "../../../Contexts/CalculatorContext";
import Line from "./Line/Line";
import { mapStateToProps } from "../../../redux";
import { connect } from "react-redux";
import { createLog } from "../../../Actions/logActions/actions";
import "./MortgageDialogStyles.css";

class MortgageDialog extends Component {
    static contextType = CalculatorContext;

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        triggerElement: PropTypes.oneOf(["valueHouse", "none"])
    };
    static defaultProps = {
        triggerElement: "none"
    };

    constructor(props) {
        super(props);

        this.valueHouseRef = React.createRef();

        this.state = {};
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open === true && prevProps.open !== this.props.open) {
            this.context.state.checkPoint().then();
        }
        if (this.props.triggerElement === "valueHouse" && this.props.open === true) {
            if (this.valueHouseRef.current !== null) {
                this.valueHouseRef.current.focus();
            }
        }
    }

    thousandSeparator = number => {
        const parts = number.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
    };

    handleMainAction = () => {
        const { onClose, createLog, auth, role } = this.props;
        const { functions, product, } = this.context;
        const calculatorContext = this.context;
        
        if (calculatorContext.mortgageAmount.value < 5000) {
            return;
        }
        // getting new porduct as it is possible to have change in the number of parts
        functions.requestProduct(product.code).then(newProduct => {
            functions.requestCalculation().then(() => {
                functions.requestMaxMortgageFromIncomeCalculation().then(newMaxMortgageIncome => {
                    createLog(auth, { ...this.context, product: newProduct, maxMortgageAmountIncome: newMaxMortgageIncome }, role);
                });

                onClose();
            });
        });
    };

    handleCancelAction = () => {
        const { onClose } = this.props;

        this.context.state.rollback().then(() => {
            onClose();
        });
    };

    handleRadioChange = event => {
        const { includeNHG } = this.state;

        const temp = includeNHG;

        temp.value = event.target.value;

        this.setState({ includeNHG: temp });
    };

    render() {
        const calculatorContext = this.context;
        const { open, lang } = this.props;

        return (
            <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
                <DialogTitle>{lang.mortgageAmount.modal_title}</DialogTitle>
                <DialogContent className={"dialog-content"}>
                    <div className={"content-wrapper"}>
                        <div className={"content-side"}>
                            <Input
                                id={"purchase-price-house"}
                                label={lang.mortgageAmount.house_purchase_price_label}
                                value={this.thousandSeparator(calculatorContext.purchasePriceHouse.value)}
                                onChange={event => {
                                    calculatorContext.purchasePriceHouse.setValue(event.target.value.split(".").join(""));
                                }}
                                tooltipText={lang.mortgageAmount.house_purchase_price_help}
                                error={calculatorContext.purchasePriceHouse.error}
                                errors={calculatorContext.purchasePriceHouse.errors}
                            />
                            <Input
                                id={"transfer-tax"}
                                label={lang.mortgageAmount.costs_transfer_tax_label}
                                tooltipText={lang.mortgageAmount.costs_transfer_tax_help}
                                readOnly={true}
                                value={this.thousandSeparator(calculatorContext.transferTax.value)}
                                error={null}
                            />
                            <Input
                                id={"notary-deed-of-transfer"}
                                label={lang.mortgageAmount.costs_notary_transfer_label}
                                value={this.thousandSeparator(calculatorContext.notaryDeedOfTransfer.value)}
                                onChange={event => {
                                    calculatorContext.notaryDeedOfTransfer.setValue(event.target.value.split(".").join(""));
                                }}
                                tooltipText={lang.mortgageAmount.costs_notary_transfer_help}
                                error={calculatorContext.notaryDeedOfTransfer.error}
                                errors={calculatorContext.notaryDeedOfTransfer.errors}
                            />
                            <Input
                                id={"bank-guarantee"}
                                label={lang.mortgageAmount.costs_bank_garantuee_label}
                                value={this.thousandSeparator(calculatorContext.bankGuarantee.value)}
                                tooltipText={lang.mortgageAmount.costs_bank_garantuee_help}
                                onChange={event => {
                                    calculatorContext.bankGuarantee.setValue(event.target.value.split(".").join(""));
                                }}
                                error={calculatorContext.bankGuarantee.error}
                                errors={calculatorContext.bankGuarantee.errors}
                            />
                            <Input
                                id={"architectural-inspection"}
                                label={lang.mortgageAmount.costs_architectural_inspection_label}
                                value={this.thousandSeparator(calculatorContext.architecturalInspection.value)}
                                tooltipText={lang.mortgageAmount.costs_architectural_inspection_help}
                                onChange={event => {
                                    calculatorContext.architecturalInspection.setValue(event.target.value.split(".").join(""));
                                }}
                                error={calculatorContext.architecturalInspection.error}
                                errors={calculatorContext.architecturalInspection.errors}
                            />
                            <Input
                                id={"notary-mortgage"}
                                label={lang.mortgageAmount.costs_notary_mortgage_label}
                                tooltipText={lang.mortgageAmount.costs_notary_mortgage_help}
                                value={this.thousandSeparator(calculatorContext.notaryMortgage.value)}
                                onChange={event => {
                                    calculatorContext.notaryMortgage.setValue(event.target.value.split(".").join(""));
                                }}
                                error={calculatorContext.notaryMortgage.error}
                                errors={calculatorContext.notaryMortgage.errors}
                            />
                            <Input
                                id={"valuation"}
                                label={lang.mortgageAmount.costs_valuation_label}
                                tooltipText={lang.mortgageAmount.costs_valuation_help}
                                value={this.thousandSeparator(calculatorContext.valuation.value)}
                                onChange={event => {
                                    calculatorContext.valuation.setValue(event.target.value.split(".").join(""));
                                }}
                                error={calculatorContext.valuation.error}
                                errors={calculatorContext.valuation.errors}
                            />
                            <Input
                                id={"mortgage-advice"}
                                label={lang.mortgageAmount.costs_mortgage_advice_label}
                                tooltipText={lang.mortgageAmount.costs_mortgage_advice_help}
                                value={this.thousandSeparator(calculatorContext.mortgageAdvice.value)}
                                onChange={event => {
                                    calculatorContext.mortgageAdvice.setValue(event.target.value.split(".").join(""));
                                }}
                                error={calculatorContext.mortgageAdvice.error}
                                errors={calculatorContext.mortgageAdvice.errors}
                            />
                            <Input
                                id={"mortgage-application"}
                                label={lang.mortgageAmount.costs_mortgage_application_label}
                                tooltipText={lang.mortgageAmount.costs_mortgage_application_help}
                                value={this.thousandSeparator(calculatorContext.mortgageApplication.value)}
                                onChange={event => {
                                    calculatorContext.mortgageApplication.setValue(event.target.value.split(".").join(""));
                                }}
                                error={calculatorContext.mortgageApplication.error}
                                errors={calculatorContext.mortgageApplication.errors}
                            />
                            {!calculatorContext.isNhgPossible.value ? (
                                <Input
                                    label={lang.mortgageAmount.nhg_label}
                                    type={"text"}
                                    value={lang.mortgageAmount.nhg_no}
                                    readOnly={true}
                                    tooltipText={lang.mortgageAmount.nhg_help}
                                />
                            ) : (
                                <Fragment>
                                    <Radio
                                        onChange={event => {
                                            calculatorContext.forceNoNhg.setValue(event.target.value === "true");
                                        }}
                                        inputs={calculatorContext.forceNoNhg.inputs}
                                        value={calculatorContext.forceNoNhg.value.toString()}
                                        label={lang.mortgageAmount.nhg_label}
                                        tooltipText={lang.mortgageAmount.nhg_help}
                                        name={"include-nhg"}
                                    />
                                    <Collapse in={!calculatorContext.forceNoNhg.value} timeout="auto" unmountOnExit>
                                        <Input
                                            id={"nhg"}
                                            label={lang.mortgageAmount.costs_nhg_label}
                                            value={this.thousandSeparator(calculatorContext.nhgCosts.value)}
                                            error={calculatorContext.nhgCosts.error}
                                            tooltipText={lang.mortgageAmount.costs_nhg_help}
                                            readOnly={true}
                                        />
                                    </Collapse>
                                </Fragment>
                            )}
                            <Line />
                            <Input
                                id={"total-required-amount"}
                                label={lang.mortgageAmount.total_required_amount_label}
                                value={this.thousandSeparator(calculatorContext.requiredMortgageAmount.value)}
                                tooltipText={lang.mortgageAmount.total_required_amount_help}
                                error={null}
                                readOnly={true}
                            />
                            <Input
                                id={"investment-savings"}
                                label={lang.mortgageAmount.investment_savings_label}
                                value={this.thousandSeparator(calculatorContext.requiredSavings.value)}
                                tooltipText={lang.mortgageAmount.investment_savings_help}
                                onChange={event => {
                                    calculatorContext.requiredSavings.setValue(event.target.value.split(".").join(""));
                                }}
                                error={calculatorContext.requiredSavings.error}
                                errors={calculatorContext.requiredSavings.errors}
                            />
                            <Line />
                            <Input
                                id={"mortgage-amount"}
                                label={lang.mortgageAmount.mortgage_amount_label}
                                tooltipText={lang.mortgageAmount.mortgage_amount_help}
                                value={this.thousandSeparator(calculatorContext.mortgageAmount.value)}
                                onChange={event => {
                                    calculatorContext.mortgageAmount.setValue(event.target.value.split(".").join(""));
                                }}
                                error={calculatorContext.mortgageAmount.error}
                                errors={calculatorContext.mortgageAmount.errors}
                            />
                        </div>
                        <div className={"content-side"}>
                            <Input
                                id={"value-house"}
                                label={lang.mortgageAmount.value_house_label}
                                inputRef={this.valueHouseRef}
                                value={this.thousandSeparator(calculatorContext.valueHouse.value)}
                                tooltipText={lang.mortgageAmount.value_house_help}
                                onChange={event => {
                                    calculatorContext.valueHouse.setValue(event.target.value.split(".").join(""));
                                }}
                                type={"text"}
                                error={calculatorContext.valueHouse.error}
                                errors={calculatorContext.valueHouse.errors}
                            />
                            <Input
                                id={"ltv"}
                                label={lang.mortgageAmount.ltv_label}
                                value={`${calculatorContext.ltv.value}%`}
                                tooltipText={lang.mortgageAmount.ltv_help}
                                type={"text"}
                                readOnly={true}
                            />
                            <Input
                                id={"interest-rate"}
                                label={lang.mortgageAmount.interest_rate_label}
                                tooltipText={lang.mortgageAmount.interest_rate_help}
                                value={`${format(calculatorContext.interestRate.value * 100, 2, 3, ".", ",")}%`}
                                type={"text"}
                                readOnly={true}
                            />
                            <Input
                                id={"max-mortgage-house"}
                                label={lang.mortgageAmount.maximum_mortgage_house_label}
                                tooltipText={lang.mortgageAmount.maximum_mortgage_house_help}
                                type={"text"}
                                value={this.thousandSeparator(calculatorContext.maxMortgageHouse.value)}
                                readOnly={true}
                            />
                            <Input
                                id={"max-mortgage-income"}
                                label={lang.mortgageAmount.maximum_mortgage_income_label}
                                tooltipText={lang.mortgageAmount.maximum_mortgage_income_help}
                                value={this.thousandSeparator(calculatorContext.maxMortgageAmountIncome)}
                                readOnly={true}
                            />
                            <Typography>{lang.mortgageAmount.mortgage_amount_explanation}</Typography>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancelAction} color="secondary">
                        {lang.mortgageAmount.button_cancel}
                    </Button>
                    <Button onClick={this.handleMainAction} color="secondary" variant={"contained"}>
                        {lang.mortgageAmount.button_apply}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, { createLog })(withTheme(MortgageDialog));
