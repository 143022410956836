import { Radio, RadioGroup, FormControlLabel, FormControl } from "@material-ui/core";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";

import { api } from "../../services";

import React, { Fragment } from "react";

import axios from "axios";

function CustomerInformation(props) {
    const { are_logs_shared, are_comparisons_shared, state, customerUuid, auth, onSharedChange, css, language, email } = props;

    const [sharedCalculations, setSharedCalculations] = React.useState(are_logs_shared);
    const [sharedComparisons, setSharedComparisons] = React.useState(are_comparisons_shared);
    const [check, setCheck] = React.useState(are_logs_shared);
    const [checkComparison, setCheckComparison] = React.useState(are_comparisons_shared);

    React.useEffect(() => {
        if (check !== sharedCalculations) {
            changeAreLogsShared();
            setCheck(sharedCalculations);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedCalculations]);

    React.useEffect(() => {
        if (checkComparison !== sharedComparisons) {
            changeAreComparisonsShared();
            setCheckComparison(sharedComparisons);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedComparisons]);

    const changeAreLogsShared = async () => {
        //Change sared column in the resource service
        await axios({
            method: "post",
            url: `${api.url}/resources`,
            // url: `http://localhost:3000/v1/resources`,
            data: {
                query: `mutation{change_are_logs_shared(customers_uuid:"${customerUuid}",state:"${state}") {
                    are_logs_shared
                }}`
            },
            withCredentials: false,
            headers: { Authorization: `Bearer ${auth.token}` }
        })
            .then(res => {
                axios({
                    method: "post",
                    url: `${api.url}/logs`,
                    // url: `http://localhost:3000/v1/logs`,
                    data: {
                        query: `query{chacnge_shared_logs(shared_uuid:"${customerUuid}",shared:${sharedCalculations})}`
                    },
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${auth.token}` }
                })
                    .then(response => {
                        onSharedChange(customerUuid, res.data.data.change_are_logs_shared.are_logs_shared, sharedComparisons);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            })
            .catch(err => {
                console.error(err);
            });
    };

    const changeAreComparisonsShared = async () => {
        //Change sared column in the resource service
        await axios({
            method: "post",
            url: `${api.url}/resources`,
            // url: `http://localhost:3000/v1/resources`,
            data: {
                query: `mutation{change_are_comparison_shared(customers_uuid:"${customerUuid}",state:"${state}") {
                    are_comparisons_shared
                }}`
            },
            withCredentials: false,
            headers: { Authorization: `Bearer ${auth.token}` }
        })
            .then(res => {
                axios({
                    method: "post",
                    url: `${api.url}/logs`,
                    // url: `http://localhost:3000/v1/logs`,
                    data: {
                        query: `query{chacnge_shared_comparisons(shared_uuid:"${customerUuid}",shared:${sharedComparisons})}`
                    },
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${auth.token}` }
                })
                    .then(response => {
                        onSharedChange(customerUuid, sharedCalculations, res.data.data.change_are_comparison_shared.are_comparisons_shared);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            })
            .catch(err => {
                console.error(err);
            });
    };

    return (
        <div className={"customer-information"}>
            {css.isMobile ? (
                <Fragment>
                    <div className={"customer-information-row"}>
                        <div className={"customer-information-cell"}>Email:</div>
                        <div className={"customer-information-cell"}>{email}</div>
                    </div>
                    <div className={"customer-information-row"}>
                        <div className={"customer-information-cell"}>Status:</div>
                        <div className={"customer-information-cell"}>{state}</div>
                    </div>
                    <div className={"customer-information-row"}>
                        <div className={"customer-information-cell"}>Language:</div>
                        <div className={"customer-information-cell"}>{language}</div>
                    </div>
                </Fragment>
            ) : (
                <Fragment />
            )}
            <div className={"customer-information-row"}>
                Share your calculations with this customer
                <div className={"customer-information-radio-button"}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="position"
                            name="position"
                            value={String(sharedCalculations)}
                            onChange={() => {
                                setSharedCalculations(!sharedCalculations);
                            }}
                            row
                        >
                            <FormControlLabel value="true" control={<Radio />} label="Yes" labelPlacement="end" />
                            <FormControlLabel value="false" control={<Radio />} label="No" labelPlacement="end" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div className={"customer-information-row"}>
                Share your comparisons with this customer
                <div className={"customer-information-radio-button"}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="position"
                            name="position"
                            value={String(sharedComparisons)}
                            onChange={() => {
                                setSharedComparisons(!sharedComparisons);
                            }}
                            row
                        >
                            <FormControlLabel value="true" control={<Radio />} label="Yes" labelPlacement="end" />
                            <FormControlLabel value="false" control={<Radio />} label="No" labelPlacement="end" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </div>
    );
}
export default connect(mapStateToProps)(CustomerInformation);
