import React, { useContext, Fragment, useState, useEffect } from "react";
import moment from "moment";
import classNames from "classnames";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Help, Close } from "@material-ui/icons";
import { Typography, IconButton, Tooltip, AppBar, Toolbar } from "@material-ui/core";

import { mapStateToProps } from "../../redux";
import { resetCustomer } from "../../Actions/roleActions/actions";
import { unloadLog } from "../../Actions/logActions/actions";
import { openDialog } from "../../Store/ui/actions";

import { CalculatorContext } from "../../Contexts/CalculatorContext";

function TemplateForCalculation(props) {
    const { auth, role, resetCustomer, unloadLog, css, classes, menu } = props;
    const { openDialog } = props;

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [createdAt, setCreatedAt] = useState(null);
    const [owner, setOwner] = useState(null);

    const context = useContext(CalculatorContext);
    const { functions } = context;

    useEffect(() => {
        getText();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.isCalculationMine, context.createdAt, role.selectedCustomer]);

    const getText = () => {
        if (auth.user.role === "advisor" && role.selectedCustomer !== null) {
            setSelectedCustomer(
                role.selectedCustomer.customerFirstName === "" && role.selectedCustomer.customerLastName === ""
                    ? role.selectedCustomer.customerEmail
                    : `${role.selectedCustomer.customerFirstName} ${
                          role.selectedCustomer.customerPrefix === undefined ? "" : role.selectedCustomer.customerPrefix
                      } ${role.selectedCustomer.customerLastName}`,
            );
        }

        if (context.isCalculationMine.value !== null && context.createdAt.value !== null) {
            setCreatedAt(moment(context.createdAt.value, "x").format("DD-MM-YYYY, HH:mm:ss"));
            setOwner(context.isCalculationMine.value);
        }
    };

    return (
        <Fragment>
            {auth.user.role !== "advisor" && css.isMobile ? (
                <Fragment />
            ) : (
                <Fragment>
                    <div className={"calculation-empty-tool-bar"}></div>
                    <AppBar
                        className={classNames(
                            classes.content,
                            {
                                [classes.contentSideMenuOpen]: menu.isOpen,
                            },
                            "calculation-app-bar",
                        )}
                    >
                        <Toolbar disableGutters={true} className={"calculation-tool-bar"}>
                            {auth.user.role === "advisor" ? (
                                role.selectedCustomer !== null ? (
                                    css.isMobile ? (
                                        <Fragment>
                                            <div className={"calculation-bar-text"}>
                                                <Typography
                                                    variant="h6"
                                                    color="inherit"
                                                    noWrap
                                                    align="center"
                                                    className={"calculation-bar-typography"}
                                                >
                                                    <div>{selectedCustomer !== null ? `Customer: ${selectedCustomer}` : "Demo Mode"}</div>
                                                </Typography>
                                            </div>
                                            <div className={"calculation-bar-x-button"}>
                                                <IconButton
                                                    className={"calculation-bar-x-icon"}
                                                    color="inherit"
                                                    aria-label={"Close"}
                                                    onClick={() => {
                                                        resetCustomer();
                                                        functions.loadFromDefaultState().then();
                                                        unloadLog();
                                                    }}
                                                >
                                                    <Close />
                                                </IconButton>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <div className={"calculation-bar-text"}>
                                                <Typography
                                                    variant="h6"
                                                    color="inherit"
                                                    noWrap
                                                    align="center"
                                                    className={"calculation-bar-typography"}
                                                >
                                                    <div>{selectedCustomer !== null ? `Customer: ${selectedCustomer}` : "Demo Mode"}</div>
                                                    {createdAt !== null ? <div>{`Loaded calculation: ${createdAt}`}</div> : null}
                                                    {owner !== null ? <div>{`Owner: ${owner ? "Advisor" : "Customer"}`}</div> : null}
                                                </Typography>
                                            </div>
                                            <div className={"calculation-bar-x-button"}>
                                                <Tooltip
                                                    title={"Stop working with this user"}
                                                    aria-label={"Close"}
                                                    placement="top"
                                                    disableFocusListener
                                                    disableTouchListener
                                                >
                                                    <IconButton
                                                        className={"calculation-bar-x-icon"}
                                                        color="inherit"
                                                        aria-label={"Close"}
                                                        onClick={() => {
                                                            resetCustomer();
                                                            functions.loadFromDefaultState().then();
                                                            unloadLog();
                                                        }}
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </Fragment>
                                    )
                                ) : (
                                    <Fragment>
                                        <div className={"calculation-bar-text"}>
                                            <Typography variant="h6" color="inherit" noWrap align="center">
                                                Demo Mode
                                            </Typography>
                                        </div>
                                        <div className={"calculation-bar-tooltip"}>
                                            <IconButton
                                                color="secondary"
                                                onClick={openDialog.bind(this, "tooltip", {
                                                    title: "Demo mode",
                                                    text: "While using Demo Mode your calculations will not be saved",
                                                })}
                                                style={{ padding: 0 }}
                                            >
                                                <Help />
                                            </IconButton>
                                        </div>
                                    </Fragment>
                                )
                            ) : css.isMobile ? (
                                <Fragment />
                            ) : (
                                <Fragment>
                                    <div className={"calculation-bar-text"}>
                                        <Typography
                                            variant="h6"
                                            color="inherit"
                                            noWrap
                                            align="center"
                                            className={"calculation-bar-typography"}
                                        >
                                            {createdAt !== null ? <div>{`Loaded calculation: ${createdAt}`}</div> : "Default calculation"}
                                            {owner !== null ? <div>{`Owner: ${owner ? "Customer" : "Advisor"}`}</div> : null}
                                        </Typography>
                                    </div>
                                </Fragment>
                            )}
                        </Toolbar>
                    </AppBar>
                </Fragment>
            )}
        </Fragment>
    );
}

const drawerWidthOpen = 200;

const styles = (theme) => ({
    content: {
        width: "100%",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentSideMenuOpen: {
        width: `calc(100% - ${drawerWidthOpen}px)`,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
});

export default connect(mapStateToProps, { resetCustomer, unloadLog, openDialog })(withStyles(styles)(TemplateForCalculation));
