import React, { useState } from "react";
import clsx from "clsx";
import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import LoadingMask from "./LoadingMask";
import Alert from "./Alert";

const useStyles = makeStyles(({ spacing }) => {
    return {
        closeButton: {
            top: spacing(1),
            right: spacing(1),
            position: "absolute",
        },
        mainButtonWarning: {
            display: "flex",
            alignItems: "center",
            color: "rgba(0, 0, 0, 0.54)",
            padding: 12,
        },
        mainButtonDanger: {
            display: "flex",
            alignItems: "center",
            backgroundColor: "#ff5252",
            "&:hover": {
                backgroundColor: "#ff5252",
            },
        },
        actions: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: spacing(3),
        },
        actionsRight: {
            justifyContent: "flex-end",
        },
        actionsMain: {
            display: "flex",
            alignItems: "center",
        },
    };
});

const Dialog = (props) => {
    const { disableMain, onMainText, onCloseText, onDeleteText, alert, alertType, title, content, open, children, loading, width } = props;
    const { onMain, onClose, onDelete, validate } = props;

    const classes = useStyles();

    const [hasMask, setHasMask] = useState(false);

    /**
     * when the user presses Enter or Esc the dialog is respectively opened/closed
     * Enter - 13
     * Esc - 27
     */
    const handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            if (onMain) handleMainAction();
        } else if (event.keyCode === 27) {
            if (onClose) onClose();
        }
    };

    const handleMainAction = async () => {
        try {
            setHasMask(true);

            if (validate) {
                const hasErrors = await validate()
                    .then(() => {
                        return false;
                    })
                    .catch(() => {
                        return true;
                    });

                if (hasErrors) {
                    setHasMask(false);
                    return;
                }
            }

            await onMain();

            if (onClose) onClose();

            setHasMask(false);
        } catch (error) {
            setHasMask(false);
        }
    };

    return (
        <MuiDialog
            open={open}
            onClose={() => {
                if (onClose) onClose();
            }}
            onKeyDown={handleKeyPress}
            scroll={"body"}
            maxWidth={width}
            fullWidth={true}
        >
            <DialogTitle className={classes.title}>
                {title}
                {onClose ? (
                    <IconButton className={classes.closeButton} onClick={onClose}>
                        <Close />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                {alert ? <Alert type={alertType}>{alert}</Alert> : undefined}

                <Typography>{content}</Typography>
                {children}
            </DialogContent>
            <DialogActions
                className={clsx(classes.actions, {
                    [classes.actionsRight]: !(onDelete && onDeleteText),
                })}
            >
                {onDelete && onDeleteText ? (
                    <Button onClick={onDelete} size="medium" className={classes.mainButtonDanger}>
                        {onDeleteText}
                    </Button>
                ) : undefined}
                <div className={classes.actionsMain}>
                    {onClose && onCloseText ? (
                        <Button onClick={onClose} size="medium" color="primary">
                            {onCloseText}
                        </Button>
                    ) : undefined}
                    {onMain && onMainText ? (
                        <Button
                            variant="contained"
                            size="medium"
                            className={clsx({
                                [classes.mainButtonDanger]: alertType === "danger",
                            })}
                            disabled={disableMain}
                            onClick={handleMainAction}
                            color="primary"
                        >
                            {onMainText}
                        </Button>
                    ) : undefined}
                </div>
            </DialogActions>
            <LoadingMask visible={hasMask || loading} />
        </MuiDialog>
    );
};

Dialog.defaultProps = {
    width: "xs",
};

Dialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onCloseText: PropTypes.string,
    onMain: PropTypes.func,
    onMainText: PropTypes.string,
    disableMain: PropTypes.bool,
    onDelete: PropTypes.func,
    onDeleteText: PropTypes.string,
    validate: PropTypes.func,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    alert: PropTypes.string,
    alertType: PropTypes.oneOf(["danger", "warning"]),
    width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export default Dialog;
