import { connect } from "react-redux";
import React, { Fragment, useEffect } from "react";

import { attachResizeListener } from "../../Actions/cssActions/actions";
import { getLanguage } from "../../Actions/languageAction/actions";
import { createLog, getLastLog, checkIfFirstTimeUser } from "../../Actions/logActions/actions";
import { getAdvisorAsCustomer } from "../../Actions/roleActions/actions";
import { mapStateToProps } from "../../redux";

function BootApplication(props) {
    useEffect(() => {
        const { getLanguage, auth, getLastLog, calculator, attachResizeListener, checkIfFirstTimeUser } = props;
        const { functions } = calculator;

        getLanguage(auth.token);

        checkIfFirstTimeUser(auth).then(firstTime => {
            let newProduct = {};

            if (auth.user.role === "basic" || auth.user.role === "premium") {
                props.getAdvisorAsCustomer(auth.token).then(res => {
                    getLastLog(auth)
                        .then(log => {
                            return functions.loadProductFromLog(log);
                        })
                        .then(res => {
                            let newLog = null;
                            let newState = calculator;
                            
                            if (res !== null) {
                                newLog = res[0];
                                newState = res[1];
                            }

                            return functions
                                .requestProduct(newLog === null ? null : newLog.product.code)
                                .then(newProductProps => {
                                    newProduct = newProductProps;
                                    return functions.requestCalculation();
                                })
                                .then(() => {
                                    return functions.requestMaxMortgageFromIncomeCalculation();
                                })
                                .then(newMaxMortgageIncome => {
                                    if (!firstTime) {
                                        props.createLog(
                                            auth,
                                            {
                                                ...newState,
                                                product: newProduct,
                                                maxMortgageAmountIncome: newMaxMortgageIncome
                                            },
                                            { selectedAdvisor: res }
                                        );
                                    }
                                });
                        });
                });
            } else if (auth.user.role === "advisor") {
                functions
                    .loadProductFromLog(null)
                    .then(res => {
                        return functions.requestProduct(res === null ? null : res[0].product.code);
                    })
                    .then(newProductProps => {
                        newProduct = newProductProps;
                        return functions.requestCalculation();
                    })
                    .then(() => {
                        return functions.requestMaxMortgageFromIncomeCalculation();
                    })
                    .then(() => {
                        functions.createDefaultState().then();
                    });
            }
        });

        attachResizeListener();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { children } = props;

    return <Fragment>{children}</Fragment>;
}

export default connect(mapStateToProps, {
    createLog,
    getLanguage,
    attachResizeListener,
    getLastLog,
    getAdvisorAsCustomer,
    checkIfFirstTimeUser
})(BootApplication);
