import React, { Fragment, useContext } from "react";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Snackbar,
    Paper,
    CircularProgress,
    Typography,
    TextField,
    Checkbox,
    IconButton,
    Collapse,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import axios from "axios";

import Template from "../../Components/Template/Template";
import TemplateForCalculation from "../../Components/Template/TemplateForCalculation";
import Input from "../../Components/Inputs/InputField/InputField";
import { api, origin } from "../../services";
import ConsumerPanelRow from "../../Components/ConsumerPanelRow/ConsumerPanelRow";
import BottomSelectionLogBar from "./../../Components/BottomSelectionLogBar/BottomSelectionLogBar";
import { setCustomer } from "../../Actions/roleActions/actions";
import { resetLog, getLastLog } from "../../Actions/logActions/actions";
import { CalculatorContext } from "../../Contexts/CalculatorContext";

import Radio from "../../Components/Inputs/RadioField/RadioField";

import "./ConsumerPanel.css";

function ConsumerPanel(props) {
    const { lang } = props;

    document.title = lang.customers.title_tag;

    const [isInviteDialogOpen, setIsInviteDialogOpen] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [invitationFirstName, setInvitationFirstName] = React.useState("");
    const [invitationPrefix, setInvitationPrefix] = React.useState("");
    const [invitationLastName, setInvitationLastName] = React.useState("");
    const [openSnackInvite, setOpenSnackInvite] = React.useState(false);
    const [openSnackSelect, setOpenSnackSelect] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [customers, setCustomers] = React.useState();
    const [notActivatedCustomers, setnotActivatedCustomers] = React.useState();
    const [customerId, setCustomerId] = React.useState(null);
    const [customerUuid, setCustomerUuid] = React.useState(null);
    const [customerFirstName, setCustomerFirstName] = React.useState(null);
    const [prefix, setPrefix] = React.useState(null);
    const [customerLastName, setCustomerLastName] = React.useState(null);
    const [customerEmail, setCustomerEmail] = React.useState(null);
    const [lastCustomerId, setLastCustomerId] = React.useState();
    const [filterFirstName, setFilterFirstName] = React.useState("");
    const [filterEmail, setFilterEmail] = React.useState("");
    const [filterOnlyRegistered, setFilterOnlyRegistered] = React.useState(false);
    const [filterOnlyInvited, setFilterOnlyInvited] = React.useState(false);
    const [areLogsShared, setAreLogsShared] = React.useState(false);
    const [areComparisonsShared, setAreComparisonsShared] = React.useState(false);
    const [statusOfNewCustomer, setStatusOfNewCustomer] = React.useState();
    const [statusOfSelectedCustomer, setStatusOfSelectedCustomer] = React.useState();
    const [createCustomerError, setCreateCustomerError] = React.useState("");
    const [userLanguage, setUserLanguage] = React.useState("Dutch");
    const [expandFilters, setExpandFilters] = React.useState(false);

    React.useEffect(() => {
        getCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterFirstName, filterEmail, filterOnlyRegistered, filterOnlyInvited]);

    React.useEffect(() => {
        if (customers !== undefined) {
            setLastCustomerId(customers.length);
        }
    }, [customers]);

    const onSharedChange = (customerUuid, areLogsShared, areComparisonsShared) => {
        const { role } = props;

        if (props.role.selectedCustomer !== null) {
            if (props.role.selectedCustomer.customerUuid === customerUuid) {
                props.setCustomer(
                    role.selectedCustomer.customerUuid,
                    role.selectedCustomer.customerFirstName,
                    role.selectedCustomer.prefix,
                    role.selectedCustomer.customerLastName,
                    role.selectedCustomer.customerEmail,
                    areLogsShared,
                    areComparisonsShared,
                );
            }
        }
        getCustomers();
    };

    const onSelectCheckBox = (
        customerIndex,
        customerEmail,
        customerUuid,
        customerFirstName,
        prefix,
        customerLastName,
        areLogsShared,
        areComparisonsShared,
        status,
    ) => {
        if (customerId === customerIndex) {
            setCustomerId(null);
        } else {
            setCustomerId(customerIndex);
            setCustomerEmail(customerEmail);
            setCustomerUuid(customerUuid);
            setCustomerFirstName(customerFirstName);
            setPrefix(prefix);
            setCustomerLastName(customerLastName);
            setAreLogsShared(areLogsShared);
            setAreComparisonsShared(areComparisonsShared);
            setStatusOfSelectedCustomer(status);
        }
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            setOpenSnackInvite(false);
            setOpenSnackSelect(false);
        }

        setOpenSnackInvite(false);
        setOpenSnackSelect(false);
    };

    const openInviteDialog = event => {
        setStatusOfNewCustomer("Invited");
        setIsInviteDialogOpen(true);
    };

    const closeInviteDialog = event => {
        setIsInviteDialogOpen(false);
        setEmail("");
        setInvitationFirstName("");
        setInvitationPrefix("");
        setInvitationLastName("");
        setCreateCustomerError("");
    };

    const sendInvitation = (email, statusOfNewCustomer) => {
        axios({
            method: "post",
            url: `${api.url}/resources`,
            // url: `http://localhost:3000/v1/resources`,
            data: {
                query: `mutation{create_invitation(email:"${email}",firstName:"${invitationFirstName}",prefix:"${invitationPrefix}",lastName:"${invitationLastName}",newRole:"basic",url:"${origin.url}/registratie",status:"${statusOfNewCustomer}",language:"${userLanguage}")}`,
            },
            withCredentials: false,
            headers: { Authorization: `Bearer ${props.auth.token}` },
        })
            .then(res => {
                if (res.data.errors !== undefined) {
                    setCreateCustomerError(res.data.errors[0].message);
                } else {
                    setCreateCustomerError("");
                    setEmail("");
                    setInvitationFirstName("");
                    setInvitationPrefix("");
                    setInvitationLastName("");
                    closeInviteDialog();
                    setUserLanguage("Dutch");
                    setOpenSnackInvite(true);
                    getCustomers();
                }
            })
            .catch(err => {
                console.error(err);
            });
    };

    const getCustomers = () => {
        axios({
            method: "post",
            url: `${api.url}/resources`,
            // url: `http://localhost:3000/v1/resources`,
            data: {
                query: `query{
                    get_customers(
                        first_name:"${filterFirstName}",email:"${filterEmail}",only_invited:${filterOnlyInvited},only_registered:${filterOnlyRegistered}
                        )
                        {
                            customers{
                                first_name
                                prefix
                                last_name
                                email
                                state
                                uuid
                                are_logs_shared
                                are_comparisons_shared
                                language
                            }
                            unactivated_customers{
                                first_name_of_receiver
                                prefix_of_receiver
                                last_name_of_receiver
                                unactivated_email
                                uuid
                                are_logs_shared_unactivated
                                are_comparisons_shared_unactivated
                                status
                                language
                            }
                        }
                    }`,
            },
            withCredentials: false,
            headers: { Authorization: `Bearer ${props.auth.token}` },
        })
            .then(res => {
                setCustomers(res.data.data.get_customers.customers);
                setnotActivatedCustomers(res.data.data.get_customers.unactivated_customers);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    };

    const { history, resetLog, getLastLog, css, classes } = props;
    const context = useContext(CalculatorContext);
    const { functions } = context;
    return (
        <div>
            <Template title={lang.customers.customers_label} shouldIncludeSideBar={true} contentFullHeight={true} history={history}>
                <TemplateForCalculation />
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexDirection: "column" }}>
                    <div className={"new-customer-buttons"}>
                        <Button
                            onClick={event => {
                                setStatusOfNewCustomer("Created");
                                setIsInviteDialogOpen(true);
                            }}
                            color="secondary"
                            variant={"contained"}
                        >
                            CREATE NEW CUSTOMER
                        </Button>
                        <Button onClick={openInviteDialog} color="secondary" variant={"contained"}>
                            INVITE NEW CUSTOMER
                        </Button>
                    </div>
                    <div className={"wrapper"}>
                        <div className={"filters"}>
                            {css.isUnder1200px ? (
                                //mobile version
                                <Paper className={"customer-filter-mobile-paper"}>
                                    <div className={"customer-filter-mobile-header"}>
                                        Filters{" "}
                                        <IconButton
                                            className={classNames(classes.expand, {
                                                [classes.expandOpen]: expandFilters,
                                            })}
                                            onClick={() => {
                                                setExpandFilters(!expandFilters);
                                            }}
                                            aria-expanded={expandFilters}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </div>
                                    <Collapse in={expandFilters} timeout="auto" unmountOnExit>
                                        <div className="customer-filter-mobile-content">
                                            <TextField
                                                label="First Name"
                                                value={filterFirstName}
                                                onChange={event => {
                                                    setFilterFirstName(event.target.value);
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                                style={{ maxWidth: "300px" }}
                                            />
                                            <TextField
                                                label="Email"
                                                value={filterEmail}
                                                onChange={event => {
                                                    setFilterEmail(event.target.value);
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                                style={{ maxWidth: "300px" }}
                                            />
                                            <div className={"check-box"}>
                                                <Checkbox
                                                    color={"secondary"}
                                                    checked={filterOnlyRegistered}
                                                    onClick={event => {
                                                        setFilterOnlyRegistered(!filterOnlyRegistered);
                                                        setFilterOnlyInvited(false);
                                                    }}
                                                />
                                                Only Registerd Customers
                                            </div>
                                            <div className={"check-box"}>
                                                <Checkbox
                                                    color={"secondary"}
                                                    checked={filterOnlyInvited}
                                                    lable={"yooooooooooo"}
                                                    onClick={event => {
                                                        setFilterOnlyInvited(!filterOnlyInvited);
                                                        setFilterOnlyRegistered(false);
                                                    }}
                                                />
                                                Only Invited Customers
                                            </div>
                                        </div>
                                    </Collapse>
                                </Paper>
                            ) : (
                                //desktop version
                                <Fragment>
                                    Filters:
                                    <TextField
                                        label="First Name"
                                        value={filterFirstName}
                                        onChange={event => {
                                            setFilterFirstName(event.target.value);
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        label="Email"
                                        value={filterEmail}
                                        onChange={event => {
                                            setFilterEmail(event.target.value);
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <div className={"check-box"}>
                                        <Checkbox
                                            color={"secondary"}
                                            checked={filterOnlyRegistered}
                                            onClick={event => {
                                                setFilterOnlyRegistered(!filterOnlyRegistered);
                                                setFilterOnlyInvited(false);
                                            }}
                                        />
                                        Only Registered Customers
                                    </div>
                                    <div className={"check-box"}>
                                        <Checkbox
                                            color={"secondary"}
                                            checked={filterOnlyInvited}
                                            onClick={event => {
                                                setFilterOnlyInvited(!filterOnlyInvited);
                                                setFilterOnlyRegistered(false);
                                            }}
                                        />
                                        Only Invited Customers
                                    </div>
                                </Fragment>
                            )}
                        </div>
                        {loading ? (
                            <CircularProgress />
                        ) : customers.length > 0 || notActivatedCustomers.length > 0 ? (
                            <Fragment>
                                <Paper className={"customer-table"}>
                                    <div className={"customer-panel-table"}>
                                        {css.isMobile ? (
                                            // mobile version
                                            <div className={"customer-panel-header-row"}>
                                                <div className={"customer-panel-header-cell-mobile"}>
                                                    {lang.history.column_select_header}
                                                </div>
                                                <div className={"customer-panel-header-cell-mobile-big"}>First Name</div>
                                                <div className={"customer-panel-header-cell-mobile-big"}>Last Name</div>
                                                <div className={"customer-panel-header-cell-mobile"}></div>
                                            </div>
                                        ) : (
                                            //desktop version
                                            <div className={"customer-panel-header-row"}>
                                                <div className={"customer-panel-header-cell"}>{lang.history.column_select_header}</div>
                                                <div className={"customer-panel-header-cell-small"}>First Name</div>
                                                <div className={"customer-panel-header-cell-small"}>Last Name</div>
                                                <div className={"customer-panel-header-cell"}>Email</div>
                                                <div className={"customer-panel-header-cell-small"}>Status</div>
                                                <div className={"customer-panel-header-cell"}>Are calculations shared</div>
                                                <div className={"customer-panel-header-cell-small"}>Language</div>
                                                <div className={"customer-panel-header-cell"}></div>
                                            </div>
                                        )}
                                        <div className={"customer-panel-body"}>
                                            {customers.map((customer, customerIndex) => {
                                                return (
                                                    <ConsumerPanelRow
                                                        key={customerIndex}
                                                        first_name={customer.first_name}
                                                        prefix={customer.prefix === null ? "" : customer.prefix}
                                                        last_name={customer.last_name}
                                                        email={customer.email}
                                                        onClick={onSelectCheckBox}
                                                        isSelected={customerId === customerIndex}
                                                        customerIndex={customerIndex}
                                                        state={customer.state}
                                                        uuid={customer.uuid}
                                                        are_logs_shared={customer.are_logs_shared}
                                                        are_comparisons_shared={customer.are_comparisons_shared}
                                                        customerUuid={customer.uuid}
                                                        onSharedChange={onSharedChange}
                                                        language={customer.language}
                                                    />
                                                );
                                            })}
                                            {notActivatedCustomers.map((customer, customerIndex) => {
                                                return (
                                                    <ConsumerPanelRow
                                                        key={customerIndex + lastCustomerId}
                                                        first_name={customer.first_name_of_receiver}
                                                        prefix={customer.prefix_of_receiver === null ? "" : customer.prefix_of_receiver}
                                                        last_name={customer.last_name_of_receiver}
                                                        email={customer.unactivated_email}
                                                        onClick={onSelectCheckBox}
                                                        isSelected={customerId === customerIndex + lastCustomerId}
                                                        customerIndex={customerIndex + lastCustomerId}
                                                        state={customer.status}
                                                        uuid={customer.uuid}
                                                        are_logs_shared={customer.are_logs_shared_unactivated}
                                                        are_comparisons_shared={customer.are_comparisons_shared_unactivated}
                                                        customerUuid={customer.uuid}
                                                        onSharedChange={onSharedChange}
                                                        language={customer.language}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Paper>
                            </Fragment>
                        ) : (
                            <div className={"no-customers-text"}>
                                <Typography variant={"subtitle1"}>No customers.</Typography>
                            </div>
                        )}
                    </div>
                </div>
                <Dialog open={isInviteDialogOpen} aria-labelledby="draggable-invite-dialog" fullWidth={true} maxWidth={"sm"}>
                    <DialogTitle id="draggable-invite-dialog">
                        {statusOfNewCustomer === "Created" ? "Create customer" : "Invite customer"}
                    </DialogTitle>
                    <DialogContent>
                        <Input
                            id={`input-email`}
                            label="Email address:"
                            type={"text"}
                            onChange={event => {
                                setEmail(event.target.value);
                            }}
                            value={email}
                            readOnly={false}
                            tooltipText={
                                statusOfNewCustomer === "Created"
                                    ? "Please enter the email of the customer, that you want to create."
                                    : "Please enter the email of the customer, that you want to invite."
                            }
                            errorMessage={createCustomerError === "" ? null : createCustomerError}
                        />
                        <Input
                            id={`input-first-name`}
                            label="First Name:"
                            type={"text"}
                            onChange={event => {
                                setInvitationFirstName(event.target.value);
                            }}
                            value={invitationFirstName}
                            readOnly={false}
                            tooltipText={
                                statusOfNewCustomer === "Created"
                                    ? "Please enter the first name of the customer, that you want to create."
                                    : "Please enter the first name of the customer, that you want to invite."
                            }
                        />
                        <Input
                            id={`input-prefix`}
                            label="Prefix:"
                            type={"text"}
                            onChange={event => {
                                setInvitationPrefix(event.target.value);
                            }}
                            value={invitationPrefix}
                            readOnly={false}
                            tooltipText={"If the customer has a prefix between his first and last name, you can enter it here."}
                        />
                        <Input
                            id={`input-last-name`}
                            label="Last Name:"
                            type={"text"}
                            onChange={event => {
                                setInvitationLastName(event.target.value);
                            }}
                            value={invitationLastName}
                            readOnly={false}
                            tooltipText={
                                statusOfNewCustomer === "Created"
                                    ? "Please enter the last name of the customer, that you want to create."
                                    : "Please enter the last name of the customer, that you want to invite."
                            }
                        />
                        <div className={"consumer-panel-radio-invite"}>
                            <Radio
                                onChange={event => {
                                    setUserLanguage(event.target.value);
                                }}
                                inputs={[
                                    { label: "Dutch", value: "Dutch" },
                                    { label: "English", value: "English" },
                                ]}
                                value={userLanguage}
                                label={"Language:"}
                                tooltipText={"Select the language that the customer speaks."}
                                name={"customer-language"}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeInviteDialog} color="secondary">
                            {lang.history.bar_calculation_loaded_title_button_close}
                        </Button>
                        <Button
                            onClick={() => {
                                sendInvitation(email, statusOfNewCustomer);
                            }}
                            variant="contained"
                            color="secondary"
                        >
                            {statusOfNewCustomer === "Created" ? "CREATE CUSTOMER" : "SEND INVITATION"}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={openSnackInvite}
                    onClose={handleCloseSnack}
                    autoHideDuration={6000}
                    message={
                        <span id="message-id">
                            {statusOfNewCustomer === "Created" ? "New customer has been created" : "Email sent successfully"}
                        </span>
                    }
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                />
                <Snackbar
                    open={openSnackSelect}
                    onClose={handleCloseSnack}
                    autoHideDuration={6000}
                    message={
                        <span id="message-id">
                            {customerFirstName !== ""
                                ? `You have selected to work with ${customerFirstName}`
                                : `You have selected to work with a customer with email ${customerEmail}`}
                        </span>
                    }
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                />
                <BottomSelectionLogBar
                    selectedLog={customerId}
                    text={css.isMobile ? "" : "Users Selected"}
                    primaryAction={async () => {
                        await setOpenSnackSelect(true);
                        await resetLog();
                        await props.setCustomer(
                            customerUuid,
                            customerFirstName,
                            prefix,
                            customerLastName,
                            customerEmail,
                            areLogsShared,
                            areComparisonsShared,
                        );
                        await getLastLog(props.auth, { selectedCustomer: { customerUuid } }).then(log => {
                            functions.loadFromLog(log).then(() => {
                                return functions.requestCalculation();
                            });
                        });
                        onSelectCheckBox(customerId);
                    }}
                    primaryActionText={"Start working with this user"}
                    secondaryAction={
                        statusOfSelectedCustomer === "Created" || statusOfSelectedCustomer === "Invited"
                            ? async () => {
                                  await sendInvitation(customerEmail, "Invited");
                                  onSelectCheckBox(customerId);
                              }
                            : undefined
                    }
                    secondaryActionText={
                        statusOfSelectedCustomer === "Created" || statusOfSelectedCustomer === "Invited" ? "Invite customer" : undefined
                    }
                    closeAction={() => onSelectCheckBox(customerId)}
                />
            </Template>
        </div>
    );
}

const styles = theme => ({
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
});

export default connect(mapStateToProps, { setCustomer, resetLog, getLastLog })(withStyles(styles)(ConsumerPanel));
