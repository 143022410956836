import { SET_LANGUAGE, GET_LANGUAGE } from "../Actions/languageAction/types";
import english from "../Languages/English.json";
import dutch from "../Languages/Dutch.json";

const initialState = {
    //selected language of the website
    language: localStorage.getItem("lang") === "English" ? english : dutch,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
        case GET_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
        default:
            return state;
    }
}
