import React from "react";
import { Card, Typography, CardContent, TextField, Button, FormControl, FormHelperText, CircularProgress } from "@material-ui/core";
import "./ResetPasswordStyles.css";
import axios from "axios";
import { connect } from "react-redux";
import { mapStateToProps } from "../../redux";

import { api, origin } from "../../services";
import logo from "../../Images/logo_hyponav_beta.svg";
import { setLanguage } from "../../Actions/languageAction/actions";

class SendEmailResetPassword extends React.Component {
    state = {
        email: "",
        errors: {
            email: false
        },
        isLoading: false
    };

    onInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    sendEmail = () => {
        const { email, errors } = this.state;
        this.setState({ isLoading: true });

        let _errors = errors;

        if (email.indexOf("@") >= 0) {
            _errors.email = false;
            this.setState({ errors: _errors });
            const lang = localStorage.getItem("lang") === "English" ? "English" : "Dutch";

            axios({
                method: "post",
                // url: `http://localhost:3000/v1/auth/forgot`,
                url: `${api.url}/auth/forgot`,
                data: {
                    url: `${origin.url}/reset`,
                    email: email,
                    language: lang
                }
            })
                .then(response => {
                    if (response.data.data.code === "email/sent") {
                        this.props.history.push("/wachtwoord-vergeten-bevestiging");
                    }
                })
                .catch(error => {
                    if (error.response.data.error.code.includes("email")) {
                        _errors.email = true;
                        this.setState({ errors: _errors, isLoading: false });
                    }
                });
        } else {
            _errors.email = true;
            this.setState({ errors: _errors, isLoading: false });
        }
    };

    redirectToLogin = () => {
        this.props.history.push("/inloggen");
    };

    render() {
        const { errors, isLoading } = this.state;
        const { lang } = this.props;

        return (
            <div className={"dom"}>
                <img src={logo} alt="Logo" style={{ width: "300px", margin: "40px 0" }} />
                <Card className={"card"}>
                    <CardContent className={"card-content"}>
                        <Typography variant={"h2"} style={{ margin: "20px 0", textAlign: "center" }}>
                            {lang.forgotPassword.title}
                        </Typography>
                        <Button
                            color="primary"
                            variant={"text"}
                            onClick={() => {
                                this.props.setLanguage(null);
                            }}
                        >
                            {lang.forgotPassword.button_language}
                        </Button>

                        <FormControl error={errors.email} className={"sign-form-input"}>
                            <TextField
                                error={errors.email}
                                label={lang.forgotPassword.email_address_label}
                                variant={"outlined"}
                                className={"sign-input"}
                                type="email"
                                name="email"
                                onChange={this.onInputChange}
                                autoComplete="email"
                                margin="normal"
                            />
                            {errors.email ? (
                                <FormHelperText className={"error-message"}>
                                    {lang.forgotPassword.email_address_validation_non_existing}
                                </FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>

                        <Button size="large" color="secondary" variant="contained" className={"sign-in-button"} onClick={this.sendEmail}>
                            {isLoading ? <CircularProgress className={"loader"} /> : lang.forgotPassword.button_forgot_password}
                        </Button>
                        <div className={"sign-help"}>
                            <Button variant="text" color="primary" onClick={this.redirectToLogin}>
                                {lang.forgotPassword.button_login}
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default connect(mapStateToProps, { setLanguage })(SendEmailResetPassword);
