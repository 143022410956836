import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import React, { Fragment, useEffect } from "react";

import { mapStateToProps } from "./../redux";
import { authVerifyToken } from "../Actions/authActions/actions";
import { setDirectlyLanguage } from "../Actions/languageAction/actions";

//Contexts
import { CalculatorContextComponent, CalculatorContext } from "./../Contexts/CalculatorContext";

//Boot
import BootApplication from "../Components/BootApplication/BootApplication";

//Views
import Advisor from "../Views/Advisor/Advisor";
import Calculator from "../Views/Calculator/Calculator";
import EmailSendRegister from "../Views/EmailSend/EmailSendRegister";
import EmailSendForgot from "../Views/EmailSend/EmailSendForgot";
import History from "../Views/History/History";
import Home from "../Views/Home/Home";
import NotFound from "../Views/404/404";
import ResetPassword from "../Views/ResetPassword/ResetPassword";
import SendEmailResetPassword from "../Views/ResetPassword/SendEmailResetPassword";
import SignIn from "../Views/SignIn/SignIn";
import SignUp from "../Views/SignUp/SignUp";
import VerifyEmail from "../Views/VerifyEmail/VerifyEmail";
import ConsumerPanel from "../Views/AdvisorViews/ConsumerPanel";
import FirstTimeVisit from "../Views/FirstTimeVisit/FirstTimeVisit";
import Comparison from "../Views/Comparison/Comparison";
import ComparisonDetails from "../Views/Comparison/ComparisonDetails";
import ProfilePage from "../Views/Profile/ProfilePage";
import { signOut } from "../Actions/authActions/actions";

function Router(props) {
    useEffect(() => {
        props.authVerifyToken().then(res => {
            if (res.user !== null) {
                props.setDirectlyLanguage(res.user.language);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { auth, lang, logs } = props;

    return !auth.isAuthStateChanged ? (
        <div />
    ) : (
        <BrowserRouter>
            <Switch>
                {auth.isUserAuthenticated && auth.token !== null ? (
                    <CalculatorContextComponent auth={auth} lang={lang}>
                        <CalculatorContext.Consumer>
                            {calculatorContext => {
                                return (
                                    <BootApplication calculator={calculatorContext}>
                                        {calculatorContext.product !== null ? (
                                            logs.isFirstTime ? (
                                                <Switch>
                                                    <Route path="/invoer-gegevens" exact component={FirstTimeVisit} />
                                                    <Redirect to={"/invoer-gegevens"} />
                                                </Switch>
                                            ) : (
                                                <Switch>
                                                    <Route path="/" exact component={Home} />
                                                    <Route path="/dashboard" exact component={Calculator} />
                                                    <Route path="/geschiedenis" exact component={History} />
                                                    <Route path="/adviseur" exact component={Advisor} />
                                                    <Route path="/klanten" exact component={ConsumerPanel} />
                                                    <Route path="/vergelijken" exact component={Comparison} />
                                                    <Route path="/vergelijken/details" exact component={ComparisonDetails} />
                                                    <Route path="/mijn-profiel" exact component={ProfilePage} />
                                                    {/* Remove before push */}
                                                    {/* {auth.user.role === "basic" ? <Route path="/invoer-gegevens" exact component={FirstTimeVisit} /> : null} */}
                                                    {/* Remove until here */}
                                                    <Redirect to={"/dashboard"} />
                                                </Switch>
                                            )
                                        ) : (
                                            <Fragment />
                                        )}
                                    </BootApplication>
                                );
                            }}
                        </CalculatorContext.Consumer>
                    </CalculatorContextComponent>
                ) : (
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/inloggen" exact component={SignIn} />
                        <Route path="/registratie" exact component={SignUp} />
                        <Route path="/wachtwoord-vergeten" exact component={SendEmailResetPassword} />
                        <Route path="/reset" exact component={ResetPassword} />
                        <Route path="/registratie-bevestiging" exact component={EmailSendRegister} />
                        <Route path="/wachtwoord-vergeten-bevestiging" exact component={EmailSendForgot} />
                        <Route path="/verify" exact component={VerifyEmail} />
                        <Redirect to={"/inloggen"} />
                    </Switch>
                )}
                <Route path="/404" exact component={NotFound} />
                <Redirect to={"/404"} />
            </Switch>
        </BrowserRouter>
    );
}

export default connect(mapStateToProps, { authVerifyToken, signOut, setDirectlyLanguage })(Router);
