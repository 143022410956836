import moment from "moment";
import axios from "axios";
import _ from "lodash";

import {
    LOG_CREATE,
    LOG_GET,
    LOG_GET_LAST_LOG,
    LOG_DELETE,
    LOG_DELETE_LOCAL,
    LOG_UNLOAD,
    LOG_LOAD_LOGS,
    LOG_RESET,
    LOG_LOAD,
    LOG_UNLOAD_CURRENT,
    CHECK_IF_FIRST_TIME_USER,
    COMPARISON_CREATE,
    LOG_LOAD_COMPARISONS,
    COMPARISON_DELETE,
    COMPARISON_DELETE_LOCAL,
    COMPARISONS_RESET,
} from "./types";

import { api } from "../../services";

import { getLog as getLogRequest } from "./requests";
import { lastMortgageLogQuery, getLogsQuery, getComparisonsQuery } from "../../Graphql/queries/logQueries";
import {
    createMortgageLogMutation,
    softDeleteLogMutation,
    softDeleteComparisonMutation,
    createMortgageComparisonMutation,
} from "../../Graphql/mutations/logMutations";

/**
 * Creates a log
 * * The function returns a Promise
 * * * then: returns createdLogId<Integer>
 * * * catch: return error info
 * @param {Object} auth object from the store
 * @param {Object} calculatorContext
 */
export const createLog = (auth, calculatorContext, role) => (dispatch) => {
    console.log("createLog");
    return new Promise((resolve, reject) => {
        // if (role.selectedCustomer !== null || auth.user.role !== "advisor") {
        let shared_uuid = null;
        let are_logs_shared = false;

        if (auth.user.role === "basic" || auth.user.role === "premium") {
            const { selectedAdvisor } = role || {};

            shared_uuid = selectedAdvisor === null ? null : selectedAdvisor.uuid;
            are_logs_shared = true;
        } else if (auth.user.role === "advisor") {
            const { selectedCustomer } = role || {};

            if (selectedCustomer) {
                shared_uuid = selectedCustomer.customerUuid;
                are_logs_shared = selectedCustomer.areLogsShared;
            }
        }

        const variables = {
            data: {
                mortgage_amount: calculatorContext.mortgageAmount.value,
                value_house: calculatorContext.valueHouse.value,
                purchase_price_house: calculatorContext.purchasePriceHouse.value,
                parts: calculatorContext.parts.parts.map((part) => {
                    return {
                        mortgage_amount: part.mortgageAmount.value,
                        mortgage_type: part.mortgageType.value,
                        duration: part.duration.value,
                        interest_fixed_period: part.interestFixedPeriod.value.toString(),
                        interest_rate: part.interestRate.value,
                    };
                }),
                incomes: calculatorContext.applicants.incomes.map((income) => {
                    return income.value;
                }),
                start_date: calculatorContext.startDate.value,
                force_no_nhg: calculatorContext.forceNoNhg.value,
                transfer_tax: calculatorContext.transferTax.value,
                notary_deed_of_transfer: calculatorContext.notaryDeedOfTransfer.value,
                bank_guarantee: calculatorContext.bankGuarantee.value,
                architectural_inspection: calculatorContext.architecturalInspection.value,
                notary_mortgage: calculatorContext.notaryMortgage.value,
                evaluation: calculatorContext.valuation.value,
                mortgage_advice: calculatorContext.mortgageAdvice.value,
                mortgage_application: calculatorContext.mortgageApplication.value,
                interest_rate_key: calculatorContext.interestRateKey,
                ltv: calculatorContext.ltv.value,
            },
            product: calculatorContext.product,
            calculations: {
                max_mortgage_from_income: calculatorContext.maxMortgageAmountIncome,
                max_mortgage_from_house: parseInt(calculatorContext.maxMortgageHouse.value),
                is_nhg_possible: calculatorContext.isNhgPossible.value,
                required_mortgage_amount: calculatorContext.requiredMortgageAmount.value,
                required_savings: calculatorContext.requiredSavings.value,
                nhg_costs: calculatorContext.nhgCosts.value,
            },
            shared_uuid: shared_uuid,
            are_logs_shared: are_logs_shared,
        };

        axios({
            method: "post",
            url: `${api.url}/logs`,
            // url: `http://localhost:3000/v1/logs`,
            data: {
                query: createMortgageLogMutation,
                variables: variables,
            },
            withCredentials: false,
            headers: { Authorization: `Bearer ${auth.token}` },
        })
            .then((response) => {
                const log = response.data.data.create_mortgage_log;
                calculatorContext.createdAt.setValue(log.created_at);
                calculatorContext.isCalculationMine.setValue(true);

                dispatch({
                    type: LOG_CREATE,
                    payload: log,
                });
                dispatch({
                    type: LOG_UNLOAD,
                    payload: null,
                });

                resolve(log);
            })
            .catch((error) => {
                reject(error);
                console.error("LOG_CREATE::FAIL", error);
            });
    });
};

export const createComparison = (firstId, secondId) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { auth, role } = getState();
        let shared_uuid;
        let are_comparisons_shared;

        if (auth.user.role === "basic" || auth.user.role === "premium") {
            shared_uuid = role.selectedAdvisor === null ? null : role.selectedAdvisor.uuid;
            are_comparisons_shared = true;
        } else if (auth.user.role === "advisor") {
            shared_uuid = role.selectedCustomer.customerUuid;
            are_comparisons_shared = role.selectedCustomer.areComparisonsShared;
        }

        const variables = {
            shared_uuid: shared_uuid,
            are_comparisons_shared: are_comparisons_shared,
            first_comparison_id: firstId,
            second_comparison_id: secondId,
        };

        axios({
            method: "post",
            url: `${api.url}/logs`,
            // url: `http://localhost:3000/v1/logs`,
            data: {
                query: createMortgageComparisonMutation,
                variables: variables,
            },
            withCredentials: false,
            headers: { Authorization: `Bearer ${auth.token}` },
        })
            .then((response) => {
                const comparison = response.data.data.create_mortgage_comparison;

                dispatch({
                    type: COMPARISON_CREATE,
                    payload: comparison,
                });

                resolve(comparison);
            })
            .catch((error) => {
                reject(error);
                console.error("COMPARISON_CREATE::FAIL", error);
            });
    });
};
/**
 * Soft deletes the log corresponding to the id param
 * * The function returns a Promise
 * * * then: void
 * * * catch: return error info
 * @param {Integer} id id of the log
 * @param {Object} auth auth object from the store
 */
export const deleteLog = (id, auth) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${api.url}/logs`,
            withCredentials: false,
            data: {
                query: softDeleteLogMutation,
                variables: {
                    id: id,
                },
            },
            headers: { Authorization: `Bearer ${auth.token}` },
        })
            .then((res) => {
                const response = res.data.data.paranoid_delete_mortgage_log;

                dispatch({
                    type: LOG_DELETE,
                    payload: response,
                });

                resolve(response);
            })
            .catch((error) => {
                reject(error);

                console.error("LOG_DELETE::FAIL", error);
            });
    });
};

/**
 * deletes the element at the specified indexes, from the logs array
 * * The function returns a Promise
 * * * then: returns an updated logs array(without the deleted log)
 * * * catch: return error info
 * @param {Array} logs from the store
 * @param {Integer} parentIndex index of the parent element of the index(the table)
 * @param {Integer} logIndex the log index
 */
export const deleteLogLocal = (logs, parentIndex, logIndex) => (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            const newLogs = _.cloneDeep(logs);

            newLogs[parentIndex].logs.splice(logIndex, 1);

            if (newLogs[parentIndex].logs.length === 0) {
                newLogs.splice(parentIndex, 1);
            }

            dispatch({
                type: LOG_DELETE_LOCAL,
                payload: newLogs,
            });

            resolve(newLogs);
        } catch (error) {
            reject(error);

            console.error("LOG_DELETE_LOCAL::FAIL", error);
        }
    });
};

/**
 * removes the currently loaded logs
 * * The function returns a Promise
 * * * then: void
 * * * catch: return error info
 */
export const unloadLog = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: LOG_UNLOAD,
                payload: null,
            });

            resolve();
        } catch (error) {
            reject(error);

            console.error("LOG_UNLOAD::FAIL", error);
        }
    });
};

/**
 * removes the current log
 * * The function returns a Promise
 * * * then: void
 * * * catch: return error info
 */
export const unloadCurrentLog = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: LOG_UNLOAD_CURRENT,
                payload: null,
            });

            resolve();
        } catch (error) {
            reject(error);

            console.error("LOG_UNLOAD_CURRENT::FAIL", error);
        }
    });
};

/**
 * Gets the log from for the id param
 * * The function returns a Promise
 * * * then: returns { log<Object> }
 * * * catch: return error info
 * @param {Integer} id id of the log
 * @param {Object} auth auth object from the store
 * @param {Object} role role object from the store
 * @param {Boolean} shouldLoadLog used to check if the log will be loaded or only viewed
 */
export const getLog = (id, auth, role, shouldLoadLog) => (dispatch) => {
    return new Promise((resolve, reject) => {
        let shared_uuid;
        if (auth.user.role === "basic" || auth.user.role === "premium") {
            shared_uuid = role.selectedAdvisor === null ? null : role.selectedAdvisor.uuid;
        } else if (auth.user.role === "advisor") {
            shared_uuid = role.selectedCustomer.customerUuid;
        }
        getLogRequest(id, shared_uuid, auth.token)
            .then((response) => {
                const log = response;
                if (shouldLoadLog) {
                    dispatch({
                        type: LOG_LOAD,
                        payload: log,
                    });
                } else {
                    dispatch({
                        type: LOG_GET,
                        payload: log,
                    });
                }

                resolve(log);
            })
            .catch((error) => {
                reject(error);

                console.error("LOG_GET::FAIL", error);
            });
    });
};

/**
 * Gets the state for the last log, if there is any
 * * The function returns a Promise
 * * * then: returns { state<Object> }
 * * * catch: return error info
 * @param {Object} auth auth object from the store
 * @param {Object} role role object from the store
 */
export const getLastLog = (auth, role) => (dispatch) => {
    return new Promise((resolve, reject) => {
        let shared_uuid = null;
        if (auth.user.role === "advisor") {
            if (role.selectedCustomer !== null || role.selectedCustomer !== undefined) {
                shared_uuid = role.selectedCustomer.customerUuid;
            }
        }
        axios({
            method: "post",
            url: `${api.url}/logs`,
            // url: `http://localhost:3000/v1/logs`,
            withCredentials: false,
            data: {
                query: lastMortgageLogQuery,
                variables: {
                    shared_uuid,
                },
            },
            headers: { Authorization: `Bearer ${auth.token}` },
        })
            .then((response) => {
                const log = response.data.data.last_mortgage_log;

                dispatch({
                    type: LOG_GET_LAST_LOG,
                    payload: log,
                });

                resolve(log);
            })
            .catch((error) => {
                reject(error);
                console.error("LOG_GET_LAST_LOG::FAIL", error);
            });
    });
};

/**
 * gets the logs from the backend
 * * The function returns a Promise
 * * * then: returns {logs<Array>, lastCreatedLogId<Integer>, areLogsLoaded<Boolean>, firstLogId<Integer>, lastLogId<Integer>}
 * * * catch: return error info
 * @param {Object} logs logs object from the store
 * @param {Object} auth auth object from the store
 */
export const getLogs = (logs, offset, auth, role) => (dispatch) => {
    return new Promise((resolve, reject) => {
        let shared_uuid = null;

        if (auth.user.role === "basic" || auth.user.role === "premium") {
            const { selectedAdvisor } = role || {};

            if (selectedAdvisor) {
                shared_uuid = selectedAdvisor === null ? null : selectedAdvisor.uuid;
            }
        } else if (auth.user.role === "advisor") {
            const { selectedCustomer } = role || {};

            if (selectedCustomer) {
                shared_uuid = selectedCustomer.customerUuid;
            }
        }

        axios({
            method: "post",
            url: `${api.url}/logs`,
            // url: `http://localhost:3000/v1/logs`,
            withCredentials: false,
            data: {
                query: getLogsQuery,
                variables: {
                    offset,
                    limit: 15,
                    shared_uuid: shared_uuid,
                },
            },
            headers: { Authorization: `Bearer ${auth.token}` },
        })
            .then((res) => {
                const response = res.data.data.mortgage_logs;

                const newLogs = _.cloneDeep(logs);

                response.forEach((log) => {
                    const date = moment(log.created_at, "x").toDate();

                    let formattedDateFromLog = moment(log.created_at, "x").format("dddd DD MMM YYYY");

                    if (newLogs.length > 0 && formattedDateFromLog === newLogs[newLogs.length - 1].formatedDate) {
                        newLogs[newLogs.length - 1]["logs"].push(log);
                    } else {
                        newLogs.push({ formatedDate: formattedDateFromLog, date: date, logs: [log] });
                    }
                });

                const payload = {
                    logs: newLogs,
                    areLogsLoaded: true,
                    offset: offset + 15,
                    hasLoadedAllLogs: response.length === 0,
                };

                dispatch({
                    type: LOG_LOAD_LOGS,
                    payload: payload,
                });

                resolve(payload);
            })
            .catch((error) => {
                reject(error);
                console.error("LOG_LOAD_LOGS::FAIL", error);
            });
    });
};

/**
 * gets the comparisons from the backend
 * * The function returns a Promise
 * * * then: returns {id<Integer>, first_comparison<Object>, second_comparison<Object>, created_at<Integer>}
 * * * catch: return error info
 * @param {Object} logs logs object from the store
 * @param {Object} auth auth object from the store
 */
export const getComparisons = (comparisons, offset, auth, role) => (dispatch) => {
    return new Promise((resolve, reject) => {
        let shared_uuid;
        if (auth.user.role === "basic" || auth.user.role === "premium") {
            shared_uuid = role.selectedAdvisor === null ? null : role.selectedAdvisor.uuid;
        } else if (auth.user.role === "advisor") {
            shared_uuid = role.selectedCustomer.customerUuid;
        }

        axios({
            method: "post",
            url: `${api.url}/logs`,
            // url: `http://localhost:3000/v1/logs`,
            withCredentials: false,
            data: {
                query: getComparisonsQuery,
                variables: {
                    offset,
                    limit: 10,
                    shared_uuid: shared_uuid,
                },
            },
            headers: { Authorization: `Bearer ${auth.token}` },
        })
            .then((res) => {
                const response = res.data.data.mortgage_comparisons;

                const newComparisons = _.cloneDeep(comparisons);
                response.forEach((comparison) => {
                    const date = moment(comparison.created_at, "x").toDate();

                    let formatedDateFromComparison = moment(comparison.created_at, "x").format("dddd DD MMM YYYY");

                    if (
                        newComparisons.length > 0 &&
                        formatedDateFromComparison === newComparisons[newComparisons.length - 1].formatedDate
                    ) {
                        newComparisons[newComparisons.length - 1]["comparisons"].push(comparison);
                    } else {
                        newComparisons.push({ formatedDate: formatedDateFromComparison, date: date, comparisons: [comparison] });
                    }
                });

                const payload = {
                    comparisons: newComparisons,
                    areComparisonsLoaded: true,
                    offsetComparisons: offset + 10,
                    hasLoadedAllComparisons: response.length === 0,
                };

                dispatch({
                    type: LOG_LOAD_COMPARISONS,
                    payload: payload,
                });

                resolve(payload);
            })
            .catch((error) => {
                reject(error);
                console.error("LOG_LOAD_COMPARISONS::FAIL", error);
            });
    });
};

/**
 * Soft deletes the comparison corresponding to the id param
 * * The function returns a Promise
 * * * then: void
 * * * catch: return error info
 * @param {Integer} id id of the log
 * @param {Object} auth auth object from the store
 */
export const deleteComparison = (id, auth) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            // url: `http://localhost:3000/v1/logs`,
            url: `${api.url}/logs`,
            withCredentials: false,
            data: {
                query: softDeleteComparisonMutation,
                variables: {
                    id: id,
                },
            },
            headers: { Authorization: `Bearer ${auth.token}` },
        })
            .then((res) => {
                const response = res.data.data.paranoid_delete_mortgage_comparison;

                dispatch({
                    type: COMPARISON_DELETE,
                    payload: response,
                });

                resolve(response);
            })
            .catch((error) => {
                reject(error);

                console.error("COMPARISON_DELETE::FAIL", error);
            });
    });
};

/**
 * deletes the element at the specified indexes, from the comparisons array
 * * The function returns a Promise
 * * * then: returns an updated comparisons array(without the deleted comparison)
 * * * catch: return error info
 * @param {Array} comparisons from the store
 * @param {Integer} parentIndex index of the parent element of the index(the table)
 * @param {Integer} comparisonIndex the comparison index
 */
export const deleteComparisonLocal = (comparisons, parentIndex, comparisonIndex) => (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            const newComparisons = _.cloneDeep(comparisons);

            newComparisons[parentIndex].comparisons.splice(comparisonIndex, 1);

            if (newComparisons[parentIndex].comparisons.length === 0) {
                newComparisons.splice(parentIndex, 1);
            }

            dispatch({
                type: COMPARISON_DELETE_LOCAL,
                payload: newComparisons,
            });

            resolve(newComparisons);
        } catch (error) {
            reject(error);

            console.error("COMPARISON_DELETE_LOCAL::FAIL", error);
        }
    });
};

export const checkIfFirstTimeUser = (auth) => (dispatch) => {
    return new Promise((resolve, reject) => {
        if (auth.user.role === "basic" || auth.user.role === "premium") {
            axios({
                method: "post",
                url: `${api.url}/logs`,
                // url: `http://localhost:3000/v1/logs`,
                data: {
                    query: `query{check_if_first_time_user}`,
                },
                withCredentials: false,
                headers: { Authorization: `Bearer ${auth.token}` },
            })
                .then((res) => {
                    dispatch({
                        type: CHECK_IF_FIRST_TIME_USER,
                        payload: res.data.data.check_if_first_time_user,
                    });
                    resolve(res.data.data.check_if_first_time_user);
                })
                .catch((err) => {
                    dispatch({
                        type: CHECK_IF_FIRST_TIME_USER,
                        payload: null,
                    });
                    reject(err);
                });
        } else {
            dispatch({
                type: CHECK_IF_FIRST_TIME_USER,
                payload: false,
            });
            resolve(true);
        }
    });
};

export const resetLog = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: LOG_RESET,
            });
            resolve(true);
        } catch (error) {
            reject(error);
            console.error("LOG_RESET::FAIL", error);
        }
    });
};

export const resetComparisons = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: COMPARISONS_RESET,
            });
            resolve(true);
        } catch (error) {
            reject(error);
            console.error("COMPARISONS_RESET::FAIL", error);
        }
    });
};
