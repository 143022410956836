import _ from "lodash";
import axios from "axios";
import moment from "moment";
import React, { createContext, Component } from "react";

import { api } from "./../services";
import { getRateKey, format, calculateLTV } from "./../helperFunctions";
import { log } from "../redux";

import { getMortgageProductQuery, getMortgageProductsQuery } from "../Graphql/queries/productQueries";
import { Promise } from "q";

export const CalculatorContext = createContext();

const requestDelay = 600;

export class CalculatorContextComponent extends Component {
    static contextType = CalculatorContext;

    constructor(props) {
        super(props);

        this.currentDate = new Date();

        this.debouncedNHGUpdate = _.debounce(() => {
            this.requestNHGDefault();
        }, requestDelay);

        this.debouncedNHGAmountUpdate = _.debounce(() => {
            this.requestNHGWithAmount();
        }, requestDelay);

        this.debouncedNHGSavingsUpdate = _.debounce(() => {
            this.requestNHGWithSavings();
        }, requestDelay);

        this.state = {
            cachedSomeProp: this.props.lang,
            mortgageAmount: {
                value: 290000,
                setValue: this.setMortgageAmount,
                error: null,
                conditions: { min: 5000, max: 9999999 },
                errors: {
                    min: `${this.props.lang.mortgageAmount.min_price} 5.000`,
                    max: `${this.props.lang.mortgageAmount.max_price} 9.999.999`,
                    moreThanMaxMortgageIncome: "Please fill in an amount less than the maximum mortgage for the income",
                    moreThanMaxMortgageHouse: "Please fill in an amount less than the maximum mortgage for the house"
                }
            },
            valueHouse: {
                value: 290000,
                setValue: this.setValueHouse,
                error: null,
                conditions: { min: 50000, max: 5000000 },
                errors: {
                    min: `${this.props.lang.mortgageAmount.min_price} 50.000`,
                    max: `${this.props.lang.mortgageAmount.max_price} 5.000.000`,
                    lessThanMortgageAmount: "Please fill in an amount more than the mortgage amount"
                }
            },
            startDate: {
                value: `1-${this.currentDate.getMonth() === 11 ? 1 : this.currentDate.getMonth() + 2}-${
                    this.currentDate.getMonth() === 11 ? this.currentDate.getFullYear() + 1 : this.currentDate.getFullYear()
                }`,
                setValue: this.setStartDate
            },

            credit: {
                value: 0,
                setValue: this.setCreditValue,
                error: null,
                conditions: { min: 100, max: 5000000, equal: 0 },
                errors: {
                    min: `${this.props.lang.mortgageAmount.min_price} 100 or 0`,
                    max: `${this.props.lang.mortgageAmount.max_price} 5.000.000 or 0`
                }
            },

            hasCredit: {
                value: false,
                setValue: this.setHasCreditValue,
                inputs: [
                    { value: true, label: this.props.lang.mortgageAmount.nhg_yes },
                    { value: false, label: this.props.lang.mortgageAmount.nhg_no }
                ]
            },

            studyLoan: {
                value: 0,
                date: "",
                setValue: this.setStudyLoan,
                setDate: this.setStudyLoanDate,
                error: null,
                conditions: { min: 100, max: 5000000, equal: 0 },
                errors: {
                    min: `${this.props.lang.mortgageAmount.min_price} 100 or 0`,
                    max: `${this.props.lang.mortgageAmount.max_price} 5.000.000 or 0`
                },
                validateDate: this.validateStudyLoanDate
            },

            hasStudyLoan: {
                value: false,
                setValue: this.setHasStudyLoan,
                inputs: [
                    { value: true, label: this.props.lang.mortgageAmount.nhg_yes },
                    { value: false, label: this.props.lang.mortgageAmount.nhg_no }
                ]
            },

            forceNoNhg: {
                value: false,
                setValue: this.setForceNoNhg,
                inputs: [
                    { value: false, label: this.props.lang.mortgageAmount.nhg_yes },
                    { value: true, label: this.props.lang.mortgageAmount.nhg_no }
                ]
            },
            isNhgPossible: { value: true, setValue: this.setIsNhgPossible },

            maxMortgageAmountIncome: 292905,

            maxMortgageHouse: {
                value: 290000
            },
            purchasePriceHouse: {
                value: 290000,
                setValue: this.setPurchasePriceHouse,
                error: null,
                conditions: { min: 50000, max: 5000000 },
                errors: {
                    min: `${this.props.lang.mortgageAmount.min_price} 50.000`,
                    max: `${this.props.lang.mortgageAmount.max_price}  5.000.000`
                }
            },
            requiredMortgageAmount: { value: 262300 },

            requiredSavings: {
                value: 12300,
                setValue: this.setRequiredSavings,
                conditions: { min: 100, max: 99999999, equal: 0 },
                errors: {
                    min: `${this.props.lang.mortgageAmount.min_price} 100 or 0`,
                    max: `${this.props.lang.mortgageAmount.max_price}  9.999.999 or 0`
                }
            },
            nhgCosts: {
                value: 2250
            },
            transferTax: {
                value: 5800,
                percentage: 0.02
            },
            notaryDeedOfTransfer: {
                value: 600,
                error: null,
                conditions: { min: 100, max: 1000, equal: 0 },
                errors: {
                    min: `${this.props.lang.mortgageAmount.min_price} 100 or 0`,
                    max: `${this.props.lang.mortgageAmount.max_price} 1.000 or 0`
                },
                setValue: this.setNotaryDeedOfTransfer
            },
            bankGuarantee: {
                value: 225,
                error: null,
                percentage: 0.009,
                conditions: { min: 225, max: 1000, equal: 0 },
                errors: {
                    min: `${this.props.lang.mortgageAmount.min_price} 225 or 0`,
                    max: `${this.props.lang.mortgageAmount.max_price} 1.000 or 0`
                },
                setValue: this.setBankGuarantee
            },
            architecturalInspection: {
                value: 450,
                error: null,
                conditions: { min: 450, max: 1000, equal: 0 },
                errors: {
                    min: `${this.props.lang.mortgageAmount.min_price} 450 or 0`,
                    max: `${this.props.lang.mortgageAmount.max_price} 1.000 or 0`
                },
                setValue: this.setArchitecturalInspection
            },
            notaryMortgage: {
                value: 600,
                error: null,
                conditions: { min: 100, max: 1000, equal: 0 },
                errors: {
                    min: `${this.props.lang.mortgageAmount.min_price} 100 or 0`,
                    max: `${this.props.lang.mortgageAmount.max_price} 1.000 or 0`
                },
                setValue: this.setNotaryMortgage
            },
            valuation: {
                value: 500,
                error: null,
                conditions: { min: 500, max: 1000, equal: 0 },
                errors: {
                    min: `${this.props.lang.mortgageAmount.min_price} 500 or 0`,
                    max: `${this.props.lang.mortgageAmount.max_price} 1.000 or 0`
                },
                setValue: this.setValuation
            },
            mortgageAdvice: {
                value: 1300,
                error: null,
                conditions: { min: 1300, max: 2000, equal: 0 },
                errors: {
                    min: `${this.props.lang.mortgageAmount.min_price} 1.300 or 0`,
                    max: `${this.props.lang.mortgageAmount.max_price} 2.000 or 0`
                },
                setValue: this.setMortgageAdvice
            },
            mortgageApplication: {
                value: 600,
                error: null,
                conditions: { min: 600, max: 2000, equal: 0 },
                errors: {
                    min: `${this.props.lang.mortgageAmount.min_price} 600 or 0`,
                    max: `${this.props.lang.mortgageAmount.max_price} 2.000 or 0`
                },
                setValue: this.setMortgageApplication
            },
            interestRate: { value: 0.0167 },

            ltv: { value: 100 },

            interestRateKey: getRateKey(true, 100),

            parts: {
                parts: [
                    {
                        mortgageAmount: {
                            value: 290000,
                            conditions: { min: 5000, max: 99999999 },
                            error: null
                        },
                        mortgageType: {
                            value: "annuity",
                            requestValue: "annuity"
                        },
                        duration: {
                            value: 30
                        },
                        interestFixedPeriod: {
                            value: 10,
                            requestValue: 10
                        },
                        interestRate: { value: 0.0167 }
                    }
                ],
                addPart: this.addPart,
                removePart: this.removePart,
                setPartMortgageAmount: this.setPartMortgageAmount,
                setPartMortgageType: this.setPartMortgageType,
                setPartMortgageTypeRequest: this.setPartMortgageTypeRequest,
                setPartDuration: this.setPartDuration,
                setPartInterestFixedPeriod: this.setPartInterestFixedPeriod,
                setPartInterestFixedPeriodRequest: this.setPartInterestFixedPeriodRequest,
                setPartInterestRate: this.setPartInterestRate,
                toString: this.convertPartsToString
            },

            applicants: {
                incomes: [
                    {
                        value: 30000,
                        error: null,
                        conditions: { min: 5000, max: 1000000 }
                    },
                    {
                        value: 30000,
                        error: null,
                        conditions: { min: 5000, max: 1000000, equal: 0 }
                    }
                ],
                inputs: [
                    { value: 1, label: "1" },
                    { value: 2, label: "2" }
                ],
                addApplicant: this.addApplicant,
                removeApplicant: this.removeApplicant,
                setApplicantAmount: this.setApplicantAmount
            },

            product: null,

            calculations: {
                isLoading: true,
                totals: {
                    ifp: {
                        gross: null,
                        net: null,
                        avgRate: null,
                        interestMortgageAmount: null
                    },
                    duration: {
                        gross: null,
                        net: null,
                        avgRate: null,
                        interestMortgageAmount: null
                    }
                },
                mortgageAmountCalcualtions: [],
                grossPaymentCalcualtions: [],
                netPaymentCalcualtions: [],
                averageInterestRateCalcualtions: []
            },

            state: {
                oldState: {},
                defaultState: {},
                checkPoint: this.createStateCheckPoint,
                rollback: this.rollbackState
            },

            functions: {
                requestProduct: this.requestProduct,
                requestProducts: this.requestProducts,
                requestCalculation: this.requestCalculation,
                requestMaxMortgageFromIncomeCalculation: this.requestMaxMortgageAmountFromIncome,
                loadFromLog: this.loadFromLog,
                loadProductFromLog: this.loadProductFromLog,
                updateRateKeyAndLTV: this.updateRateKeyAndLTV,
                createDefaultState: this.createDefaultState,
                loadFromDefaultState: this.loadFromDefaultState,
                nhg: {
                    default: this.requestNHGDefault
                }
            },

            createdAt: { value: null, setValue: this.setCreatedAt },
            isCalculationMine: { value: null, setValue: this.setIsCalculationMine },

            rateError: false
        };
    }

    //Todo Remove getDerivedStateFromProps() and move all of the errors to their input fields
    static getDerivedStateFromProps(props, prevState) {
        if (prevState.cachedSomeProp.dashboard.changeLanguage !== props.lang.dashboard.changeLanguage) {
            return {
                mortgageAmount: {
                    ...prevState.mortgageAmount,
                    errors: {
                        ...prevState.mortgageAmount.errors,
                        min: `${props.lang.mortgageAmount.min_price} 5.000`,
                        max: `${props.lang.mortgageAmount.max_price} 9.999.999`
                    }
                },

                valueHouse: {
                    ...prevState.valueHouse,
                    errors: {
                        ...prevState.valueHouse.errors,
                        min: `${props.lang.mortgageAmount.min_price} 50.000`,
                        max: `${props.lang.mortgageAmount.max_price} 5.000.000`
                    }
                },

                credit: {
                    ...prevState.credit,
                    errors: {
                        ...prevState.credit.errors,
                        min: `${props.lang.mortgageAmount.min_price} 100 or 0`,
                        max: `${props.lang.mortgageAmount.max_price} 5.000.000 or 0`
                    }
                },

                hasCredit: {
                    ...prevState.hasCredit,
                    inputs: [
                        { value: true, label: props.lang.mortgageAmount.nhg_yes },
                        { value: false, label: props.lang.mortgageAmount.nhg_no }
                    ]
                },

                studyLoan: {
                    ...prevState.studyLoan,
                    errors: {
                        ...prevState.studyLoan.errors,
                        min: `${props.lang.mortgageAmount.min_price} 100 or 0`,
                        max: `${props.lang.mortgageAmount.max_price} 5.000.000 or 0`
                    }
                },

                hasStudyLoan: {
                    ...prevState.hasStudyLoan,
                    inputs: [
                        { value: true, label: props.lang.mortgageAmount.nhg_yes },
                        { value: false, label: props.lang.mortgageAmount.nhg_no }
                    ]
                },

                forceNoNhg: {
                    ...prevState.forceNoNhg,
                    inputs: [
                        { value: true, label: props.lang.mortgageAmount.nhg_yes },
                        { value: false, label: props.lang.mortgageAmount.nhg_no }
                    ]
                },

                purchasePriceHouse: {
                    ...prevState.purchasePriceHouse,
                    errors: {
                        ...prevState.purchasePriceHouse.errors,
                        min: `${props.lang.mortgageAmount.min_price} 50.000`,
                        max: `${props.lang.mortgageAmount.max_price}  5.000.000`
                    }
                },

                requiredSavings: {
                    ...prevState.requiredSavings,
                    errors: {
                        ...prevState.requiredSavings.errors,
                        min: `${props.lang.mortgageAmount.min_price} 100 or 0`,
                        max: `${props.lang.mortgageAmount.max_price}  9.999.999 or 0`
                    }
                },

                notaryDeedOfTransfer: {
                    ...prevState.notaryDeedOfTransfer,
                    errors: {
                        ...prevState.notaryDeedOfTransfer.errors,
                        min: `${props.lang.mortgageAmount.min_price} 100 or 0`,
                        max: `${props.lang.mortgageAmount.max_price} 1.000 or 0`
                    }
                },

                bankGuarantee: {
                    ...prevState.bankGuarantee,
                    errors: {
                        ...prevState.bankGuarantee.errors,
                        min: `${props.lang.mortgageAmount.min_price} 225 or 0`,
                        max: `${props.lang.mortgageAmount.max_price} 1.000 or 0`
                    }
                },

                architecturalInspection: {
                    ...prevState.architecturalInspection,
                    errors: {
                        ...prevState.architecturalInspection.errors,
                        min: `${props.lang.mortgageAmount.min_price} 450 or 0`,
                        max: `${props.lang.mortgageAmount.max_price} 1.000 or 0`
                    }
                },

                notaryMortgage: {
                    ...prevState.notaryMortgage,
                    errors: {
                        ...prevState.notaryMortgage.errors,
                        min: `${props.lang.mortgageAmount.min_price} 100 or 0`,
                        max: `${props.lang.mortgageAmount.max_price} 1.000 or 0`
                    }
                },

                valuation: {
                    ...prevState.valuation,
                    errors: {
                        ...prevState.valuation.errors,
                        min: `${props.lang.mortgageAmount.min_price} 500 or 0`,
                        max: `${props.lang.mortgageAmount.max_price} 1.000 or 0`
                    }
                },

                mortgageAdvice: {
                    ...prevState.mortgageAdvice,
                    errors: {
                        ...prevState.mortgageAdvice.errors,
                        min: `${props.lang.mortgageAmount.min_price} 1.300 or 0`,
                        max: `${props.lang.mortgageAmount.max_price} 2.000 or 0`
                    }
                },

                mortgageApplication: {
                    ...prevState.mortgageApplication,
                    errors: {
                        ...prevState.mortgageApplication.errors,
                        min: `${props.lang.mortgageAmount.min_price} 600 or 0`,
                        max: `${props.lang.mortgageAmount.max_price} 2.000 or 0`
                    }
                },

                cachedSomeProp: props.lang
            };
        }
        return null;
    }

    createDefaultState = () => {
        return new Promise((resolve, reject) => {
            try {
                const defaultState = _.cloneDeep(this.state);

                log("DEFAULT_STATE", defaultState);

                this.setState({ state: { ...this.state.state, defaultState: defaultState } }, resolve);
            } catch (error) {
                reject(error);
            }
        });
    };

    loadFromDefaultState = () => {
        return new Promise((resolve, reject) => {
            try {
                const defaultState = this.state.state.defaultState;

                log("LOAD_DEFAULT_STATE", defaultState);

                this.setState(defaultState, () => {
                    this.createDefaultState();
                    resolve();
                });
            } catch (error) {
                reject(error);
            }
        });
    };

    createStateCheckPoint = () => {
        return new Promise((resolve, reject) => {
            try {
                const oldState = _.cloneDeep(this.state);

                log("STATE_CHECK_POINT", oldState);

                this.setState({ state: { ...this.state.state, oldState: oldState } }, resolve);
            } catch (error) {
                reject(error);
            }
        });
    };

    rollbackState = () => {
        return new Promise((resolve, reject) => {
            try {
                const oldState = this.state.state.oldState;

                log("STATE_ROLL_BACK", oldState);

                this.setState(oldState, resolve);
            } catch (error) {
                reject(error);
            }
        });
    };

    requestProduct = code => {
        return new Promise((resolve, reject) => {
            try {
                const { parts } = this.state.parts;

                const variables = {
                    code: code,
                    parts: parts.map(part => {
                        return {
                            type: part.mortgageType.requestValue,
                            period: part.interestFixedPeriod.requestValue.toString()
                        };
                    })
                };

                axios({
                    method: "post",
                    url: `${api.url}/resources`,
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${this.props.auth.token}` },
                    data: {
                        query: getMortgageProductQuery,
                        variables: variables
                    }
                })
                    .then(response => {
                        const product = response.data.data.mortgage_product;

                        log("RESOURCES_PRODUCT", product);

                        this.setState({ product }, () => {
                            this.updatePartsFromProduct().then(() => {
                                this.updateRateKeyAndLTV().then(() => {
                                    resolve(product);
                                });
                            });
                        });
                    })
                    .catch(error => {
                        reject(error);
                        console.error(error);
                    });
            } catch (error) {
                reject(error);
                console.error(error);
            }
        });
    };

    requestProducts = () => {
        return new Promise((resolve, reject) => {
            try {
                const { interestRateKey, parts } = this.state;

                const variables = {
                    parts: parts.parts.map(part => {
                        return {
                            type: part.mortgageType.requestValue,
                            period: part.interestFixedPeriod.requestValue.toString()
                        };
                    })
                };

                axios({
                    method: "post",
                    url: `${api.url}/resources`,
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${this.props.auth.token}` },
                    data: {
                        query: getMortgageProductsQuery(interestRateKey),
                        variables: variables
                    }
                })
                    .then(response => {
                        const products = response.data.data.mortgage_products;

                        products.sort((a, b) => {
                            if (a.rates[0][interestRateKey] === null) {
                                return 1;
                            } else if (b.rates[0][interestRateKey] === null) {
                                return -1;
                            } else if (a.rates[0][interestRateKey] === b.rates[0][interestRateKey]) {
                                return 0;
                            }
                            return a.rates[0][interestRateKey] < b.rates[0][interestRateKey] ? -1 : 1;
                        });

                        log("RESOURCES_PRODUCTS", products);

                        resolve(products);
                    })
                    .catch(error => {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    };

    loadProductFromLog = log => {
        return new Promise((resolve, reject) => {
            try {
                if (log === null) {
                    resolve(null);
                }
                const newState = _.cloneDeep(this.state);

                newState.product = log.product;

                const statePart = _.cloneDeep(newState.parts.parts[0]);

                newState.parts.parts = log.input.parts.map(part => {
                    return {
                        ...statePart,
                        mortgageAmount: {
                            ...statePart.mortgageAmount,
                            value: part.mortgage_amount,
                            requestValue: part.mortgage_amount
                        },
                        interestFixedPeriod: {
                            ...statePart.interestFixedPeriod,
                            value: part.interest_fixed_period,
                            requestValue: part.interest_fixed_period
                        },
                        mortgageType: {
                            ...statePart.mortgageType,
                            value: part.mortgage_type,
                            requestValue: part.mortgage_type
                        },
                        interestRate: {
                            ...statePart.interestRate,
                            value: part.interest_rate,
                            requestValue: part.interest_rate
                        },
                        duration: {
                            ...statePart.duration,
                            value: part.duration,
                            requestValue: part.duration
                        }
                    };
                });

                newState.architecturalInspection.value = log.input.architectural_inspection;
                newState.bankGuarantee.value = log.input.bank_guarantee;
                newState.valuation.value = log.input.evaluation;
                newState.forceNoNhg.value = log.input.force_no_nhg;
                newState.architecturalInspection.value = log.input.evaluation;

                newState.applicants.incomes = log.input.incomes.map(income => {
                    return {
                        value: income,
                        error: null,
                        conditions: { min: 5000, max: 1000000 }
                    };
                });

                newState.interestRateKey = log.input.interest_rate_key;
                newState.ltv.value = log.input.ltv;
                newState.mortgageAdvice.value = log.input.mortgage_advice;
                newState.mortgageAmount.value = log.input.mortgage_amount;
                newState.mortgageApplication.value = log.input.mortgage_application;
                newState.notaryDeedOfTransfer.value = log.input.notary_deed_of_transfer;
                newState.notaryMortgage.value = log.input.notary_mortgage;
                newState.purchasePriceHouse.value = log.input.purchase_price_house;
                newState.startDate.value = log.input.start_date;
                newState.transferTax.value = log.input.transfer_tax;
                newState.valueHouse.value = log.input.value_house;

                newState.maxMortgageAmountIncome = log.calculations.max_mortgage_from_income;
                newState.maxMortgageHouse.value = log.calculations.max_mortgage_from_house;
                newState.isNhgPossible.value = log.calculations.is_nhg_possible;
                newState.requiredSavings.value = log.calculations.required_savings;
                newState.nhgCosts.value = log.calculations.nhg_costs;

                newState.createdAt.value = log.created_at;
                newState.isCalculationMine.value = log.is_mine;
                this.setState(newState, () => {
                    resolve([log, newState]);
                });
            } catch (error) {
                reject(error);
            }
        });
    };

    loadFromLog = log => {
        return new Promise((resolve, reject) => {
            try {
                const newState = _.cloneDeep(this.state);

                newState.product = log.product;

                newState.architecturalInspection.value = log.input.architectural_inspection;
                newState.bankGuarantee.value = log.input.bank_guarantee;
                newState.valuation.value = log.input.evaluation;
                newState.forceNoNhg.value = log.input.force_no_nhg;
                newState.architecturalInspection.value = log.input.evaluation;

                newState.applicants.incomes = log.input.incomes.map(income => {
                    return {
                        value: income,
                        error: null,
                        conditions: { min: 5000, max: 1000000 }
                    };
                });

                newState.interestRateKey = log.input.interest_rate_key;
                newState.ltv.value = log.input.ltv;
                newState.mortgageAdvice.value = log.input.mortgage_advice;
                newState.mortgageAmount.value = log.input.mortgage_amount;
                newState.mortgageApplication.value = log.input.mortgage_application;
                newState.notaryDeedOfTransfer.value = log.input.notary_deed_of_transfer;
                newState.notaryMortgage.value = log.input.notary_mortgage;
                newState.purchasePriceHouse.value = log.input.purchase_price_house;
                newState.startDate.value = log.input.start_date;
                newState.transferTax.value = log.input.transfer_tax;
                newState.valueHouse.value = log.input.value_house;

                newState.parts.parts = [];
                log.input.parts.map(part => {
                    return newState.parts.parts.push({
                        duration: { value: part.duration },
                        interestFixedPeriod: { value: part.interest_fixed_period, requestValue: part.interest_fixed_period },
                        interestRate: { value: part.interest_rate },
                        mortgageAmount: {
                            value: part.mortgage_amount,
                            conditions: { min: 5000, max: 99999999 },
                            error: null
                        },
                        mortgageType: {
                            value: part.mortgage_type,
                            requestValue: part.mortgage_type
                        }
                    });
                });

                newState.maxMortgageAmountIncome = log.calculations.max_mortgage_from_income;
                newState.maxMortgageHouse.value = log.calculations.max_mortgage_from_house;
                newState.isNhgPossible.value = log.calculations.is_nhg_possible;
                newState.requiredSavings.value = log.calculations.required_savings;
                newState.nhgCosts.value = log.calculations.nhg_costs;

                newState.createdAt.value = log.created_at;
                newState.isCalculationMine.value = log.is_mine;

                this.setState(newState, () => {
                    this.updatePartsFromProduct().then(() => {
                        this.updateRateKeyAndLTV().then(() => {
                            resolve(log);
                        });
                    });
                });
            } catch (error) {
                reject(error);
            }
        });
    };

    requestMaxMortgageAmountFromIncome = () => {
        return new Promise((resolve, reject) => {
            try {
                const { applicants, parts, credit, hasCredit, studyLoan, hasStudyLoan } = this.state;

                const body = new FormData();

                const number_of_parts = applicants.incomes.length;

                body.append("number_of_applicants", number_of_parts);

                applicants.incomes.forEach((income, index) => {
                    body.append(`income_${index + 1}`, income.value);
                });

                body.append("parts", parts.toString());

                body.append("duration", parts.parts[0].duration.value);

                if (hasCredit.value) {
                    body.append("credit", credit.value);
                }

                if (hasStudyLoan.value) {
                    body.append("student_loan", studyLoan.value);

                    body.append("student_loan_date", studyLoan.date);
                }

                axios({
                    method: "post",
                    url: `${api.url}/calculator/mortgage/max`,
                    data: body,
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${this.props.auth.token}` }
                })
                    .then(response => {
                        const data = response.data.data;

                        const validation = this.validateMortgageAmount(this.state.mortgageAmount.value);

                        log("CALCULATION_MAX_MORTGAGE_FROM_INCOME", data.max_mortgage);

                        this.setState(
                            {
                                maxMortgageAmountIncome: data.max_mortgage,
                                mortgageAmount: { ...this.state.mortgageAmount, error: validation }
                            },
                            () => {
                                //validate the fields after the value from the calculations is resolved
                                this.validateMortgageAmountAndValueHouse();

                                resolve(data.max_mortgage);
                            }
                        );
                    })
                    .catch(error => {
                        reject(error.response);
                    });
            } catch (error) {
                reject(error);
            }
        });
    };

    requestNHGDefault = () => {
        return new Promise((resolve, reject) => {
            try {
                const { valueHouse, purchasePriceHouse, isNhgPossible, forceNoNhg } = this.state;

                const nhgForRequest = isNhgPossible.value ? !forceNoNhg.value : false;

                const formData = new FormData();

                formData.append("house_value", valueHouse.value);
                formData.append("house_purchase_value", purchasePriceHouse.value);
                formData.append("apply_nhg", nhgForRequest);

                formData.append("costs_notary_transfer", this.state.notaryDeedOfTransfer.value);
                formData.append("costs_bank_guarantee_minimum", this.state.bankGuarantee.value);
                formData.append("costs_architectural_inspection", this.state.architecturalInspection.value);
                formData.append("costs_notary_mortgage", this.state.notaryMortgage.value);
                formData.append("costs_evaluation", this.state.valuation.value);
                formData.append("costs_mortgage_advice", this.state.mortgageAdvice.value);
                formData.append("costs_mortgage_application", this.state.mortgageApplication.value);

                axios({
                    method: "post",
                    url: `${api.url}/calculator/mortgage/nhg`,
                    data: formData,
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${this.props.auth.token}` }
                })
                    .then(response => {
                        const data = response.data.data;

                        this.setState(
                            {
                                mortgageAmount: {
                                    ...this.state.mortgageAmount,
                                    value: data.mortgage_amount,
                                    error: this.validateMortgageAmount(data.mortgage_amount)
                                },
                                nhgCosts: { ...this.state.nhgCosts, value: data.nhg_costs },
                                isNhgPossible: { ...this.state.isNhgPossible, value: data.nhg_possible },
                                requiredMortgageAmount: { ...this.state.requiredMortgageAmount, value: data.required_amount },
                                requiredSavings: { ...this.state.requiredSavings, value: data.required_savings }
                            },
                            () => {
                                log("CALCULATION_NHG_DEFAULT", data);

                                this.updateRateKeyAndLTV().then(() => {
                                    this.updatePartsAmounts().then(() => {
                                        resolve(data);
                                    });
                                });
                            }
                        );
                    })
                    .catch(error => {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    };

    requestNHGWithAmount = () => {
        return new Promise((resolve, reject) => {
            try {
                const { mortgageAmount, valueHouse, purchasePriceHouse, isNhgPossible, forceNoNhg } = this.state;

                const nhgForRequest = isNhgPossible.value ? !forceNoNhg.value : false;

                const formData = new FormData();
                formData.append("house_value", valueHouse.value);
                formData.append("house_purchase_value", purchasePriceHouse.value);
                formData.append("apply_nhg", nhgForRequest);

                formData.append("mortgage_amount", mortgageAmount.value);

                formData.append("costs_notary_transfer", this.state.notaryDeedOfTransfer.value);
                formData.append("costs_bank_guarantee_minimum", this.state.bankGuarantee.value);
                formData.append("costs_architectural_inspection", this.state.architecturalInspection.value);
                formData.append("costs_notary_mortgage", this.state.notaryMortgage.value);
                formData.append("costs_evaluation", this.state.valuation.value);
                formData.append("costs_mortgage_advice", this.state.mortgageAdvice.value);
                formData.append("costs_mortgage_application", this.state.mortgageApplication.value);

                axios({
                    method: "post",
                    url: `${api.url}/calculator/mortgage/nhg/amount`,
                    data: formData,
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${this.props.auth.token}` }
                })
                    .then(response => {
                        const data = response.data.data;

                        this.setState(
                            {
                                mortgageAmount: {
                                    ...this.state.mortgageAmount,
                                    value: data.mortgage_amount,
                                    error: this.validateMortgageAmount(data.mortgage_amount)
                                },
                                nhgCosts: { ...this.state.nhgCosts, value: data.nhg_costs },
                                isNhgPossible: { ...this.state.isNhgPossible, value: data.nhg_possible },
                                requiredMortgageAmount: { ...this.state.requiredMortgageAmount, value: data.required_amount },
                                requiredSavings: { ...this.state.requiredSavings, value: data.required_savings }
                            },
                            () => {
                                log("CALCULATION_NHG_AMOUNT", data);

                                this.updateRateKeyAndLTV().then(() => {
                                    this.updatePartsAmounts().then(() => {
                                        resolve(data);
                                    });
                                });
                            }
                        );
                    })
                    .catch(error => {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    };

    requestNHGWithSavings = () => {
        return new Promise((resolve, reject) => {
            try {
                const { valueHouse, purchasePriceHouse, isNhgPossible, forceNoNhg } = this.state;

                const nhgForRequest = isNhgPossible.value ? !forceNoNhg.value : false;

                const formData = new FormData();
                formData.append("house_value", valueHouse.value);
                formData.append("house_purchase_value", purchasePriceHouse.value);
                formData.append("apply_nhg", nhgForRequest);

                formData.append("savings", this.state.requiredSavings.value);

                formData.append("costs_notary_transfer", this.state.notaryDeedOfTransfer.value);
                formData.append("costs_bank_guarantee_minimum", this.state.bankGuarantee.value);
                formData.append("costs_architectural_inspection", this.state.architecturalInspection.value);
                formData.append("costs_notary_mortgage", this.state.notaryMortgage.value);
                formData.append("costs_evaluation", this.state.valuation.value);
                formData.append("costs_mortgage_advice", this.state.mortgageAdvice.value);
                formData.append("costs_mortgage_application", this.state.mortgageApplication.value);

                axios({
                    method: "post",
                    url: `${api.url}/calculator/mortgage/nhg/savings`,
                    data: formData,
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${this.props.auth.token}` }
                })
                    .then(response => {
                        const data = response.data.data;

                        this.setState(
                            {
                                mortgageAmount: {
                                    ...this.state.mortgageAmount,
                                    value: data.mortgage_amount,
                                    error: this.validateMortgageAmount(data.mortgage_amount)
                                },
                                nhgCosts: { ...this.state.nhgCosts, value: data.nhg_costs },
                                isNhgPossible: { ...this.state.isNhgPossible, value: data.nhg_possible },
                                requiredMortgageAmount: { ...this.state.requiredMortgageAmount, value: data.required_amount },
                                requiredSavings: { ...this.state.requiredSavings, value: data.required_savings }
                            },
                            () => {
                                log("CALCULATION_NHG_SAVINGS");

                                this.updateRateKeyAndLTV().then(() => {
                                    this.updatePartsAmounts().then(() => {
                                        resolve(data);
                                    });
                                });
                            }
                        );
                    })
                    .catch(error => {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    };

    requestCalculation = () => {
        return new Promise((resolve, reject) => {
            try {
                const { valueHouse, mortgageAmount, isNhgPossible, forceNoNhg, parts, applicants, product, startDate } = this.state;

                const nhg = isNhgPossible.value ? !forceNoNhg.value : false;

                let queryParts = "[";

                parts.parts.forEach((part, index) => {
                    if (index !== 0) {
                        queryParts += ",";
                    }

                    let iterestRatesPart = "";

                    const interestRatesKeys = Object.keys(product.rates[index]);

                    interestRatesKeys.forEach(rateKey => {
                        //adding new line if there is already a rate
                        if (iterestRatesPart.length > 0) {
                            iterestRatesPart += "\n";
                        }
                        //adding only the rates
                        if ((rateKey.indexOf("nhg") > -1 || rateKey.indexOf("ltv") > -1) && product.rates[index][rateKey] !== null) {
                            iterestRatesPart += `${rateKey}: ${product.rates[index][rateKey]},`;
                        }
                    });

                    queryParts += `
                        {
                            mortgage_amount: ${part.mortgageAmount.value},
                            interest_fixed_period: "${part.interestFixedPeriod.value}",
                            duration: ${part.duration.value},
                            mortgage_type: ${part.mortgageType.value}, 
                            interest_rates:{
                                ${iterestRatesPart}
                            }
                        }
                    `;
                });

                queryParts += "]";

                const query = `query{
                    mortgage_calculation(
                        mortgage_amount:${mortgageAmount.value},
                        house_value:${valueHouse.value},
                        nhg: ${nhg},
                        income_1:${applicants.incomes[0].value},
                        ${applicants.incomes.length > 1 ? `income_2:${applicants.incomes[1].value},` : ""}
                        start_date: "${startDate.value}"
                        interest_adjustment_ltv_mortgage_during_ifp: ${product.interest_adjustment_ltv_mortgage_during_ifp},
                        interest_adjustment_ltv_mortgage_during_ifp_provider:${
                            product.interest_adjustment_ltv_mortgage_during_ifp_provider
                        },
                        interest_adjustment_ltv_house_during_ifp:${product.interest_adjustment_ltv_house_during_ifp}, 
                        parts:${queryParts}) {
                        totals{
                            ifp{
                                redemption
                                start_month
                                interest_mortgage_amount
                                gross_payment
                                net_payment
                                average_interest_rate
                            }
                            duration{
                                redemption
                                start_month
                                interest_mortgage_amount
                                gross_payment
                                net_payment
                                average_interest_rate
                            }
                        }
                        calculations{
                            year
                            average_gross_payment
                            average_net_payment
                            average_interest_rate
                            monthly_calculations{
                                mortgage_amount
                                weighted_interest_rate
                                interest_rate_per_part{
                                    part_1
                                    part_2
                                }
                            }
                        }
                    }
                }`;

                axios({
                    method: "post",
                    url: `${api.url}/calculators`,
                    withCredentials: false,
                    headers: {
                        Authorization: `Bearer ${this.props.auth.token}`
                    },
                    data: {
                        query: query
                    }
                })
                    .then(response => {
                        const calculations = response.data.data.mortgage_calculation;

                        log("CALCULATION_DATA", calculations);

                        const date = this.state.startDate.value.split("-");
                        let startDate = new Date(date[2], 0, 1);

                        //init the arrays in which the calcualtion will be stored
                        const grossPayments = [];
                        const netPayments = [];
                        const mortgageAmount = [];
                        const averageInterestRate = [];
                        let averageInterestRatesMonthlyTooltipValues = [];

                        //adding empty data to the months before today
                        for (let index = 1; index < parseInt(date[1]); index++) {
                            mortgageAmount.push({
                                name: moment(startDate).format("DD-MM-YYYY"),
                                value: 0,
                                values: [{ value: moment(startDate).format("DD-MM-YYYY") }, { value: "€ 0" }]
                            });

                            averageInterestRatesMonthlyTooltipValues.push({
                                values: [null, null, null],
                                label: moment(startDate).format("DD-MM")
                            });

                            startDate = this.addMonthToDate(startDate);
                        }

                        //cloning the totals object so that it can be mapped to the new values
                        const totals = _.cloneDeep(this.state.calculations.totals);

                        //adding the totals (element of first position is ifp)
                        Object.keys(calculations.totals).forEach(periodKey => {
                            totals[periodKey].gross = calculations.totals[periodKey].gross_payment;
                            totals[periodKey].net = calculations.totals[periodKey].net_payment;
                            totals[periodKey].avgRate = calculations.totals[periodKey].average_interest_rate * 100;
                            totals[periodKey].interestMortgageAmount = calculations.totals[periodKey].interest_mortgage_amount;
                        });

                        //adding the calculations to the arrays
                        calculations.calculations.forEach((yearlyCalculation, index) => {
                            //looping the monthly calcualtions
                            yearlyCalculation.monthly_calculations.forEach(monthlyCalcualtions => {
                                mortgageAmount.push({
                                    name: moment(startDate).format("DD-MM-YYYY"),
                                    value: Math.round(monthlyCalcualtions.mortgage_amount),
                                    values: [
                                        { value: moment(startDate).format("DD-MM-YYYY") },
                                        { value: `€ ${format(Math.round(monthlyCalcualtions.mortgage_amount), 0, 3, ".", ",")}` }
                                    ]
                                });

                                const partTwoValue =
                                    typeof monthlyCalcualtions.interest_rate_per_part.part_2 === "undefined"
                                        ? null
                                        : `${format(monthlyCalcualtions.interest_rate_per_part.part_2 * 100, 2, 3, ".", ",")}%`;

                                averageInterestRatesMonthlyTooltipValues.push({
                                    values: [
                                        `${format(monthlyCalcualtions.weighted_interest_rate * 100, 2, 3, ".", ",")}%`,
                                        `${format(monthlyCalcualtions.interest_rate_per_part.part_1 * 100, 2, 3, ".", ",")}%`,
                                        partTwoValue
                                    ],
                                    label: moment(startDate).format("DD-MM")
                                });
                                startDate = this.addMonthToDate(startDate);
                            });

                            //adding to the data arrays
                            grossPayments.push({
                                name: yearlyCalculation.year,
                                value: Math.round(yearlyCalculation.average_gross_payment)
                            });
                            netPayments.push({
                                name: yearlyCalculation.year,
                                value: Math.round(yearlyCalculation.average_net_payment)
                            });
                            averageInterestRate.push({
                                name: yearlyCalculation.year,
                                value: yearlyCalculation.average_interest_rate * 100,
                                yearlyValues: {
                                    label: yearlyCalculation.year,
                                    value: `${format(yearlyCalculation.average_interest_rate * 100, 2, 3, ".", ",")} %`
                                },
                                monthlyValues: averageInterestRatesMonthlyTooltipValues
                            });

                            //resetting the monthly array
                            averageInterestRatesMonthlyTooltipValues = [];
                        });

                        //if the end mnth is not 0 (January) loop to fill in everything until it goes to the end of the year
                        if (startDate.getMonth() !== 0) {
                            for (let index = startDate.getMonth() + 1; index <= 12; index++) {
                                mortgageAmount.push({
                                    name: moment(startDate).format("DD-MM-YYYY"),
                                    value: 0,
                                    values: [{ value: moment(startDate).format("DD-MM-YYYY") }, { value: `€ ${0}` }],
                                    label: "Mortgage amount at start of each month"
                                });

                                averageInterestRate[averageInterestRate.length - 1].monthlyValues.push({
                                    values: [null, null, null],
                                    label: moment(startDate).format("DD-MM")
                                });

                                startDate = this.addMonthToDate(startDate);
                            }
                        }

                        this.setState({
                            calculations: {
                                isLoading: false,
                                totals: totals,
                                mortgageAmountCalcualtions: mortgageAmount,
                                grossPaymentCalcualtions: grossPayments,
                                netPaymentCalcualtions: netPayments,
                                averageInterestRateCalcualtions: averageInterestRate
                            }
                        });

                        resolve(calculations);
                    })
                    .catch(error => {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    };

    setCreatedAt = newValue => {
        const { createdAt } = this.state;
        this.setState({ createdAt: { ...createdAt, value: newValue } });
    };

    setIsCalculationMine = newValue => {
        const { isCalculationMine } = this.state;
        this.setState({ isCalculationMine: { ...isCalculationMine, value: newValue } });
    };

    setMortgageAmount = (newValue, callback) => {
        const { mortgageAmount, valueHouse } = this.state;

        const validation = this.validateMortgageAmount(newValue);

        const validationHouse = this.validateMortgageAmount(valueHouse.value);

        this.setState(
            {
                mortgageAmount: { ...mortgageAmount, value: parseInt(newValue), error: validation },
                valueHouse: { ...valueHouse, error: validationHouse }
            },
            () => {
                this.debouncedNHGAmountUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setValueHouse = (newValue, callback, skipRequest) => {
        const { valueHouse, mortgageAmount } = this.state;

        const validationMortgage = this.validateMortgageAmount(mortgageAmount.value);

        const validation = this.validateValueHouse(newValue);

        this.setState(
            {
                mortgageAmount: { ...mortgageAmount, error: validationMortgage },
                valueHouse: { ...valueHouse, value: parseInt(newValue), error: validation },
                maxMortgageHouse: { value: newValue }
            },
            () => {
                if (skipRequest === undefined || skipRequest === false) {
                    this.debouncedNHGUpdate();
                }
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setRequiredSavings = (newValue, callback) => {
        const { requiredSavings } = this.state;

        const validation = this.validate(requiredSavings, newValue);

        this.setState({ requiredSavings: { ...requiredSavings, value: parseInt(newValue), error: validation } }, () => {
            this.debouncedNHGSavingsUpdate();
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setStartDate = (newDay, newMonth, newYear) => {
        return new Promise((resolve, reject) => {
            try {
                const { startDate } = this.state;

                this.setState({ startDate: { ...startDate, value: `${newDay}-${newMonth}-${newYear}` } }, () => {
                    resolve(this.state.startDate.value);
                });
            } catch (error) {
                reject(error);
            }
        });
    };

    setPurchasePriceHouse = (newValue, callback, skipRequest) => {
        const { purchasePriceHouse, bankGuarantee, transferTax, valueHouse, mortgageAmount } = this.state;

        const validationMortgage = this.validateMortgageAmount(mortgageAmount.value);

        const validationHouse = this.validateValueHouse(newValue);

        const validation = this.validate(purchasePriceHouse, newValue);

        const transferTaxValue = newValue * transferTax.percentage;

        const bankValue = Math.round(bankGuarantee.percentage * (newValue * 0.1));

        const bankGuaranteeValue = bankGuarantee.conditions.max < bankValue ? bankGuarantee.conditions.max : bankValue;

        this.setState(
            {
                mortgageAmount: { ...mortgageAmount, error: validationMortgage },
                valueHouse: { ...valueHouse, value: parseInt(newValue), error: validationHouse },
                purchasePriceHouse: { ...purchasePriceHouse, value: parseInt(newValue), error: validation },
                bankGuarantee: { ...bankGuarantee, value: bankGuaranteeValue },
                transferTax: { ...transferTax, value: transferTaxValue },
                maxMortgageHouse: { value: parseInt(newValue) }
            },
            () => {
                if (skipRequest === undefined || skipRequest === false) {
                    this.debouncedNHGUpdate();
                }
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setNotaryDeedOfTransfer = (newValue, callback) => {
        const { notaryDeedOfTransfer } = this.state;

        const validation = this.validate(notaryDeedOfTransfer, newValue);

        this.setState(
            {
                notaryDeedOfTransfer: { ...notaryDeedOfTransfer, value: parseInt(newValue), error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setForceNoNhg = (newValue, callback) => {
        const { forceNoNhg } = this.state;

        this.setState(
            {
                forceNoNhg: { ...forceNoNhg, value: newValue }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setBankGuarantee = (newValue, callback) => {
        const { bankGuarantee } = this.state;

        const validation = this.validate(bankGuarantee, newValue);

        this.setState(
            {
                bankGuarantee: { ...bankGuarantee, value: parseInt(newValue), error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setArchitecturalInspection = (newValue, callback) => {
        const { architecturalInspection } = this.state;

        const validation = this.validate(architecturalInspection, newValue);

        this.setState(
            {
                architecturalInspection: { ...architecturalInspection, value: parseInt(newValue), error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setNotaryMortgage = (newValue, callback) => {
        const { notaryMortgage } = this.state;

        const validation = this.validate(notaryMortgage, newValue);

        this.setState(
            {
                notaryMortgage: { ...notaryMortgage, value: parseInt(newValue), error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setValuation = (newValue, callback) => {
        const { valuation } = this.state;

        const validation = this.validate(valuation, newValue);

        this.setState(
            {
                valuation: { ...valuation, value: parseInt(newValue), error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setMortgageAdvice = (newValue, callback) => {
        const { mortgageAdvice } = this.state;

        const validation = this.validate(mortgageAdvice, newValue);

        this.setState(
            {
                mortgageAdvice: { ...mortgageAdvice, value: parseInt(newValue), error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setMortgageApplication = (newValue, callback) => {
        const { mortgageApplication } = this.state;

        const validation = this.validate(mortgageApplication, newValue);

        this.setState(
            {
                mortgageApplication: { ...mortgageApplication, value: parseInt(newValue), error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setCreditValue = (newValue, callback) => {
        const { credit } = this.state;

        const validation = this.validate(credit, newValue);

        this.setState(
            {
                credit: { ...credit, value: parseInt(newValue), error: validation }
            },
            () => {
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setStudyLoan = (newValue, callback) => {
        const { studyLoan } = this.state;

        const validation = this.validate(studyLoan, newValue);

        this.setState(
            {
                studyLoan: { ...studyLoan, value: parseInt(newValue), error: validation }
            },
            () => {
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setStudyLoanDate = (newValue, callback) => {
        const { studyLoan } = this.state;

        this.setState(
            {
                studyLoan: { ...studyLoan, date: newValue }
            },
            () => {
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    validateStudyLoanDate = () => {
        const { studyLoan } = this.state;

        const date = studyLoan.date.split("-");

        if (date.length === 3) {
            if (!isNaN(date[1])) {
                const newDate = new Date(date[2], parseInt(date[1]) - 1, date[0]);
                const formatedDate = moment(newDate).format("DD-MM-YYYY");
                return { date: formatedDate, error: null };
            } else {
                return { date: null, error: "Invalid date" };
            }
        } else {
            return { date: null, error: "Invalid date" };
        }
    };

    setHasCreditValue = (newValue, callback) => {
        const { hasCredit } = this.state;

        this.setState(
            {
                hasCredit: { ...hasCredit, value: newValue }
            },
            () => {
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setHasStudyLoan = (newValue, callback) => {
        const { hasStudyLoan } = this.state;

        this.setState(
            {
                hasStudyLoan: { ...hasStudyLoan, value: newValue }
            },
            () => {
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    addPart = callback => {
        const { parts } = this.state;

        const newParts = _.cloneDeep(parts);

        const newPart = _.cloneDeep(newParts.parts[0]);

        newPart.mortgageAmount.value = 5000;

        newParts.parts.push(newPart);

        newParts.parts[0].mortgageAmount.value = newParts.parts[0].mortgageAmount.value - 5000;

        this.setState({ parts: newParts }, () => {
            this.updateWeightedAvgInterestRate();
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    removePart = callback => {
        const { parts, mortgageAmount } = this.state;

        const newParts = _.cloneDeep(parts.parts);

        newParts.pop();

        newParts[0].mortgageAmount.value = mortgageAmount.value;

        this.setState({ parts: { ...parts, parts: newParts } }, () => {
            this.updateWeightedAvgInterestRate();
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartMortgageAmount = (index, value, callback) => {
        const { mortgageAmount, parts } = this.state;

        const newParts = _.cloneDeep(parts.parts);

        const part = newParts[index];

        const validation = this.validate(part.mortgageAmount, value);

        part.mortgageAmount.value = parseInt(value);
        part.mortgageAmount.error = validation;

        let leftOutMortgageAmount = mortgageAmount.value - value;

        for (let partIndex = 0; partIndex < newParts.length; partIndex++) {
            const tempPart = newParts[partIndex];
            if (partIndex !== index && leftOutMortgageAmount > 0) {
                const valueToAssign = tempPart.mortgageAmount.value - leftOutMortgageAmount;

                const tempValidation = this.validate(tempPart.mortgageAmount, value);
                tempPart.mortgageAmount.value -= valueToAssign;
                tempPart.mortgageAmount.error = tempValidation;
                leftOutMortgageAmount -= valueToAssign;
            }
        }

        this.setState({ parts: { ...parts, parts: newParts } }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartMortgageType = (value, index, callback) => {
        const { parts } = this.state;

        const newParts = parts;

        const currentPart = newParts.parts[index];

        const newPart = { ...currentPart, mortgageType: { ...currentPart.mortgageType, value: value, requestValue: value } };

        newParts.parts[index] = newPart;

        this.setState({ parts: newParts }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartMortgageTypeRequest = (value, index, callback) => {
        const { parts } = this.state;

        const newParts = parts;

        const currentPart = newParts.parts[index];

        const newPart = { ...currentPart, mortgageType: { ...currentPart.mortgageType, requestValue: value } };

        newParts.parts[index] = newPart;

        this.setState({ parts: newParts }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartDuration = (value, index, callback) => {
        const { parts } = this.state;

        const newParts = parts;

        const currentPart = newParts.parts[index];

        const newPart = { ...currentPart, duration: { ...currentPart.duration, value: value } };

        newParts.parts[index] = newPart;

        this.setState({ parts: newParts }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartInterestFixedPeriod = (value, index, callback) => {
        const { parts } = this.state;

        const newParts = parts;

        const currentPart = newParts.parts[index];

        const newPart = { ...currentPart, interestFixedPeriod: { ...currentPart.interestFixedPeriod, value: value, requestValue: value } };

        newParts.parts[index] = newPart;

        this.setState({ parts: newParts }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartInterestFixedPeriodRequest = (value, index, callback) => {
        const { parts } = this.state;

        const newParts = parts;

        const currentPart = newParts.parts[index];

        const newPart = { ...currentPart, interestFixedPeriod: { ...currentPart.interestFixedPeriod, requestValue: value } };

        newParts.parts[index] = newPart;

        this.setState({ parts: newParts }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartInterestRate = (value, index, callback) => {
        const { parts } = this.state;

        const newParts = parts;

        const currentPart = newParts.parts[index];

        const newPart = { ...currentPart, interestRate: { ...currentPart.interestRate, value: value } };

        newParts.parts[index] = newPart;

        this.setState({ parts: newParts }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    updatePartsFromProduct = () => {
        return new Promise((resolve, reject) => {
            try {
                const { parts, product, interestRateKey } = this.state;

                const newParts = _.cloneDeep(parts.parts);

                let isRateMissing = false;

                product.rates.forEach((rateArray, index) => {
                    //setting the new rate for the part
                    const newRate = rateArray[interestRateKey];

                    //checking if the rate is mising, if it is rise an error
                    if (newRate === null) {
                        isRateMissing = true;
                    }

                    newParts[index].interestRate.value = newRate;
                    //setting hte mortgage type
                    newParts[index].mortgageType.value = rateArray.type;
                    //setting the ifp
                    newParts[index].interestFixedPeriod.value = rateArray.period;

                    newParts[index].interestFixedPeriod.availableOptions = product.available_periods[0].available_periods;

                    newParts[index].mortgageType.availableOptions = product.available_types;
                });
                log("MORTGAGE_PARTS_UPDATE_FROM_PRODUCT", newParts);

                resolve(newParts);

                this.setState({ parts: { ...parts, parts: newParts }, rateError: isRateMissing }, this.updateWeightedAvgInterestRate);
            } catch (error) {
                reject(error);
            }
        });
    };

    updatePartsAmounts = () => {
        return new Promise((resolve, reject) => {
            try {
                const { parts, mortgageAmount } = this.state;

                const newParts = _.cloneDeep(parts);

                const partOne = newParts.parts[0];

                const mortgageAmountValue = parseInt(mortgageAmount.value);

                if (newParts.parts.length > 1) {
                    const partTwo = newParts.parts[1];

                    const total = parseInt(partOne.mortgageAmount.value) + parseInt(partTwo.mortgageAmount.value);

                    //if mortgage amount is more
                    if (total < mortgageAmountValue) {
                        const difference = mortgageAmountValue - total;

                        this.setPartMortgageAmount(1, parseInt(partTwo.mortgageAmount.value) + difference);
                    } else if (total > mortgageAmountValue) {
                        const difference = total - mortgageAmountValue;
                        //remove part 2 as the new amount is more
                        if (difference + partTwo.mortgageAmount.conditions.min > partTwo.mortgageAmount.value) {
                            this.removePart(() => {
                                this.updateRateKeyAndLTV();
                            });
                        } else {
                            this.setPartMortgageAmount(1, parseInt(partTwo.mortgageAmount.value) - difference);
                        }
                    }
                } else {
                    this.setPartMortgageAmount(0, mortgageAmount.value);
                }
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    updateWeightedAvgInterestRate = callback => {
        const { parts, mortgageAmount, interestRate } = this.state;

        let weighted = 0;

        if (parts.parts.length > 1) {
            parts.parts.forEach(parts => {
                weighted += parts.mortgageAmount.value * parts.interestRate.value;
            });

            weighted /= mortgageAmount.value;
        } else {
            weighted = parts.parts[0].interestRate.value;
        }

        this.setState({ interestRate: { ...interestRate, value: weighted } }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    updateRateKeyAndLTV = () => {
        return new Promise((resolve, reject) => {
            try {
                const { mortgageAmount, valueHouse, isNhgPossible, forceNoNhg } = this.state;

                const nhg = isNhgPossible.value ? !forceNoNhg.value : false;

                const newLTV = calculateLTV(mortgageAmount.value, valueHouse.value);

                const newRateKey = getRateKey(nhg, newLTV);

                this.setState({ interestRateKey: newRateKey, ltv: { ...newLTV, value: newLTV } }, () => {
                    this.updatePartsFromProduct(() => {
                        this.requestMaxMortgageAmountFromIncome();
                    });
                });

                log("UPDATE_RATE_KEY_AND_LTV", { key: newRateKey, ltv: newLTV });

                resolve({ key: newRateKey, ltv: newLTV });
            } catch (error) {
                reject(error);
            }
        });
    };

    addApplicant = callback => {
        const { applicants } = this.state;

        const incomesArray = applicants.incomes;

        incomesArray.push({
            value: 5000,
            conditions: { min: 5000, max: 1000000, equal: 0 },
            errors: { min: "min", max: "max" }
        });

        this.setState({ applicants: { ...applicants, incomes: incomesArray } }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    removeApplicant = callback => {
        const { applicants } = this.state;

        const incomesArray = applicants.incomes;

        incomesArray.pop();

        this.setState({ applicants: { ...applicants, incomes: incomesArray } }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setApplicantAmount = (index, newValue, callback) => {
        const { applicants } = this.state;

        const incomesArray = _.cloneDeep(applicants.incomes);

        const validation = this.validate(incomesArray[index], newValue);

        incomesArray[index].value = parseInt(newValue);
        incomesArray[index].error = validation;

        this.setState({ applicants: { ...applicants, incomes: incomesArray } }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    validate = (input, value) => {
        const formatedValue = value !== "" ? parseInt(value) : value;

        if (!("conditions" in input) || formatedValue === input.conditions.equal) {
            return null;
        }
        if (formatedValue < input.conditions.min) {
            return "min";
        }
        if (formatedValue > input.conditions.max) {
            return "max";
        }
        return null;
    };

    validateMortgageAmount = newValue => {
        const { mortgageAmount, maxMortgageAmountIncome, valueHouse } = this.state;
        if (parseInt(newValue) > parseInt(maxMortgageAmountIncome)) {
            return "moreThanMaxMortgageIncome";
        }
        if (parseInt(newValue) > parseInt(valueHouse.value)) {
            return "moreThanMaxMortgageHouse";
        }
        return this.validate(mortgageAmount, newValue);
    };

    validateValueHouse = newValue => {
        const { mortgageAmount, valueHouse } = this.state;
        if (parseInt(newValue) < parseInt(mortgageAmount.value)) {
            return "lessThanMortgageAmount";
        }
        return this.validate(valueHouse, newValue);
    };

    validateMortgageAmountAndValueHouse = () => {
        return new Promise((resolve, reject) => {
            try {
                const { valueHouse, mortgageAmount } = this.state;

                const validationMortgage = this.validateMortgageAmount(mortgageAmount.value);

                const validationHouse = this.validateValueHouse(valueHouse.value);

                this.setState(
                    {
                        mortgageAmount: { ...mortgageAmount, error: validationMortgage },
                        valueHouse: { ...valueHouse, error: validationHouse }
                    },
                    () => {
                        resolve();
                    }
                );
            } catch (error) {
                reject(error);
            }
        });
    };

    convertPartsToString = () => {
        const { parts } = this.state;

        const partsJSON = [];

        parts.parts.forEach(part => {
            partsJSON.push({
                mortgage_amount: parseInt(part.mortgageAmount.value),
                interest_fixed_period:
                    part.interestFixedPeriod.value === "variabel"
                        ? part.interestFixedPeriod.value
                        : parseInt(part.interestFixedPeriod.value),
                mortgage_type: part.mortgageType.value,
                duration: parseInt(part.duration.value),
                interest_rate: parseFloat(part.interestRate.value)
            });
        });

        return JSON.stringify(partsJSON);
    };

    addMonthToDate = date => {
        const currentMonth = date.getMonth();
        if (currentMonth < 12) {
            date.setMonth(date.getMonth() + 1);
        } else {
            date.setMonth(0);
            date.setYear(date.getFullYear() + 1);
        }
        return date;
    };

    /**
     * everything wrapped in this context will have access to the state and func
     */
    render() {
        const { children } = this.props;

        const state = this.state;

        return <CalculatorContext.Provider value={state}>{children}</CalculatorContext.Provider>;
    }
}
