import {
    AUTH_VERIFY_EMAIL,
    AUTH_SIGN_OUT,
    AUTH_SIGN_IN_DEFAULT,
    AUTH_SIGN_UP_DEFAULT,
    AUTH_VERIFY_TOKEN,
    AUTH_CHANGE_ROLE,
} from "../Actions/authActions/types";

const initialState = {
    user: null,
    token: null,
    isAuthStateChanged: false,
    isUserAuthenticated: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AUTH_VERIFY_EMAIL:
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,
                isUserAuthenticated: action.payload !== null,
            };
        case AUTH_VERIFY_TOKEN:
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,
                isAuthStateChanged: true,
                isUserAuthenticated: action.payload !== null,
            };
        case AUTH_SIGN_UP_DEFAULT:
            return {
                ...state,
                user: action.payload,
            };
        case AUTH_SIGN_OUT:
            return {
                ...state,
                user: action.payload,
                isUserAuthenticated: false,
            };
        case AUTH_SIGN_IN_DEFAULT:
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,
                isUserAuthenticated: action.payload !== null,
            };
        case AUTH_CHANGE_ROLE:
            return {
                ...state,
                token: action.payload.token,
                user: { ...state.user, role: action.payload.role },
            };
        default:
            return state;
    }
}
