import { connect } from "react-redux";
import { Table, TableCell, TableRow, TableBody } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { mapStateToProps } from "../../redux";

import "../../Views/History/HistoryStyles.css";

function MobileHistoryRowTableTable(props) {
    const thousandSeparator = number => {
        const parts = number.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
    };

    const { log, lang, auth } = props;

    const numberOfParts = log.input.parts.length;

    return (
        <Fragment>
            <div className="table-header">
                {log.product.description === "Example mortgage" ? lang.dashboard.input_product_default_value : log.product.description}
            </div>

            <Table className="details-table">
                <TableBody>
                    <TableRow className="details-row">
                        <TableCell className="details-cell">{lang.history.column_house_header}</TableCell>

                        <TableCell className="details-cell">
                            {log.input.purchase_price_house !== null ? `€ ${thousandSeparator(log.input.purchase_price_house)}` : "-"}
                        </TableCell>
                    </TableRow>
                    {log.input.parts.map((part, index) => {
                        return (
                            <Fragment key={index}>
                                <TableRow className="details-row">
                                    <TableCell className="details-cell">
                                        {lang.history[`column_part_${numberOfParts === 1 ? "" : `${index + 1}_`}amount_header`]}
                                    </TableCell>
                                    <TableCell className="details-cell">{`€ ${thousandSeparator(part.mortgage_amount)}`}</TableCell>
                                </TableRow>

                                <TableRow className="details-row">
                                    <TableCell className="details-cell">
                                        {lang.history[`column_part_${numberOfParts === 1 ? "" : `${index + 1}_`}type_header`]}
                                    </TableCell>
                                    <TableCell className="details-cell">
                                        {part.mortgage_type === "annuity"
                                            ? lang.dashboard.input_mortgage_type_part_value_annuity
                                            : lang.dashboard.input_mortgage_type_part_value_linear}
                                    </TableCell>
                                </TableRow>

                                <TableRow className="details-row">
                                    <TableCell className="details-cell">
                                        {
                                            lang.history[
                                                `column_part_${numberOfParts === 1 ? "" : `${index + 1}_`}interest_fixed_period_header`
                                            ]
                                        }
                                    </TableCell>
                                    <TableCell className="details-cell">{part.interest_fixed_period}</TableCell>
                                </TableRow>

                                <TableRow className="details-row">
                                    <TableCell className="details-cell">{lang.history.column_creator_header}</TableCell>
                                    <TableCell className="details-cell">
                                        {log.is_mine
                                            ? lang.history.column_creator_option_me
                                            : auth.user.role === "advisor"
                                            ? lang.history.column_creator_option_customer_advisor_account
                                            : lang.history.column_creator_option_advisor_customer_account}
                                    </TableCell>
                                </TableRow>
                            </Fragment>
                        );
                    })}
                </TableBody>
            </Table>
        </Fragment>
    );
}

MobileHistoryRowTableTable.propTypes = {
    log: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(MobileHistoryRowTableTable);
