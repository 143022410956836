import React, { useEffect, Fragment } from "react";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";

import { Paper, Table, TableBody, TableCell, TableRow, TableHead, CircularProgress } from "@material-ui/core";

import Template from "../../Components/Template/Template";
import HistoryRow from "../../Components/HistoryRow/HistoryRow";
import MobileHistoryRow from "./../../Components/HistoryRow/MobileHistoryRow";
import ChartContainer from "../../Components/ChartContainer/ChartContainer";

import { getComparisonLog, getComparisonCalculations, getCompariosnNet } from "../../Actions/comparisonActions/actions";

function ComparisonDetails(props) {
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        if (role.selectedCustomer !== null || auth.user.role !== "advisor") {
            compareLogs();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const compareLogs = async () => {
        const { getComparisonLog, getComparisonCalculations, getCompariosnNet } = props;

        const link = props.location.search;
        const arr = link.split(/-/g).slice(1);
        const [one, two] = await Promise.all([
            getComparisonLog(parseInt(arr[0]), true).then(data => {
                if (data !== null) {
                    return getComparisonCalculations(true);
                }
            }),
            getComparisonLog(parseInt(arr[1]), false).then(data => {
                if (data !== null) {
                    return getComparisonCalculations(false);
                }
            })
        ]);

        if (one === undefined || two === undefined) {
            history.push("/vergelijken");
        } else {
            getCompariosnNet();
            setLoading(false);
        }
    };

    const { comparison, lang, css, history, auth, role } = props;

    return (
        <div>
            <Template
                title="Customers"
                shouldIncludeSideBar={true}
                contentFullHeight={true}
                history={history}
                shouldIncludeSecoundTopBar={true}
            >
                <div style={{ padding: "10px" }}>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                        {role.selectedCustomer === null && auth.user.role === "advisor" ? (
                            history.push("/vergelijken")
                        ) : loading ? (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <Fragment>
                                <Paper style={{ width: "100%" }}>
                                    {css.isMobile ? (
                                        // Mobile version
                                        <div className={"table-mobile"}>
                                            <div className={"header-row"}>
                                                <Paper className={"paper-header-cell"}>
                                                    <div className={"header-cell"} style={{ width: "25%" }}>
                                                        Date
                                                    </div>
                                                    <div className={"header-cell"} style={{ width: "15%" }}>
                                                        {lang.history.column_time_header}
                                                    </div>
                                                    <div className={"header-cell"} style={{ width: "15%" }}>
                                                        {lang.history.column_check_header}
                                                    </div>
                                                    <div className={"header-cell"} style={{ width: "30%" }}>
                                                        {lang.history.column_mortgage_amount_header}
                                                    </div>
                                                </Paper>
                                            </div>
                                            <div className={"row-body"}>
                                                <MobileHistoryRow
                                                    key={comparison.firstComparisonLog.id}
                                                    log={comparison.firstComparisonLog}
                                                ></MobileHistoryRow>
                                                <MobileHistoryRow
                                                    key={comparison.secondComparisoLog.id}
                                                    log={comparison.secondComparisoLog}
                                                ></MobileHistoryRow>
                                            </div>
                                        </div>
                                    ) : (
                                        // Desktop version
                                        <Table className="history-table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={"small-item"}></TableCell>
                                                    <TableCell className={"small-item"}>Date</TableCell>
                                                    <TableCell className={"smaller-item"}>{lang.history.column_time_header}</TableCell>
                                                    <TableCell className={"smaller-item"}>{lang.history.column_check_header}</TableCell>
                                                    <TableCell className={"small-item"} component="th" align="left">
                                                        {lang.history.column_house_header}
                                                    </TableCell>
                                                    <TableCell className={"small-item"} component="th" align="left">
                                                        {lang.history.column_mortgage_amount_header}
                                                    </TableCell>
                                                    <TableCell className={"smaller-item"} component="th" align="left">
                                                        {lang.history.column_parts_header}
                                                    </TableCell>
                                                    <TableCell className={"small-item"} component="th" align="left">
                                                        {lang.history.column_part_1_amount_header}
                                                    </TableCell>
                                                    <TableCell className={"smaller-item"} component="th" align="left">
                                                        {lang.history.column_part_1_type_header}
                                                    </TableCell>
                                                    <TableCell className={"smaller-item"} component="th" align="left">
                                                        {lang.history.column_part_1_interest_fixed_period_header}
                                                    </TableCell>
                                                    <TableCell className={"small-item"} component="th" align="left">
                                                        {lang.history.column_part_2_amount_header}
                                                    </TableCell>
                                                    <TableCell className={"smaller-item"} component="th" align="left">
                                                        {lang.history.column_part_2_type_header}
                                                    </TableCell>
                                                    <TableCell className={"smaller-item"} component="th" align="left">
                                                        {lang.history.column_part_2_interest_fixed_period_header}
                                                    </TableCell>
                                                    <TableCell className={"small-item"} component="th" align="left">
                                                        Owner
                                                    </TableCell>
                                                    <TableCell className={"large-item"} component="th" align="left">
                                                        {lang.history.column_product_header}
                                                    </TableCell>
                                                    <TableCell className={"smaller-item"} component="th" align="left" />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className={"table-body"}>
                                                <HistoryRow
                                                    key={comparison.firstComparisonLog.id}
                                                    log={comparison.firstComparisonLog}
                                                    logIndex={1}
                                                    displayedOn={"ComparisonDetails"}
                                                />
                                                <HistoryRow
                                                    key={comparison.secondComparisoLog.id}
                                                    log={comparison.secondComparisoLog}
                                                    logIndex={2}
                                                    displayedOn={"ComparisonDetails"}
                                                />
                                            </TableBody>
                                        </Table>
                                    )}
                                </Paper>
                                <ChartContainer
                                    chartData={comparison.comparisonNet}
                                    chartHeight={150}
                                    chartTooltip={lang.dashboard.graph_net_payments_help}
                                    chartTotalTitle={lang.dashboard.graph_net_payments_title}
                                    chartTotalTooltip={lang.dashboard.total_net_help}
                                    chartType={"BarChart2"}
                                    isChartDataLoading={comparison.comparisonNet !== null ? false : true}
                                    totals={null}
                                    totalsComparisonData={comparison.totalComparisonNet}
                                    totalsHeader={lang.dashboard.total_net_title}
                                />
                                <ChartContainer
                                    chartData={comparison.comparisonGross}
                                    chartHeight={150}
                                    chartTooltip={lang.dashboard.graph_gross_payments_help}
                                    chartTotalTitle={lang.dashboard.graph_gross_payments_title}
                                    chartTotalTooltip={lang.dashboard.total_gross_help}
                                    chartType={"BarChart2"}
                                    isChartDataLoading={comparison.comparisonGross !== null ? false : true}
                                    totals={null}
                                    totalsComparisonData={comparison.totalComparisonGross}
                                    totalsHeader={lang.dashboard.total_gross_title}
                                />
                                <ChartContainer
                                    chartData={comparison.comparisonInterestRate}
                                    chartHeight={150}
                                    chartTooltip={lang.dashboard.graph_interest_rate_help}
                                    chartTotalTitle={lang.dashboard.graph_interest_rate_title}
                                    chartTotalTooltip={lang.dashboard.average_interest_help}
                                    chartType={"BarChart2"}
                                    isChartDataLoading={comparison.comparisonInterestRate !== null ? false : true}
                                    totals={null}
                                    totalsComparisonData={comparison.totalComparisonInterestRate}
                                    totalsHeader={lang.dashboard.average_interest_title}
                                />
                                <ChartContainer
                                    chartData={comparison.comparisonMortgageAmount}
                                    chartHeight={150}
                                    chartTooltip={lang.dashboard.graph_mortgage_amount_help}
                                    chartTotalTitle={lang.dashboard.graph_mortgage_amount_title}
                                    chartType={"LineChart2"}
                                    isChartDataLoading={comparison.comparisonInterestRate !== null ? false : true}
                                />
                            </Fragment>
                        )}
                    </div>
                </div>
            </Template>
        </div>
    );
}

export default connect(mapStateToProps, { getComparisonLog, getComparisonCalculations, getCompariosnNet })(ComparisonDetails);
