import { connect } from "react-redux";
import { Typography, Card, CardContent } from "@material-ui/core";
import * as axios from "axios";
import React, { useEffect } from "react";

import { authVerifyEmail } from "../../Actions/authActions/actions";
import { mapStateToProps } from "../../redux";
import { setDirectlyLanguage } from "../../Actions/languageAction/actions";
import logo from "../../Images/logo_hyponav_beta.svg";

axios.defaults.withCredentials = true;

function VerifyEmail(props) {
    const [didRespond, setDidRespond] = React.useState(false);
    const extractToken = token => {
        return token.replace("?token=", "");
    };

    useEffect(() => {
        props
            .authVerifyEmail(extractToken(props.location.search))
            .then(res => {
                props.setDirectlyLanguage(res.user.language);
            })
            .catch(error => {
                console.log(error);
                if (error.code === "token/invalid") {
                    setDidRespond(true);
                }
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return didRespond ? (
        <div className={"dom"}>
            <img src={logo} alt="Logo" style={{ width: "300px", margin: "40px 0" }} />
            <Card className={"card"}>
                <CardContent className={"card-content"}>
                    <Typography variant={"h2"} style={{ margin: "20px 0" }}>
                        Invalid Token
                    </Typography>
                    <Typography style={{ margin: "20px 0" }}>
                        The verification token has expired or it is invalid. Please make a new registration.
                    </Typography>
                </CardContent>
            </Card>
        </div>
    ) : (
        <div />
    );
}

export default connect(mapStateToProps, { authVerifyEmail, setDirectlyLanguage })(VerifyEmail);
