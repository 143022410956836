import {
    GET_FIRST_COMPARISON_LOG,
    GET_SECOND_COMPARISON_LOG,
    GET_FIRST_COMPARISON_CALCULATIONS,
    GET_SECOND_COMPARISON_CALCULATIONS,
    GET_COMPARISON_NET
} from "../Actions/comparisonActions/types";
import { log } from "../redux";

const initialState = {
    firstComparisonLog: null,
    secondComparisoLog: null,

    firstComparisonCalculations: null,
    secondComparisonCalculations: null,

    comparisonNet: null,
    totalComparisonNet: null,

    comparisonGross: null,
    totalComparisonGross: null,

    comparisonInterestRate: null,
    totalComparisonInterestRate: null,

    comparisonMortgageAmount: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_FIRST_COMPARISON_LOG:
            log(GET_FIRST_COMPARISON_LOG, action);
            return {
                ...state,
                firstComparisonLog: action.payload
            };
        case GET_SECOND_COMPARISON_LOG:
            log(GET_SECOND_COMPARISON_LOG, action);
            return {
                ...state,
                secondComparisoLog: action.payload
            };
        case GET_FIRST_COMPARISON_CALCULATIONS:
            log(GET_FIRST_COMPARISON_CALCULATIONS, action);
            return {
                ...state,
                firstComparisonCalculations: action.payload
            };
        case GET_SECOND_COMPARISON_CALCULATIONS:
            log(GET_SECOND_COMPARISON_CALCULATIONS, action);
            return {
                ...state,
                secondComparisonCalculations: action.payload
            };
        case GET_COMPARISON_NET:
            log(GET_COMPARISON_NET, action);
            return {
                ...state,
                comparisonNet: action.payload.comparisonNet,
                totalComparisonNet: action.payload.totalComparisonNet,
                comparisonGross: action.payload.comparisonGross,
                totalComparisonGross: action.payload.totalComparisonGross,
                comparisonInterestRate: action.payload.comparisonInterestRate,
                totalComparisonInterestRate: action.payload.totalComparisonInterestRate,
                comparisonMortgageAmount: action.payload.comparisonMortgageAmount
            };
        default:
            return state;
    }
}
