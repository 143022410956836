import types from "./types";

const initialState = {
    sideMenu: {
        open: false,
    },
    dialogs: {
        tooltip: {
            open: false,
            text: "",
        },
    },
    isMobile: window.innerWidth <= 767,
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case types.OPEN_DIALOG:
            return {
                ...state,
                dialogs: {
                    ...state.dialogs,
                    [payload.name]: {
                        ...state.dialogs[payload.name],
                        open: true,
                        ...payload.data,
                    },
                },
            };
        case types.CLOSE_DIALOG:
            return {
                ...state,
                dialogs: {
                    ...state.dialogs,
                    [payload]: {
                        ...state.dialogs[payload],
                        open: false,
                    },
                },
            };
        case types.OPEN_SIDE_MENU:
            return {
                ...state,
                sideMenu: {
                    ...state.sideMenu,
                    open: true,
                },
            };
        case types.CLOSE_SIDE_MENU:
            return {
                ...state,
                sideMenu: {
                    ...state.sideMenu,
                    open: false,
                },
            };
        case types.UPDATE_IS_MOBILE:
            return {
                ...state,
                isMobile: payload,
            };
        case types.UI_REST:
            return initialState;
        default:
            return state;
    }
}
