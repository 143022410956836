import moment from "moment";

import {
    LOG_CREATE,
    LOG_GET,
    LOG_GET_LAST_LOG,
    LOG_DELETE,
    LOG_DELETE_LOCAL,
    LOG_UNLOAD,
    LOG_LOAD_LOGS,
    LOG_RESET,
    LOG_LOAD,
    LOG_UNLOAD_CURRENT,
    CHECK_IF_FIRST_TIME_USER,
    COMPARISON_CREATE,
    LOG_LOAD_COMPARISONS,
    COMPARISON_DELETE,
    COMPARISON_DELETE_LOCAL,
    COMPARISONS_RESET,
} from "../Actions/logActions/types";

const initialState = {
    //logs array
    logs: [],

    //offset for the request to the logs
    offset: 0,

    //identifier to show if the logs were loaded from the backend
    areLogsLoaded: false,

    //comparisons array
    comparisons: [],

    //offset for the request to the comparisons
    offsetComparisons: 0,

    //identifier to show if the comparisons were loaded from the backend
    areComparisonsLoaded: false,

    //stores the current loaded log that will be only displayed in the calculator
    currentLog: null,

    //stores the loaded log with which the user will be able to work
    loadedLog: null,

    //stores the log from the last time the user used the application
    lastLog: null,

    hasLoadedAllLogs: false,

    hasLoadedAllComparisons: false,

    isFirstTime: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOG_CREATE:
            const newLogs = [...state.logs];

            const newLog = action.payload;
            newLog["is_mine"] = true;
            if (state.areLogsLoaded) {
                const date = moment(newLog.created_at, "x").toDate();

                let formatedDateFromLog = moment(newLog.created_at, "x").format("dddd DD MMM YYYY");

                if (newLogs.length > 0 && formatedDateFromLog === newLogs[0].formatedDate) {
                    newLogs[0]["logs"].unshift(newLog);
                } else {
                    newLogs.unshift({ formatedDate: formatedDateFromLog, date: date, logs: [newLog] });
                }
            }
            return {
                ...state,
                logs: newLogs,
            };
        case LOG_DELETE:
            return state;
        case COMPARISON_DELETE:
            return state;
        case LOG_DELETE_LOCAL:
            return {
                ...state,
                logs: action.payload,
            };
        case COMPARISON_DELETE_LOCAL:
            return {
                ...state,
                comparisons: action.payload,
            };
        case LOG_UNLOAD:
            return {
                ...state,
                currentLog: action.payload,
                loadedLog: action.payload,
            };
        case LOG_UNLOAD_CURRENT:
            return {
                ...state,
                currentLog: action.payload,
            };
        case LOG_GET:
            return {
                ...state,
                currentLog: action.payload,
            };
        case LOG_LOAD:
            return {
                ...state,
                loadedLog: action.payload,
            };
        case LOG_GET_LAST_LOG:
            return {
                ...state,
                lastLog: action.payload,
            };
        case LOG_LOAD_LOGS:
            return {
                ...state,
                ...action.payload,
            };
        case LOG_LOAD_COMPARISONS:
            return {
                ...state,
                ...action.payload,
            };
        case CHECK_IF_FIRST_TIME_USER:
            return {
                ...state,
                isFirstTime: action.payload,
            };
        case COMPARISON_CREATE:
            return state;
        case LOG_RESET:
            return {
                comparisons: [],
                areComparisonsLoaded: false,
                offsetComparisons: 0,
                logs: [],
                offset: 0,
                areLogsLoaded: false,
                currentLog: null,
                loadedLog: null,
                lastLog: null,
                hasLoadedAllLogs: false,
                hasLoadedAllComparisons: false,
            };
        case COMPARISONS_RESET:
            return {
                ...state,
                comparisons: [],
                areComparisonsLoaded: false,
                offsetComparisons: 0,
                hasLoadedAllComparisons: false,
            };
        default:
            return state;
    }
}
