import React from "react";
import { withTheme } from "@material-ui/core/styles";
import { Tooltip, Radio, FormControlLabel, RadioGroup } from "@material-ui/core";
import { Help } from "@material-ui/icons";

function CustomRadio(props) {
    return (
        <div className={"dialog-input"} style={props.radioStyle === "incomeCal" ? { marginBottom: 20 } : { margin: 0 }}>
            <div className={"input-label"}>
                <label>{props.label}</label>
            </div>
            <div className={"input-help-wrapper"}>
                <div className={"radio-field"}>
                    <RadioGroup
                        aria-label={props.name}
                        name={props.name}
                        style={props.column !== null ? { flexDirection: props.column ? "column" : "row" } : {}}
                        value={props.value.toString()}
                        onChange={props.onChange}
                    >
                        {props.inputs.map((input, index) => {
                            return (
                                <FormControlLabel
                                    key={index}
                                    value={input.value.toString()}
                                    control={<Radio style={props.radioStyle === "incomeCal" ? { padding: 4 } : null} />}
                                    label={input.label}
                                />
                            );
                        })}
                    </RadioGroup>
                </div>
                <div className={"input-help"}>
                    <Tooltip title={props.tooltipText} disableFocusListener disableTouchListener>
                        <Help style={{ color: props.theme.palette.secondary.main }} />
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}

CustomRadio.defaultProps = {
    tooltipText: "help"
};

export default withTheme(CustomRadio);
