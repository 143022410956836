import React from "react";
import { mapStateToProps } from "../../redux";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Checkbox, Collapse, IconButton } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";

import CustomerInformation from "./CustomerInformation";

function ConsumerPanelRow(props) {
    const [expanded, setExpanded] = React.useState(false);
    const {
        first_name,
        prefix,
        last_name,
        email,
        onClick,
        isSelected,
        customerIndex,
        state,
        uuid,
        classes,
        are_logs_shared,
        are_comparisons_shared,
        customerUuid,
        onSharedChange,
        language,
        css
    } = props;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div className={isSelected ? "customer-panel-body-row-whole-selected" : "customer-panel-body-row-whole"}>
            {css.isMobile ? (
                //mobile version
                <div className={"customer-panel-body-row"}>
                    <div className={"customer-panel-body-cell-mobile"} padding="checkbox">
                        <Checkbox
                            color={"secondary"}
                            checked={isSelected}
                            onClick={event => {
                                onClick(
                                    customerIndex,
                                    email,
                                    uuid,
                                    first_name,
                                    prefix,
                                    last_name,
                                    are_logs_shared,
                                    are_comparisons_shared,
                                    state
                                );
                            }}
                        />
                    </div>
                    <div className={"customer-panel-body-cell-mobile-big"}>{first_name}</div>
                    <div className={"customer-panel-body-cell-mobile-big"}>{last_name}</div>
                    <div className={"customer-panel-body-cell-mobile"}>
                        <IconButton
                            className={classNames(classes.expand, {
                                [classes.expandOpen]: expanded
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </div>
                </div>
            ) : (
                //desktop version
                <div className={"customer-panel-body-row"}>
                    <div className={"customer-panel-body-cell"} padding="checkbox">
                        <Checkbox
                            color={"secondary"}
                            checked={isSelected}
                            onClick={event => {
                                onClick(
                                    customerIndex,
                                    email,
                                    uuid,
                                    first_name,
                                    prefix,
                                    last_name,
                                    are_logs_shared,
                                    are_comparisons_shared,
                                    state
                                );
                            }}
                        />
                    </div>
                    <div className={"customer-panel-body-cell-small"}>{first_name}</div>
                    <div className={"customer-panel-body-cell-small"}>{last_name}</div>
                    <div className={"customer-panel-body-cell"}>{email}</div>
                    <div className={"customer-panel-body-cell-small"}>{state}</div>
                    <div className={"customer-panel-body-cell"}>{are_logs_shared === true ? "Yes" : "No"}</div>
                    <div className={"customer-panel-body-cell-small"}>{language}</div>
                    <div className={"customer-panel-body-cell"}>
                        <IconButton
                            className={classNames(classes.expand, {
                                [classes.expandOpen]: expanded
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </div>
                </div>
            )}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CustomerInformation
                    are_logs_shared={are_logs_shared}
                    are_comparisons_shared={are_comparisons_shared}
                    email={email}
                    state={state}
                    customerUuid={customerUuid}
                    language={language}
                    onSharedChange={onSharedChange}
                />
            </Collapse>
        </div>
    );
}

const styles = theme => ({
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    }
});

export default connect(mapStateToProps)(withStyles(styles)(ConsumerPanelRow));
