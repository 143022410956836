import React from "react";
import clsx from "clsx";
import { Warning } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => {
    return {
        alert: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 10,
            marginBottom: 10,
            fontWeight: 500,
            fontSize: 14,
        },
        warning: {
            backgroundColor: "#fff3cd",
            color: "#856404",
        },
        danger: {
            backgroundColor: "#fce8e6",
            color: "#d50000",
        },
        icon: {
            marginRight: 10,
        },
        dangerIcon: {
            color: "#ff5252",
        },
    };
});

const Alert = (props) => {
    const { type, children, style, className } = props;

    const classes = useStyles();

    return (
        <div
            className={clsx(
                classes.alert,
                {
                    [classes.danger]: type === "danger",
                    [classes.warning]: type === "warning",
                },
                className,
            )}
            style={style}
        >
            <Warning
                className={clsx(classes.icon, {
                    [classes.dangerIcon]: type === "danger",
                })}
            />
            <Typography>{children}</Typography>
        </div>
    );
};

Alert.defaultProps = {
    type: "warning",
    className: "",
    style: {},
};

Alert.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    warning: PropTypes.oneOf(["danger", "warning"]),
};

export default Alert;
