import { Help } from "@material-ui/icons";
import { Tooltip, Typography } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import React from "react";

import "./InputFieldStyles.css";

function Input(props) {
    return (
        <div className={"dialog-input"}>
            <div className={"input-label"}>
                <label htmlFor={props.id}>{props.label}</label>
            </div>
            <div className={"input-help-wrapper"}>
                <div className={"input-field"}>
                    <input
                        className={props.error || props.errorMessage ? "error" : ""}
                        id={props.id}
                        type={props.type}
                        value={props.value}
                        onChange={props.onChange}
                        placeholder={props.placeholder}
                        readOnly={props.readOnly}
                        onBlur={() => {
                            if (props.onBlur !== null) {
                                
                                props.onBlur();
                            }
                        }}
                        ref={props.inputRef}
                    />
                    {props.errorMessage !== null ? <Typography className={"input-error-desktop"}>{props.errorMessage}</Typography> : undefined}
                    {props.error !== null ? <Typography className={"input-error-desktop"}>{props.errors[props.error]}</Typography> : undefined}
                </div>
                <div className={"input-help"}>
                    <Tooltip title={props.tooltipText} disableFocusListener disableTouchListener>
                        <Help style={{ color: props.theme.palette.secondary.main }} />
                    </Tooltip>
                </div>
            </div>
            {props.error !== null ? <Typography className={"input-error-mobile"}>{props.errors[props.error]}</Typography> : undefined}
        </div>
    );
}

Input.defaultProps = {
    error: null,
    readOnly: false,
    inputRef: null,
    tooltipText: "help",
    placeholder: null,
    onBlur: null
};

export default withTheme(Input);
