import React, { Component } from "react";
import Template from "../../Components/Template/Template";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";

class Advisor extends Component {
    constructor(props) {
        super(props);

        const { lang } = props;

        document.title = lang.advisor.title_tag;

        this.state = {};
    }

    render() {
        const { history, lang } = this.props;

        const data = [
            { label: lang.advisor.advisor_organisation_label, value: "Arvera" },
            { label: lang.advisor.advisor_owner_label, value: "Frank Rutten" },
            { label: lang.advisor.advisor_adress_label, value: "Torenallee 20  5617 BC Eindhoven" },
            { label: lang.advisor.advisor_telephone_label, value: "040-2800793" },
            { label: lang.advisor.advisor_whatsapp_label, value: "06-22693212" },
            { label: lang.advisor.advisor_email_label, value: "info@arvera.nl" },
            { label: lang.advisor.advisor_chamber_of_commerce_label, value: "17096051" },
            { label: lang.advisor.advisor_permit_afm_label, value: "12011129" },
            { label: lang.advisor.advisor_number_kifid_label, value: "300.007.409" },
        ];
        return (
            <Template
                title={lang.advisor.title_tag}
                shouldIncludeSideBar={true}
                contentFullHeight={true}
                history={history}
                shouldIncludeSecoundTopBar={true}
            >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexDirection: "column" }}>
                    {data.map((item, key) => {
                        return (
                            <div key={key} style={{ display: "flex", flexDirection: "row", width: "60%" }}>
                                <div style={{ width: "50%" }}>{item.label}</div>
                                <div style={{ width: "50%" }}>{item.value}</div>
                            </div>
                        );
                    })}
                </div>
            </Template>
        );
    }
}
export default connect(mapStateToProps)(Advisor);
