import { mortgageRateFragment } from "../fragments/logFragments";

export const lastMortgageLogQuery = `
query getLastLog($shared_uuid: String){
    last_mortgage_log(shared_uuid: $shared_uuid) {
        id
        input {
            mortgage_amount
            value_house
            purchase_price_house
            parts {
                mortgage_amount
                mortgage_type
                duration
                interest_fixed_period
                interest_rate
            }
            incomes
            start_date
            force_no_nhg
            transfer_tax
            notary_deed_of_transfer
            bank_guarantee
            architectural_inspection
            notary_mortgage
            evaluation
            mortgage_advice
            mortgage_application
            interest_rate_key
            ltv
        }
        product {
            code
            description
            explanation
            interest_adjustment_ltv_house_during_ifp
            interest_adjustment_ltv_mortgage_during_ifp
            interest_adjustment_ltv_mortgage_during_ifp_provider
            available_periods {
                type
                available_periods
            }
            available_types
            provider {
                name
                logo
            }
            rates {
                ...mortgageRateFragment
            }
        }
        calculations{
            max_mortgage_from_income
            max_mortgage_from_house
            is_nhg_possible
            required_savings
            nhg_costs
        }
        created_at
        is_mine
    }
}

${mortgageRateFragment}
`;

export const getMortgageLogQuery = `
query getLog($id: Int, $shared_uuid: String){
    mortgage_log(id: $id, shared_uuid: $shared_uuid) {
        id
        input {
            mortgage_amount
            value_house
            purchase_price_house
            parts {
                mortgage_amount
                mortgage_type
                duration
                interest_fixed_period
                interest_rate
            }
            incomes
            start_date
            force_no_nhg
            transfer_tax
            notary_deed_of_transfer
            bank_guarantee
            architectural_inspection
            notary_mortgage
            evaluation
            mortgage_advice
            mortgage_application
            interest_rate_key
            ltv
        }
        product {
            code
            description
            explanation
            interest_adjustment_ltv_house_during_ifp
            interest_adjustment_ltv_mortgage_during_ifp
            interest_adjustment_ltv_mortgage_during_ifp_provider
            available_periods {
                type
                available_periods
            }
            available_types
            provider {
                name
                logo
            }
            rates {
                ...mortgageRateFragment
            }
        }
        calculations{
            max_mortgage_from_income
            max_mortgage_from_house
            is_nhg_possible
            required_savings
            nhg_costs
        }
        created_at
        is_mine
    }
}

${mortgageRateFragment}`;

export const getLogsQuery = `
query getMortgageLogs($offset: Int, $limit: Int, $shared_uuid: String){
    mortgage_logs(offset: $offset, limit: $limit, shared_uuid: $shared_uuid) {
        id
        input {
            mortgage_amount
            value_house
            purchase_price_house
            parts {
                mortgage_amount
                mortgage_type
                duration
                interest_fixed_period
                interest_rate
            }
            incomes
        }
        product {
            code
            description
            provider {
                name
                logo
            }
        }
        calculations{
            max_mortgage_from_income
        }
        created_at
        is_mine
    }
}`;

export const getComparisonsQuery = `
query getMortgageComparisons($offset: Int, $limit: Int, $shared_uuid: String){
    mortgage_comparisons(offset: $offset, limit: $limit, shared_uuid: $shared_uuid)
    {
        id
        first_comparison {
            id
            input {
                mortgage_amount
                value_house
                purchase_price_house
                parts {
                    mortgage_amount
                    mortgage_type
                    duration
                    interest_fixed_period
                    interest_rate
                }
                incomes
            }
            product {
                code
                description
                provider {
                    name
                    logo
                }
            }
            calculations{
                max_mortgage_from_income
            }
            created_at
            is_mine
            }
        second_comparison {
            id
            input {
                mortgage_amount
                value_house
                purchase_price_house
                parts {
                    mortgage_amount
                    mortgage_type
                    duration
                    interest_fixed_period
                    interest_rate
                }
                incomes
            }
            product {
                code
                description
                provider {
                    name
                    logo
                }
            }
            calculations{
                max_mortgage_from_income
            }
            created_at
            is_mine
            }
            created_at
        }
    }`;
