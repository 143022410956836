import { getLog as getLogRequest, requestCalculation } from "../logActions/requests";

import { format } from "../../helperFunctions";

import {
    GET_FIRST_COMPARISON_LOG,
    GET_SECOND_COMPARISON_LOG,
    GET_FIRST_COMPARISON_CALCULATIONS,
    GET_SECOND_COMPARISON_CALCULATIONS,
    GET_COMPARISON_NET
} from "./types";

/**
 * Gets the log from for the id param
 * * The function returns a Promise
 * * * then: returns { log<Object> }
 * * * catch: return error info
 * @param {Integer} id id of the log
 * @param {Boolean} isItFirst used to check if this is the first log that needs to be stored
 */

export const getComparisonLog = (id, isItFirst) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { auth, role } = getState();
        let shared_uuid;
        if (auth.user.role === "basic" || auth.user.role === "premium") {
            shared_uuid = role.selectedAdvisor === null ? null : role.selectedAdvisor.uuid;
        } else if (auth.user.role === "advisor") {
            shared_uuid = role.selectedCustomer.customerUuid;
        }
        getLogRequest(id, shared_uuid, auth.token)
            .then(response => {
                const log = response;
                if (isItFirst) {
                    dispatch({
                        type: GET_FIRST_COMPARISON_LOG,
                        payload: log
                    });
                } else {
                    dispatch({
                        type: GET_SECOND_COMPARISON_LOG,
                        payload: log
                    });
                }

                resolve(log);
            })
            .catch(error => {
                reject(error);

                console.error("GET_COMPARISON_LOG::FAIL", error);
            });
    });
};

const getMortgageAmountComparison = (
    firstYearlyCalculation,
    firstIndex,
    secondYearlyCalculation,
    secondIndex,
    comparisonMortgageAmount
) => {
    //check if the Mortgage Amount of only the second calculations should be taken
    if (firstYearlyCalculation === undefined) {
        //check if it is the first year of the second calculations
        if (secondIndex !== 0) {
            secondYearlyCalculation.monthly_calculations.forEach((calculation, index) => {
                comparisonMortgageAmount.push({
                    name: `1-${index + 1}-${secondYearlyCalculation.year}`,
                    value2: Math.round(calculation.mortgage_amount),
                    valueForLabel2: format(Math.round(calculation.mortgage_amount), 0, 3, ".")
                });
            });
        } else {
            //if it is the first year, calculate the starting month
            let month = 13 - secondYearlyCalculation.monthly_calculations.length;

            if (month !== 1) {
                for (let index = 1; index < month; index++) {
                    comparisonMortgageAmount.push({
                        name: `1-${index}-${secondYearlyCalculation.year}`,
                        value1: "-",
                        value2: "-"
                    });
                }
            }

            secondYearlyCalculation.monthly_calculations.forEach((calculation, index) => {
                comparisonMortgageAmount.push({
                    name: `1-${month}-${secondYearlyCalculation.year}`,
                    value2: Math.round(calculation.mortgage_amount),
                    valueForLabel2: format(Math.round(calculation.mortgage_amount), 0, 3, ".")
                });
                month++;
            });
        }
        //check if the Mortgage Amount of only the first comparison should be taken
    } else if (secondYearlyCalculation === undefined) {
        //check if it is the first year of the first calculations
        if (firstIndex !== 0) {
            firstYearlyCalculation.monthly_calculations.forEach((calculation, index) => {
                comparisonMortgageAmount.push({
                    name: `1-${index + 1}-${firstYearlyCalculation.year}`,
                    value1: Math.round(calculation.mortgage_amount),
                    valueForLabel1: format(Math.round(calculation.mortgage_amount), 0, 3, ".")
                });
            });
        } else {
            //if it is the first year calculate the starting month
            let month = 13 - firstYearlyCalculation.monthly_calculations.length;

            if (month !== 1) {
                for (let index = 1; index < month; index++) {
                    comparisonMortgageAmount.push({
                        name: `1-${index}-${firstYearlyCalculation.year}`,
                        value1: "-",
                        value2: "-"
                    });
                }
            }

            firstYearlyCalculation.monthly_calculations.forEach((calculation, index) => {
                comparisonMortgageAmount.push({
                    name: `1-${month}-${firstYearlyCalculation.year}`,
                    value1: Math.round(calculation.mortgage_amount),
                    valueForLabel1: format(Math.round(calculation.mortgage_amount), 0, 3, ".")
                });
                month++;
            });
        }
        // if the Mortgage Amount for both of the comparisons should be taken
    } else {
        //if it is not the first year of any of the calculations
        if (secondIndex !== 0 && firstIndex !== 0) {
            if (firstYearlyCalculation.monthly_calculations.length === secondYearlyCalculation.monthly_calculations.length) {
                firstYearlyCalculation.monthly_calculations.forEach((calculation, index) => {
                    comparisonMortgageAmount.push({
                        name: `1-${index + 1}-${firstYearlyCalculation.year}`,
                        value1: Math.round(calculation.mortgage_amount),
                        valueForLabel1: format(Math.round(calculation.mortgage_amount), 0, 3, "."),
                        value2: Math.round(secondYearlyCalculation.monthly_calculations[index].mortgage_amount),
                        valueForLabel2: format(Math.round(secondYearlyCalculation.monthly_calculations[index].mortgage_amount), 0, 3, ".")
                    });
                });
            } else if (firstYearlyCalculation.monthly_calculations.length > secondYearlyCalculation.monthly_calculations.length) {
                firstYearlyCalculation.monthly_calculations.forEach((calculation, index) => {
                    if (secondYearlyCalculation.monthly_calculations[index] !== undefined) {
                        comparisonMortgageAmount.push({
                            name: `1-${index + 1}-${firstYearlyCalculation.year}`,
                            value1: Math.round(calculation.mortgage_amount),
                            valueForLabel1: format(Math.round(calculation.mortgage_amount), 0, 3, "."),
                            value2: Math.round(secondYearlyCalculation.monthly_calculations[index].mortgage_amount),
                            valueForLabel2: format(
                                Math.round(secondYearlyCalculation.monthly_calculations[index].mortgage_amount),
                                0,
                                3,
                                "."
                            )
                        });
                    } else {
                        comparisonMortgageAmount.push({
                            name: `1-${index + 1}-${firstYearlyCalculation.year}`,
                            value1: Math.round(calculation.mortgage_amount),
                            valueForLabel1: format(Math.round(calculation.mortgage_amount), 0, 3, ".")
                        });
                    }
                });
            } else if (firstYearlyCalculation.monthly_calculations.length < secondYearlyCalculation.monthly_calculations.length) {
                secondYearlyCalculation.monthly_calculations.forEach((calculation, index) => {
                    if (firstYearlyCalculation.monthly_calculations[index] !== undefined) {
                        comparisonMortgageAmount.push({
                            name: `1-${index + 1}-${firstYearlyCalculation.year}`,
                            value1: Math.round(firstYearlyCalculation.monthly_calculations[index].mortgage_amount),
                            valueForLabel1: format(
                                Math.round(firstYearlyCalculation.monthly_calculations[index].mortgage_amount),
                                0,
                                3,
                                "."
                            ),
                            value2: Math.round(calculation.mortgage_amount),
                            valueForLabel2: format(Math.round(calculation.mortgage_amount), 0, 3, ".")
                        });
                    } else {
                        comparisonMortgageAmount.push({
                            name: `1-${index + 1}-${firstYearlyCalculation.year}`,
                            value2: Math.round(calculation.mortgage_amount),
                            valueForLabel2: format(Math.round(calculation.mortgage_amount), 0, 3, ".")
                        });
                    }
                });
            }
            //if it is the first year of the first calculation
        } else if (firstIndex === 0 && secondIndex !== 0) {
            let monthsOfFirst = 13 - firstYearlyCalculation.monthly_calculations.length;
            let currentMonth = 1;
            let simulatedIndex = 0;
            secondYearlyCalculation.monthly_calculations.forEach((calculation, index) => {
                if (currentMonth === monthsOfFirst) {
                    comparisonMortgageAmount.push({
                        name: `1-${index + 1}-${secondYearlyCalculation.year}`,
                        value1: Math.round(firstYearlyCalculation.monthly_calculations[simulatedIndex].mortgage_amount),
                        valueForLabel1: format(
                            Math.round(firstYearlyCalculation.monthly_calculations[simulatedIndex].mortgage_amount),
                            0,
                            3,
                            "."
                        ),
                        value2: Math.round(calculation.mortgage_amount),
                        valueForLabel2: format(Math.round(calculation.mortgage_amount), 0, 3, ".")
                    });
                    simulatedIndex++;
                } else {
                    comparisonMortgageAmount.push({
                        name: `1-${index + 1}-${secondYearlyCalculation.year}`,
                        value2: Math.round(calculation.mortgage_amount),
                        valueForLabel2: format(Math.round(calculation.mortgage_amount), 0, 3, ".")
                    });
                    currentMonth++;
                }
            });
            //if it is the first year of the second calculation
        } else if (firstIndex !== 0 && secondIndex === 0) {
            let monthsOfSecond = 13 - secondYearlyCalculation.monthly_calculations.length;
            let currentMonth = 1;
            let simulatedIndex = 0;
            firstYearlyCalculation.monthly_calculations.forEach((calculation, index) => {
                if (currentMonth === monthsOfSecond) {
                    comparisonMortgageAmount.push({
                        name: `1-${index + 1}-${firstYearlyCalculation.year}`,
                        value1: Math.round(calculation.mortgage_amount),
                        valueForLabel1: format(Math.round(calculation.mortgage_amount), 0, 3, "."),
                        value2: Math.round(secondYearlyCalculation.monthly_calculations[simulatedIndex].mortgage_amount),
                        valueForLabel2: format(
                            Math.round(secondYearlyCalculation.monthly_calculations[simulatedIndex].mortgage_amount),
                            0,
                            3,
                            "."
                        )
                    });
                    simulatedIndex++;
                } else {
                    comparisonMortgageAmount.push({
                        name: `1-${index + 1}-${firstYearlyCalculation.year}`,
                        value1: Math.round(calculation.mortgage_amount),
                        valueForLabel1: format(Math.round(calculation.mortgage_amount), 0, 3, ".")
                    });
                    currentMonth++;
                }
            });
            //if it is the first year of both of the calculations
        } else {
            if (firstYearlyCalculation.monthly_calculations.length === secondYearlyCalculation.monthly_calculations.length) {
                let month = 13 - firstYearlyCalculation.monthly_calculations.length;

                if (month !== 1) {
                    for (let index = 1; index < month; index++) {
                        comparisonMortgageAmount.push({
                            name: `1-${index}-${firstYearlyCalculation.year}`,
                            value1: "-",
                            value2: "-"
                        });
                    }
                }

                firstYearlyCalculation.monthly_calculations.forEach((calculation, index) => {
                    comparisonMortgageAmount.push({
                        name: `1-${month}-${firstYearlyCalculation.year}`,
                        value1: Math.round(calculation.mortgage_amount),
                        valueForLabel1: format(Math.round(calculation.mortgage_amount), 0, 3, "."),
                        value2: Math.round(secondYearlyCalculation.monthly_calculations[index].mortgage_amount),
                        valueForLabel2: format(Math.round(secondYearlyCalculation.monthly_calculations[index].mortgage_amount), 0, 3, ".")
                    });
                    month++;
                });
            } else if (firstYearlyCalculation.monthly_calculations.length > secondYearlyCalculation.monthly_calculations.length) {
                let monthsOfFirst = 13 - firstYearlyCalculation.monthly_calculations.length;
                let monthsOfSecond = 13 - secondYearlyCalculation.monthly_calculations.length;
                let simulatedIndex = 0;

                if (monthsOfFirst !== 1) {
                    for (let index = 1; index < monthsOfFirst; index++) {
                        comparisonMortgageAmount.push({
                            name: `1-${index}-${firstYearlyCalculation.year}`,
                            value1: "-",
                            value2: "-"
                        });
                    }
                }

                firstYearlyCalculation.monthly_calculations.forEach((calculation, index) => {
                    if (monthsOfFirst === monthsOfSecond) {
                        comparisonMortgageAmount.push({
                            name: `1-${monthsOfFirst}-${firstYearlyCalculation.year}`,
                            value1: Math.round(calculation.mortgage_amount),
                            valueForLabel1: format(Math.round(calculation.mortgage_amount), 0, 3, "."),
                            value2: Math.round(secondYearlyCalculation.monthly_calculations[simulatedIndex].mortgage_amount),
                            valueForLabel2: format(
                                Math.round(secondYearlyCalculation.monthly_calculations[simulatedIndex].mortgage_amount),
                                0,
                                3,
                                "."
                            )
                        });
                        monthsOfFirst++;
                        monthsOfSecond++;
                        simulatedIndex++;
                    } else {
                        comparisonMortgageAmount.push({
                            name: `1-${monthsOfFirst}-${firstYearlyCalculation.year}`,
                            value1: Math.round(calculation.mortgage_amount),
                            valueForLabel1: format(Math.round(calculation.mortgage_amount), 0, 3, ".")
                        });
                        monthsOfFirst++;
                    }
                });
            } else if (firstYearlyCalculation.monthly_calculations.length < secondYearlyCalculation.monthly_calculations.length) {
                let monthsOfFirst = 13 - firstYearlyCalculation.monthly_calculations.length;
                let monthsOfSecond = 13 - secondYearlyCalculation.monthly_calculations.length;
                let simulatedIndex = 0;

                if (monthsOfSecond !== 1) {
                    for (let index = 1; index < monthsOfSecond; index++) {
                        comparisonMortgageAmount.push({
                            name: `1-${index}-${secondYearlyCalculation.year}`,
                            value1: "-",
                            value2: "-"
                        });
                    }
                }

                secondYearlyCalculation.monthly_calculations.forEach((calculation, index) => {
                    if (monthsOfFirst === monthsOfSecond) {
                        comparisonMortgageAmount.push({
                            name: `1-${monthsOfSecond}-${firstYearlyCalculation.year}`,
                            value1: Math.round(firstYearlyCalculation.monthly_calculations[simulatedIndex].mortgage_amount),
                            valueForLabel1: format(
                                Math.round(firstYearlyCalculation.monthly_calculations[simulatedIndex].mortgage_amount),
                                0,
                                3,
                                "."
                            ),
                            value2: Math.round(calculation.mortgage_amount),
                            valueForLabel2: format(Math.round(calculation.mortgage_amount), 0, 3, ".")
                        });
                        monthsOfFirst++;
                        monthsOfSecond++;
                        simulatedIndex++;
                    } else {
                        comparisonMortgageAmount.push({
                            name: `1-${monthsOfSecond}-${firstYearlyCalculation.year}`,
                            value2: Math.round(calculation.mortgage_amount),
                            valueForLabel2: format(Math.round(calculation.mortgage_amount), 0, 3, ".")
                        });
                        monthsOfSecond++;
                    }
                });
            }
        }
    }

    return comparisonMortgageAmount;
};

export const getCompariosnNet = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        try {
            let comparisonNet = [];
            let comparisonGross = [];
            let comparisonInterestRate = [];
            let comparisonMortgageAmount = [];

            if (
                getState().comparison.firstComparisonCalculations.calculations[0].year <=
                getState().comparison.secondComparisonCalculations.calculations[0].year
            ) {
                let lastIndexOfFirstForEach = false;
                getState().comparison.firstComparisonCalculations.calculations.forEach((firstYearlyCalculation, firstIndex, firstArray) => {
                    let isSecondValueAssigned = false;
                    getState().comparison.secondComparisonCalculations.calculations.forEach((secondYearlyCalculation, secondIndex) => {
                        //Enters in this if only if the first foreach is on the last year of firstComparisonCalculations.calculations array,
                        //but there are more years left in the secondComparisonCalculations.calculations array
                        if (lastIndexOfFirstForEach) {
                            comparisonNet.push({
                                name: secondYearlyCalculation.year,
                                value2: Math.round(secondYearlyCalculation.average_net_payment),
                                valueForLabel2: format(Math.round(secondYearlyCalculation.average_net_payment), 0, 3, ".")
                            });

                            comparisonGross.push({
                                name: secondYearlyCalculation.year,
                                value2: Math.round(secondYearlyCalculation.average_gross_payment),
                                valueForLabel2: format(Math.round(secondYearlyCalculation.average_gross_payment), 0, 3, ".")
                            });

                            comparisonInterestRate.push({
                                name: secondYearlyCalculation.year,
                                value2: format(secondYearlyCalculation.average_interest_rate * 100, 2),
                                valueForLabel2: format(secondYearlyCalculation.average_interest_rate * 100, 2, 3, ".", ",")
                            });

                            comparisonMortgageAmount = getMortgageAmountComparison(
                                undefined,
                                undefined,
                                secondYearlyCalculation,
                                secondIndex,
                                comparisonMortgageAmount
                            );
                        }
                        //When the year of first calculation = the year of second calculation
                        else if (firstYearlyCalculation.year === secondYearlyCalculation.year) {
                            comparisonNet.push({
                                name: firstYearlyCalculation.year,
                                value1: Math.round(firstYearlyCalculation.average_net_payment),
                                valueForLabel1: format(Math.round(firstYearlyCalculation.average_net_payment), 0, 3, "."),
                                value2: Math.round(secondYearlyCalculation.average_net_payment),
                                valueForLabel2: format(Math.round(secondYearlyCalculation.average_net_payment), 0, 3, ".")
                            });

                            comparisonGross.push({
                                name: firstYearlyCalculation.year,
                                value1: Math.round(firstYearlyCalculation.average_gross_payment),
                                valueForLabel1: format(Math.round(firstYearlyCalculation.average_gross_payment), 0, 3, "."),
                                value2: Math.round(secondYearlyCalculation.average_gross_payment),
                                valueForLabel2: format(Math.round(secondYearlyCalculation.average_gross_payment), 0, 3, ".")
                            });

                            comparisonInterestRate.push({
                                name: firstYearlyCalculation.year,
                                value1: format(firstYearlyCalculation.average_interest_rate * 100, 2),
                                valueForLabel1: format(firstYearlyCalculation.average_interest_rate * 100, 2, 3, ".", ","),
                                value2: format(secondYearlyCalculation.average_interest_rate * 100, 2),
                                valueForLabel2: format(secondYearlyCalculation.average_interest_rate * 100, 2, 3, ".", ",")
                            });

                            comparisonMortgageAmount = getMortgageAmountComparison(
                                firstYearlyCalculation,
                                firstIndex,
                                secondYearlyCalculation,
                                secondIndex,
                                comparisonMortgageAmount
                            );

                            isSecondValueAssigned = true;
                            //checks if it is the last index of first calculation
                            if (firstIndex === firstArray.length - 1) {
                                lastIndexOfFirstForEach = true;
                            }
                        }
                    });
                    //It enters in the if bellow only if a matching year wasn't found in the secondComparisonCalculations.calculations array
                    if (!isSecondValueAssigned) {
                        comparisonNet.push({
                            name: firstYearlyCalculation.year,
                            value1: Math.round(firstYearlyCalculation.average_net_payment),
                            valueForLabel1: format(Math.round(firstYearlyCalculation.average_net_payment), 0, 3, ".")
                        });

                        comparisonGross.push({
                            name: firstYearlyCalculation.year,
                            value1: Math.round(firstYearlyCalculation.average_gross_payment),
                            valueForLabel1: format(Math.round(firstYearlyCalculation.average_gross_payment), 0, 3, ".")
                        });

                        comparisonInterestRate.push({
                            name: firstYearlyCalculation.year,
                            value1: format(firstYearlyCalculation.average_interest_rate * 100, 2),
                            valueForLabel1: format(firstYearlyCalculation.average_interest_rate * 100, 2, 3, ".", ",")
                        });

                        comparisonMortgageAmount = getMortgageAmountComparison(
                            firstYearlyCalculation,
                            firstIndex,
                            undefined,
                            undefined,
                            comparisonMortgageAmount
                        );
                    }
                });
            } else {
                let lastIndexOfSecondForEach = false;
                getState().comparison.secondComparisonCalculations.calculations.forEach(
                    (secondYearlyCalculation, secondIndex, secondArray) => {
                        let isFirstValueAssigned = false;
                        getState().comparison.firstComparisonCalculations.calculations.forEach((firstYearlyCalculation, firstIndex) => {
                            //Enters in this if only if the second foreach is on the last year of secondComparisonCalculations.calculations array,
                            //but there are more years left in the firstComparisonCalculations.calculations array
                            if (lastIndexOfSecondForEach) {
                                comparisonNet.push({
                                    name: firstYearlyCalculation.year,
                                    value1: Math.round(firstYearlyCalculation.average_net_payment),
                                    valueForLabel1: format(Math.round(firstYearlyCalculation.average_net_payment), 0, 3, ".")
                                });

                                comparisonGross.push({
                                    name: firstYearlyCalculation.year,
                                    value1: Math.round(firstYearlyCalculation.average_gross_payment),
                                    valueForLabel1: format(Math.round(firstYearlyCalculation.average_gross_payment), 0, 3, ".")
                                });

                                comparisonInterestRate.push({
                                    name: firstYearlyCalculation.year,
                                    value1: format(firstYearlyCalculation.average_interest_rate * 100, 2),
                                    valueForLabel1: format(firstYearlyCalculation.average_interest_rate * 100, 2, 3, ".", ",")
                                });

                                comparisonMortgageAmount = getMortgageAmountComparison(
                                    firstYearlyCalculation,
                                    firstIndex,
                                    undefined,
                                    undefined,
                                    comparisonMortgageAmount
                                );
                            }
                            //When the year of first calculation = the year of second calculation
                            else if (firstYearlyCalculation.year === secondYearlyCalculation.year) {
                                comparisonNet.push({
                                    name: secondYearlyCalculation.year,
                                    value2: Math.round(secondYearlyCalculation.average_net_payment),
                                    valueForLabel2: format(Math.round(secondYearlyCalculation.average_net_payment), 0, 3, "."),
                                    value1: Math.round(firstYearlyCalculation.average_net_payment),
                                    valueForLabel1: format(Math.round(firstYearlyCalculation.average_net_payment), 0, 3, ".")
                                });

                                comparisonGross.push({
                                    name: secondYearlyCalculation.year,
                                    value2: Math.round(secondYearlyCalculation.average_gross_payment),
                                    valueForLabel2: format(Math.round(secondYearlyCalculation.average_gross_payment), 0, 3, "."),
                                    value1: Math.round(firstYearlyCalculation.average_gross_payment),
                                    valueForLabel1: format(Math.round(firstYearlyCalculation.average_gross_payment), 0, 3, ".")
                                });

                                comparisonInterestRate.push({
                                    name: secondYearlyCalculation.year,
                                    value2: format(secondYearlyCalculation.average_interest_rate * 100, 2),
                                    valueForLabel2: format(secondYearlyCalculation.average_interest_rate * 100, 2, 3, ".", ","),
                                    value1: format(firstYearlyCalculation.average_interest_rate * 100, 2),
                                    valueForLabel1: format(firstYearlyCalculation.average_interest_rate * 100, 2, 3, ".", ",")
                                });

                                comparisonMortgageAmount = getMortgageAmountComparison(
                                    firstYearlyCalculation,
                                    firstIndex,
                                    secondYearlyCalculation,
                                    secondIndex,
                                    comparisonMortgageAmount
                                );

                                isFirstValueAssigned = true;
                                //checks if it is the last index of second calculation
                                if (secondIndex === secondArray.length - 1) {
                                    lastIndexOfSecondForEach = true;
                                }
                            }
                        });
                        //It enters in the if bellow only if a matching year wasn't found in the firstComparisonCalculations.calculations array
                        if (!isFirstValueAssigned) {
                            comparisonNet.push({
                                name: secondYearlyCalculation.year,
                                value2: Math.round(secondYearlyCalculation.average_net_payment),
                                valueForLabel2: format(Math.round(secondYearlyCalculation.average_net_payment), 0, 3, ".")
                            });
                            comparisonGross.push({
                                name: secondYearlyCalculation.year,
                                value2: Math.round(secondYearlyCalculation.average_gross_payment),
                                valueForLabel2: format(Math.round(secondYearlyCalculation.average_gross_payment), 0, 3, ".")
                            });
                            comparisonInterestRate.push({
                                name: secondYearlyCalculation.year,
                                value2: format(secondYearlyCalculation.average_interest_rate * 100, 2),
                                valueForLabel2: format(secondYearlyCalculation.average_interest_rate * 100, 2, 3, ".", ",")
                            });

                            comparisonMortgageAmount = getMortgageAmountComparison(
                                undefined,
                                undefined,
                                secondYearlyCalculation,
                                secondIndex,
                                comparisonMortgageAmount
                            );
                        }
                    }
                );
            }

            let totalComparisonNet = {
                ifp: [
                    {
                        name: 1,
                        value: getState().comparison.firstComparisonCalculations.totals.ifp.net_payment,
                        valueForLabel: format(getState().comparison.firstComparisonCalculations.totals.ifp.net_payment, 0, 3, ".")
                    },
                    {
                        name: 2,
                        value: getState().comparison.secondComparisonCalculations.totals.ifp.net_payment,
                        valueForLabel: format(getState().comparison.secondComparisonCalculations.totals.ifp.net_payment, 0, 3, ".")
                    }
                ],
                duration: [
                    {
                        name: 1,
                        value: getState().comparison.firstComparisonCalculations.totals.duration.net_payment,
                        valueForLabel: format(getState().comparison.firstComparisonCalculations.totals.duration.net_payment, 0, 3, ".")
                    },
                    {
                        name: 2,
                        value: getState().comparison.secondComparisonCalculations.totals.duration.net_payment,
                        valueForLabel: format(getState().comparison.secondComparisonCalculations.totals.duration.net_payment, 0, 3, ".")
                    }
                ]
            };

            let totalComparisonGross = {
                ifp: [
                    {
                        name: 1,
                        value: getState().comparison.firstComparisonCalculations.totals.ifp.gross_payment,
                        valueForLabel: format(getState().comparison.firstComparisonCalculations.totals.ifp.gross_payment, 0, 3, ".")
                    },
                    {
                        name: 2,
                        value: getState().comparison.secondComparisonCalculations.totals.ifp.gross_payment,
                        valueForLabel: format(getState().comparison.secondComparisonCalculations.totals.ifp.gross_payment, 0, 3, ".")
                    }
                ],
                duration: [
                    {
                        name: 1,
                        value: getState().comparison.firstComparisonCalculations.totals.duration.gross_payment,
                        valueForLabel: format(getState().comparison.firstComparisonCalculations.totals.duration.gross_payment, 0, 3, ".")
                    },
                    {
                        name: 2,
                        value: getState().comparison.secondComparisonCalculations.totals.duration.gross_payment,
                        valueForLabel: format(getState().comparison.secondComparisonCalculations.totals.duration.gross_payment, 0, 3, ".")
                    }
                ]
            };

            let totalComparisonInterestRate = {
                ifp: [
                    {
                        name: 1,
                        value: format(getState().comparison.firstComparisonCalculations.totals.ifp.average_interest_rate * 100, 2),
                        valueForLabel: format(
                            getState().comparison.firstComparisonCalculations.totals.ifp.average_interest_rate * 100,
                            2,
                            3,
                            ".",
                            ","
                        )
                    },
                    {
                        name: 2,
                        value: format(getState().comparison.secondComparisonCalculations.totals.ifp.average_interest_rate * 100, 2),
                        valueForLabel: format(
                            getState().comparison.secondComparisonCalculations.totals.ifp.average_interest_rate * 100,
                            2,
                            3,
                            ".",
                            ","
                        )
                    }
                ],
                duration: [
                    {
                        name: 1,
                        value: format(getState().comparison.firstComparisonCalculations.totals.duration.average_interest_rate * 100, 2),
                        valueForLabel: format(
                            getState().comparison.firstComparisonCalculations.totals.duration.average_interest_rate * 100,
                            2,
                            3,
                            ".",
                            ","
                        )
                    },
                    {
                        name: 2,
                        value: format(getState().comparison.secondComparisonCalculations.totals.duration.average_interest_rate * 100, 2),
                        valueForLabel: format(
                            getState().comparison.secondComparisonCalculations.totals.duration.average_interest_rate * 100,
                            2,
                            3,
                            ".",
                            ","
                        )
                    }
                ]
            };
            
            dispatch({
                type: GET_COMPARISON_NET,
                payload: {
                    comparisonNet,
                    totalComparisonNet,
                    comparisonGross,
                    totalComparisonGross,
                    comparisonInterestRate,
                    totalComparisonInterestRate,
                    comparisonMortgageAmount
                }
            });
            resolve(comparisonNet);
        } catch (error) {
            reject(error);
        }
    });
};

export const getComparisonCalculations = isItFirst => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { comparison, auth } = getState();

        const log = isItFirst ? comparison.firstComparisonLog : comparison.secondComparisoLog;

        const nhg = log.calculations.is_nhg_possible ? !log.input.force_no_nhg : false;

        let queryParts = "[";

        log.input.parts.forEach((part, index) => {
            if (index !== 0) {
                queryParts += ",";
            }

            let iterestRatesPart = "";

            const interestRatesKeys = Object.keys(log.product.rates[index]);

            interestRatesKeys.forEach(rateKey => {
                //adding new line if there is already a rate
                if (iterestRatesPart.length > 0) {
                    iterestRatesPart += "\n";
                }
                //adding only the rates
                if ((rateKey.indexOf("nhg") > -1 || rateKey.indexOf("ltv") > -1) && log.product.rates[index][rateKey] !== null) {
                    iterestRatesPart += `${rateKey}: ${log.product.rates[index][rateKey]},`;
                }
            });

            queryParts += `
                    {
                        mortgage_amount: ${part.mortgage_amount},
                        interest_fixed_period: "${part.interest_fixed_period}",
                        duration: ${part.duration},
                        mortgage_type: ${part.mortgage_type},
                        interest_rates:{
                            ${iterestRatesPart}
                        }
                    }
                `;
        });

        queryParts += "]";

        const query = `query{
                mortgage_calculation(
                    mortgage_amount:${log.input.mortgage_amount},
                    house_value:${log.input.value_house},
                    nhg: ${nhg},
                    income_1:${log.input.incomes[0]},
                    ${log.input.incomes.length > 1 ? `income_2:${log.input.incomes[1]},` : ""}
                    start_date: "${log.input.start_date}"
                    interest_adjustment_ltv_mortgage_during_ifp: ${log.product.interest_adjustment_ltv_mortgage_during_ifp},
                    interest_adjustment_ltv_mortgage_during_ifp_provider:${
                        log.product.interest_adjustment_ltv_mortgage_during_ifp_provider
                    },
                    interest_adjustment_ltv_house_during_ifp:${log.product.interest_adjustment_ltv_house_during_ifp},
                    parts:${queryParts}) {
                    totals{
                        ifp{
                            redemption
                            start_month
                            interest_mortgage_amount
                            gross_payment
                            net_payment
                            average_interest_rate
                        }
                        duration{
                            redemption
                            start_month
                            interest_mortgage_amount
                            gross_payment
                            net_payment
                            average_interest_rate
                        }
                    }
                    calculations{
                        year
                        average_gross_payment
                        average_net_payment
                        average_interest_rate
                        monthly_calculations{
                            mortgage_amount
                            weighted_interest_rate
                            interest_rate_per_part{
                                part_1
                                part_2
                            }
                        }
                    }
                }
            }`;

        requestCalculation(query, auth.token)
            .then(response => {
                const calculations = response;

                if (isItFirst) {
                    dispatch({
                        type: GET_FIRST_COMPARISON_CALCULATIONS,
                        payload: calculations
                    });
                } else {
                    dispatch({
                        type: GET_SECOND_COMPARISON_CALCULATIONS,
                        payload: calculations
                    });
                }

                resolve(calculations);
            })
            .catch(error => {
                reject(error);

                console.error("GET_COMPARISON_CALCULATIONS::FAIL", error);
            });
    });
};
