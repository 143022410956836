import { IS_MOBILE } from "../Actions/cssActions/types";

const initialState = {
    isMobile: document.body.clientWidth <= 767,
    isUnder1200px: document.body.clientWidth <= 1199,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case IS_MOBILE:
            return {
                ...state,
                isMobile: action.payload.isMobile,
                isUnder1200px: action.payload.isUnder1200px,
            };
        default:
            return state;
    }
}
