import React from "react";
import { connect } from "react-redux";
import { mapStateToProps } from "../../redux";
import { Typography, Card, CardContent } from "@material-ui/core";
import logo from "../../Images/logo_hyponav_beta.svg";
import "./EmailSendStyles.css";

const EmailSendRegister = props => {
    const { lang } = props;
    return (
        <div className={"dom"}>
            <img src={logo} alt="Logo" style={{ width: "300px", margin: "40px 0" }} />
            <Card className={"card"}>
                <CardContent className={"card-content"}>
                    <Typography variant={"h2"} style={{ margin: "20px 0", textAlign: "center" }}>
                        {lang.email_sent.title}
                    </Typography>
                    <Typography style={{ margin: "20px 0" }}>{lang.email_sent.intro_register}</Typography>
                </CardContent>
            </Card>
        </div>
    );
};

export default connect(mapStateToProps)(EmailSendRegister);
