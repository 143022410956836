import React, { useState } from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { store } from "./Store/index";

import { Provider } from "react-redux";
import DialogProvider from "./Providers/DialogProvider";

import Router from "./Router/Router";

//polyfills
import "promise-polyfill/src/polyfill";
import "es6-object-assign/auto";

import "./Icons/style.css";
import "./App.css";

function App() {
    const [theme] = useState(
        createMuiTheme({
            typography: {
                useNextVariants: true,
            },
            palette: {
                primary: {
                    main: "#303f9f",
                },
                secondary: {
                    main: "#e05d22",
                },
                type: "light",
            },
            overrides: {
                MuiOutlinedInput: {
                    // Name of the component ⚛️ / style sheet
                    adornedEnd: {
                        paddingRight: 0,
                    },
                },
                MuiSvgIcon: {
                    root: {
                        cursor: "pointer",
                    },
                },
                MuiTooltip: {
                    tooltip: {
                        fontSize: 14,
                    },
                },
            },
        }),
    );

    return (
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <DialogProvider>
                    <Router />
                </DialogProvider>
            </Provider>
        </MuiThemeProvider>
    );
}

export default App;
