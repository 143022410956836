import React, { Fragment, useEffect } from "react";
import { Help } from "@material-ui/icons";
import { Typography, Radio, FormControlLabel, RadioGroup, Tooltip, Select, MenuItem } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { BootstrapInput } from "../../Components/Inputs/BootstrapInput";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";

import "../../Views/FirstTimeVisit/FirstTimeVisit.css";

function FirstTimeVisitContent(props) {
    const {
        id,
        title,
        textUnderTitle,
        radioQuestion,
        inputQuestion,
        state,
        setState,
        optionalState,
        setOptionalState,
        validation,
        lang,
        panelType,
        errorMessage,
        onMount,
        css,
        tooltip,
    } = props;

    useEffect(() => {
        if (onMount) onMount();

        return () => {
            if (id === "price-of-house") setOptionalState({ ...optionalState, value: state.value });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleMonthChange = (event, state) => {
        const newMonth = event.target.value;

        const currentDate = new Date();

        if (newMonth < currentDate.getMonth() + 1) {
            setState({ ...state, startDate: { ...state.startDate, month: newMonth, year: currentDate.getFullYear() + 1 } });
        } else {
            setState({ ...state, startDate: { ...state.startDate, month: newMonth, year: currentDate.getFullYear() } });
        }
    };

    const thousandSeparator = (number) => {
        const parts = number.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
    };

    return (
        <Fragment>
            <div className={"first-time-visit-title-wrapper"}>
                <Typography variant="h6" color="inherit" noWrap className={"first-time-visit-step-title"}>
                    {title}
                </Typography>
                <Typography className={"first-time-visit-step-info"}>{textUnderTitle}</Typography>
            </div>
            <div className={"first-time-visit-question"}>
                <div className={"first-time-visit-yes-no"}>
                    <div className={"first-time-visit-question-text"}>{radioQuestion}</div>
                    <div className={"first-time-visit-radio-button"}>
                        <RadioGroup
                            aria-label="position"
                            name="position"
                            value={String(state.isRadioTrue)}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    isRadioTrue: !state.isRadioTrue,
                                });
                            }}
                            row
                        >
                            <FormControlLabel value="false" control={<Radio />} label={lang.inputDataSteps.no} labelPlacement="end" />
                            <FormControlLabel value="true" control={<Radio />} label={lang.inputDataSteps.yes} labelPlacement="end" />
                        </RadioGroup>
                    </div>
                    <div className={"first-time-visit-tooltip"}>
                        <Tooltip title={tooltip} disableFocusListener disableTouchListener>
                            <Help style={{ color: props.theme.palette.secondary.main }} />
                        </Tooltip>
                    </div>
                </div>

                <div className={"first-time-visit-collapse"}>
                    {/* Mortgage Type panel */}
                    {panelType === "mortgageType" ? (
                        <div className={"first-time-visit-mortgage-type"}>
                            <div className={"first-time-visit-question-text"}>
                                {!state.isRadioTrue ? lang.inputDataSteps.example + " " + inputQuestion.toLowerCase() : inputQuestion}
                            </div>
                            <div className={"first-time-visit-radio-button"}>
                                {!state.isRadioTrue ? (
                                    <div id={id} style={{ width: "180px" }}>
                                        {state.value === "annuity" ? lang.type.mortgage_type_annuity : lang.type.mortgage_type_linear}
                                    </div>
                                ) : (
                                    <RadioGroup
                                        aria-label="position"
                                        name="position"
                                        value={state.value}
                                        onChange={(event) => {
                                            if (state.value === "annuity") {
                                                setState({
                                                    ...state,
                                                    value: "linear",
                                                });
                                            } else {
                                                setState({
                                                    ...state,
                                                    value: "annuity",
                                                });
                                            }
                                        }}
                                        row
                                    >
                                        <FormControlLabel
                                            value="annuity"
                                            control={<Radio />}
                                            label={lang.type.mortgage_type_annuity}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="linear"
                                            control={<Radio />}
                                            label={lang.type.mortgage_type_linear}
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                )}
                            </div>
                        </div>
                    ) : null}

                    {/* Input panels */}
                    {panelType === "input" ? (
                        <div className={"dialog-input"} style={{ justifyContent: "normal", marginTop: "32px" }}>
                            <div className={"first-time-visit-question-text"}>
                                <label htmlFor={id}>
                                    {!state.isRadioTrue ? lang.inputDataSteps.example + " " + inputQuestion.toLowerCase() : inputQuestion}
                                </label>
                            </div>
                            <div className={"input-field"} style={css.isMobile ? null : { width: "180px" }}>
                                <div>
                                    {!state.isRadioTrue ? (
                                        <div id={id} style={{ width: "180px" }}>
                                            {thousandSeparator(state.value)}
                                        </div>
                                    ) : (
                                        <input
                                            className={props.error || props.errorMessage ? "error" : ""}
                                            id={id}
                                            style={{ width: "180px" }}
                                            value={thousandSeparator(state.value)}
                                            onChange={(event) => {
                                                setState({
                                                    ...state,
                                                    value: validation(
                                                        parseInt(event.target.value.split(".").join("")),
                                                        state.min,
                                                        state.max,
                                                    ),
                                                });
                                            }}
                                        />
                                    )}

                                    {errorMessage !== "" ? (
                                        <Typography className={"input-error-desktop"}>{errorMessage}</Typography>
                                    ) : undefined}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {/* Start date panel */}
                    {panelType === "date" ? (
                        <div className={"dialog-input"} style={{ justifyContent: "normal", marginTop: "32px" }}>
                            <div className={"first-time-visit-question-text"}>
                                <label>
                                    {!state.isRadioTrue ? lang.inputDataSteps.example + " " + inputQuestion.toLowerCase() : inputQuestion}
                                </label>
                            </div>
                            <div style={{ display: "flex", width: "180px" }}>
                                {!state.isRadioTrue ? (
                                    <div id={id} style={{ width: "180px" }}>
                                        {`${state.startDate.day}-${state.startDate.month}-${state.startDate.year}`}
                                    </div>
                                ) : (
                                    <Fragment>
                                        <div className={"input-field"} style={{ width: "50px", marginRight: 5 }}>
                                            <input value={state.startDate.day} readOnly={true} />
                                        </div>
                                        <Select
                                            style={{ width: "90px", marginRight: 5 }}
                                            value={state.startDate.month}
                                            onChange={(event) => {
                                                handleMonthChange(event, state);
                                            }}
                                            input={<BootstrapInput name="month" id="month-input" />}
                                        >
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
                                                return (
                                                    <MenuItem key={month} value={month}>
                                                        {month}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <div className={"input-field"} style={{ width: "60px" }}>
                                            <input value={state.startDate.year} readOnly={true} />
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    ) : null}

                    {/* Select panel */}
                    {panelType === "select" ? (
                        <div className={"dialog-input"} style={{ justifyContent: "normal", marginTop: "32px" }}>
                            <div className={"first-time-visit-question-text"}>
                                <label>
                                    {!state.isRadioTrue ? lang.inputDataSteps.example + " " + inputQuestion.toLowerCase() : inputQuestion}
                                </label>
                            </div>
                            <div style={{ display: "flex", width: "180px" }}>
                                {!state.isRadioTrue ? (
                                    <div id={id} style={{ width: "180px" }}>
                                        {state.value}
                                    </div>
                                ) : (
                                    <Select
                                        style={{ width: "90px", marginRight: 5 }}
                                        value={state.value}
                                        onChange={(event) => {
                                            setState({ ...state, value: event.target.value });
                                        }}
                                        input={<BootstrapInput name="ifp-first" id="ifp-first-time" />}
                                    >
                                        {[1, 2, 3, 5, 6, 7, 10, 12, 15, 20, 25, 30].map((ifpValue) => {
                                            return (
                                                <MenuItem key={ifpValue} value={ifpValue}>
                                                    {ifpValue}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                )}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </Fragment>
    );
}

export default connect(mapStateToProps)(withTheme(FirstTimeVisitContent));
