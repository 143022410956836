import React, { Fragment } from "react";

import TooltipDialog from "../Components/Dialogs/TooltipDialog";

const DialogProvider = (props) => {
    const { children } = props;

    return (
        <Fragment>
            {children}

            <TooltipDialog />
        </Fragment>
    );
};

export default DialogProvider;
