import { connect } from "react-redux";
import { IconButton, Tooltip } from "@material-ui/core";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { mapStateToProps } from "../../../redux";

import "./ChartScrollButtonStyles.css";

function ChartScrollButton(props) {
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;

    const buttonDimensions = {
        height: 48,
        width: 48
    };

    const scrollChart = () => {
        const { chartDomRef, orientation } = props;

        const scrollAmount = orientation === "normal" ? 200 : -200;

        chartDomRef.current.scrollLeft += scrollAmount;
    };

    const calculateHeightOffset = () => {
        const { chartDomRef } = props;

        if (chartDomRef.current !== null) {
            return chartDomRef.current.clientHeight / 2;
        }
        return (200 - buttonDimensions.height) / 2;
    };

    const [isButtonVisible, setIsButtonVisible] = useState(props.orientation === "normal" ? true : false);
    const [heightOffset, setHeightOffset] = useState(calculateHeightOffset());

    const updateButton = event => {
        const { chartDomRef, orientation } = props;

        const dom = chartDomRef.current;
        if (dom !== null) {
            if (orientation === "normal") {
                setIsButtonVisible(!(dom.clientWidth + dom.scrollLeft === dom.scrollWidth));
            } else {
                setIsButtonVisible(!(dom.scrollLeft === 0));
            }
        }
    };

    useEffect(() => {
        const { chartDomRef } = props;

        if (chartDomRef.current !== null) {
            chartDomRef.current.addEventListener("scroll", updateButton);
            setHeightOffset(calculateHeightOffset());
        }
        return function cleanup() {
            if (chartDomRef.current !== null) {
                chartDomRef.current.removeEventListener("scroll", updateButton);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { theme, orientation, lang } = props;
    const { height, width } = buttonDimensions;

    return isButtonVisible && !isIE ? (
        <Tooltip
            title={orientation === "normal" ? lang.dashboard.graph_arrow_right : lang.dashboard.graph_arrow_left}
            disableFocusListener
            disableTouchListener
        >
            <IconButton
                className={classNames("chart-scroller", {
                    "button-left": orientation === "reversed",
                    "button-right": orientation === "normal"
                })}
                onClick={scrollChart}
                style={{ backgroundColor: theme.palette.secondary.main, color: "white", top: heightOffset, height, width }}
            >
                {orientation === "normal" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
        </Tooltip>
    ) : null;
}

ChartScrollButton.propTypes = {
    chartDomRef: PropTypes.object,
    orientation: PropTypes.oneOf(["reversed", "normal"])
};

export default connect(mapStateToProps)(withTheme(ChartScrollButton));
