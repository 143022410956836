export default {
    OPEN_DIALOG: "OPEN_DIALOG",
    CLOSE_DIALOG: "CLOSE_DIALOG",

    OPEN_SIDE_MENU: "OPEN_SIDE_MENU",
    CLOSE_SIDE_MENU: "CLOSE_SIDE_MENU",

    UI_REST: "UI_REST",

    UPDATE_IS_MOBILE: "UPDATE_IS_MOBILE",
};
