import { SELECT_CUSTOMER_TO_WORK_WITH, SELECT_ADVISOR_AS_CUSTOMER, RESET_CUSTOMER } from "./types";
import axios from "axios";

import { api } from "../../services";

export const setCustomer = (
    customerUuid,
    customerFirstName,
    customerPrefix,
    customerLastName,
    customerEmail,
    areLogsShared,
    areComparisonsShared
) => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: SELECT_CUSTOMER_TO_WORK_WITH,
                payload: {
                    customerUuid,
                    customerFirstName,
                    customerPrefix,
                    customerLastName,
                    customerEmail,
                    areLogsShared,
                    areComparisonsShared
                }
            });

            resolve(customerEmail);
        } catch (error) {
            reject(error);

            console.error("SELECT_CUSTOMER_TO_WORK_WITH::FAIL", error);
        }
    });
};

export const resetCustomer = () => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: RESET_CUSTOMER,
                payload: null
            });

            resolve(null);
        } catch (error) {
            reject(error);

            console.error("RESET_CUSTOMER::FAIL", error);
        }
    });
};

export const getAdvisorAsCustomer = token => dispatch => {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${api.url}/resources`,
            // url: `http://localhost:3000/v1/resources`,
            data: {
                query: `query{
                        get_advisor
                            {
                                first_name
                                email
                                uuid
                            }
                        }`
            },
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => {
                dispatch({
                    type: SELECT_ADVISOR_AS_CUSTOMER,
                    payload: res.data.data.get_advisor
                });
                resolve(res.data.data.get_advisor);
            })
            .catch(err => {
                dispatch({
                    type: SELECT_ADVISOR_AS_CUSTOMER,
                    payload: null
                });
                reject(err);
            });
    });
};
