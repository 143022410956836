import types from "./types";

/**
 * attaches a resize listener to the window
 *
 * @returns {Promise} resolves to void
 */
export const onResizeListener = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        try {
            window.addEventListener("resize", () => {
                const { isMobile } = getState().ui;

                const newIsMobile = window.innerWidth <= 767;

                if (newIsMobile !== isMobile) {
                    dispatch({
                        type: types.UPDATE_IS_MOBILE,
                        payload: window.innerWidth <= 767,
                    });
                }
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * opens the side menu
 *
 * @returns {Promise} resolves to void
 */
export const openSideMenu = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: types.OPEN_SIDE_MENU,
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * closes the side menu
 *
 * @returns {Promise} resolves to void
 */
export const closeSideMenu = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: types.CLOSE_SIDE_MENU,
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * opens the dialog with the passed @param name
 *
 * @param {String} name the dialog name
 *
 * @returns {Promise} resolves to void
 */
export const openDialog = (name, data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        try {
            const { dialogs } = getState().ui;

            if (!dialogs[name]) {
                reject("dialog/not-defined");
            }

            dispatch({
                type: types.OPEN_DIALOG,
                payload: { name, data: data || {} },
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * closes the dialog with the passed @param name
 *
 * @param {String} name the dialog name
 *
 * @returns {Promise} resolves to void
 */
export const closeDialog = (name) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        try {
            const { dialogs } = getState().ui;

            if (!dialogs[name]) {
                reject("dialog/not-defined");
            }

            dispatch({
                type: types.CLOSE_DIALOG,
                payload: name,
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * cleans up the notification store
 *
 * @returns {Promise} resolves to void
 */
export const resetUi = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            dispatch({
                type: types.UI_REST,
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};
