import React, { Component, Fragment } from "react";
import { CalculatorContext } from "./../../Contexts/CalculatorContext";
import HistoryCalculations from "./Components/HistoryCalculations";
import HistoryComparisons from "./Components/HistoryComparisons";
import Template from "../../Components/Template/Template";
import TemplateForCalculation from "../../Components/Template/TemplateForCalculation";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";
import "./HistoryStyles.css";

class History extends Component {
    static contextType = CalculatorContext;

    constructor(props) {
        super(props);

        const { lang } = props;

        document.title = lang.history.title_tag;

        this.state = {
            tabValue: "calculations",
        };
    }

    handleTabChange = (event, tabValue) => {
        this.setState({ tabValue: tabValue });
    };

    render() {
        const { logs, history, lang, auth, css } = this.props;
        const { tabValue } = this.state;

        return (
            <Template title={lang.history.title_tag} shouldIncludeSideBar={true} history={history} contentFullHeight={!logs.areLogsLoaded}>
                {auth.user.role === "advisor" ? <TemplateForCalculation /> : <Fragment />}
                <AppBar
                    position="static"
                    color="default"
                    className={"history-calculation-comparison-bar"}
                    style={
                        auth.user.role === "advisor" ? (css.isMobile ? { top: 88 } : { top: 96 }) : css.isMobile ? { top: 56 } : { top: 64 }
                    }
                >
                    <Tabs value={this.state.tabValue} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                        <Tab label={lang.history.tab_calculations} value="calculations" className={"tabs"} />
                        <Tab label={lang.history.tab_comparisons} value="comparisons" className={"tabs"} />
                    </Tabs>
                </AppBar>
                {tabValue === "calculations" ? <HistoryCalculations history={history} /> : <HistoryComparisons history={history} />}
            </Template>
        );
    }
}

export default connect(mapStateToProps)(History);
