import React, { Component, Fragment } from "react";
import classNames from "classnames";
import moment from "moment";
import { CalculatorContext } from "./../../../Contexts/CalculatorContext";
import { withStyles } from "@material-ui/core/styles";
import { Delete, Info, MoreVert } from "@material-ui/icons";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Typography,
    CircularProgress,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Menu,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Checkbox,
    IconButton
} from "@material-ui/core";
import MobileHistoryRow from "./../../../Components/HistoryRow/MobileHistoryRow";
import BottomSelectionLogBar from "./../../../Components/BottomSelectionLogBar/BottomSelectionLogBar";
import HistoryRow from "../../../Components/HistoryRow/HistoryRow";
import Draggable from "react-draggable";
import { mapStateToProps } from "../../../redux";
import { connect } from "react-redux";
import { getComparisons, getLog, deleteComparisonLocal, deleteComparison, unloadLog } from "../../../Actions/logActions/actions";
import { translateDate } from "../../../Actions/languageAction/actions";
import "./../HistoryStyles.css";

class HistoryComparisons extends Component {
    static contextType = CalculatorContext;

    constructor(props) {
        super(props);

        this.lastComparison = React.createRef();

        this.state = {
            menu: { anchorEl: null, comparisonParentIndex: null, comparisonIndex: null, comparisonID: null },
            selectedComparison: { element: null, comparisonParentIndex: null, comparisonIndex: null, comparisonID: 0 },
            loadingOlderComparisons: false,
            infoDialog: { isOpen: false, content: null },
            isDeleteDialogOpen: false,
            top: 0,
            height: 0,
            scroll: 0
        };
    }

    componentDidMount() {
        const { logs, auth, getComparisons, css, role } = this.props;

        if (logs.comparisons.length === 0) {
            if (role.selectedCustomer !== null || auth.user.role !== "advisor") {
                getComparisons(logs.comparisons, logs.offsetComparisons, auth, role);
            }
        }

        window.addEventListener("scroll", this.scrollEvent);

        if (css.isMobile) {
            const el = document.querySelector(".history-calculation-comparison-bar");
            this.setState({ top: el.offsetTop, height: el.offsetHeight });
        }
    }

    selectComparison = (event, parentIndex, comparisonIndex, comparisonID) => {
        const { selectedComparison } = this.state;

        const newObject =
            selectedComparison.comparisonID === comparisonID
                ? { element: null, comparisonParentIndex: null, comparisonIndex: null, comparisonID: null }
                : {
                      element: event.currentTarget,
                      comparisonParentIndex: parentIndex,
                      comparisonIndex: comparisonIndex,
                      comparisonID: comparisonID
                  };

        this.setState({ selectedComparison: newObject });
    };

    disselectComparison = callback => {
        this.setState(
            {
                selectedComparison: { element: null, comparisonParentIndex: null, comparisonIndex: null, comparisonID: null }
            },
            () => {
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    loadComparison = () => {
        const { logs, history } = this.props;

        logs.comparisons.forEach(comparisonsForDays => {
            comparisonsForDays.comparisons.forEach(comparison => {
                if (comparison.id === this.state.selectedComparison.comparisonID) {
                    history.push(`/vergelijken/details?id-${comparison.first_comparison.id}-${comparison.second_comparison.id}`);
                }
            });
        });
    };

    openInformationDialog = () => {
        const { logs } = this.props;

        const { menu } = this.state;

        this.setState({
            infoDialog: { isOpen: true, content: logs.comparisons[menu.comparisonParentIndex].comparisons[menu.comparisonIndex] }
        });
    };

    closeInformationDialog = () => {
        this.setState({ infoDialog: { isOpen: false, content: null } });
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollEvent);
    }

    scrollEvent = () => {
        if (this.lastComparison.current !== null && !this.state.loadingOlderComparisons && !this.props.logs.hasLoadedAllComparisons) {
            const windowHeight = window.innerHeight;

            if (window.scrollY + windowHeight > this.offsetEl(this.lastComparison.current).top) {
                this.setState({ loadingOlderComparisons: true }, () => {
                    const { auth, logs, getComparisons, role } = this.props;

                    getComparisons(logs.comparisons, logs.offsetComparisons, auth, role).then(() => {
                        this.setState({ loadingOlderComparisons: false });
                    });
                });
            }
        }

        this.setState({ scroll: window.scrollY });
    };

    offsetEl = el => {
        const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    };

    handleOpenMenu = (event, parentIndex, comparisonIndex, comparisonID) => {
        this.setState({
            menu: {
                anchorEl: event.currentTarget,
                comparisonParentIndex: parentIndex,
                comparisonIndex: comparisonIndex,
                comparisonID: comparisonID
            }
        });
    };

    handleCloseMenu = () => {
        this.setState({ menu: { anchorEl: null, comparisonParentIndex: null, comparisonIndex: null, comparisonID: null } });
    };

    deleteComparison = () => {
        const { logs, auth, deleteComparisonLocal, deleteComparison } = this.props;
        const { menu, selectedComparison } = this.state;

        deleteComparisonLocal(logs.comparisons, menu.comparisonParentIndex, menu.comparisonIndex).then(() => {
            if (selectedComparison.comparisonID !== null) {
                if (menu.comparisonID === selectedComparison.comparisonID) {
                    this.disselectComparison();
                }
            }
            deleteComparison(menu.comparisonID, auth);
        });

        this.handleCloseMenu();
    };

    deleteComparisonFromSelectionLogBar = () => {
        const { logs, auth, deleteComparisonLocal, deleteComparison } = this.props;
        const { selectedComparison } = this.state;

        deleteComparisonLocal(logs.comparisons, selectedComparison.comparisonParentIndex, selectedComparison.comparisonIndex).then(() => {
            deleteComparison(selectedComparison.comparisonID, auth);
            this.disselectComparison();
        });

        this.handleCloseMenu();
    };

    openDeleteDialog = () => {
        this.setState({ isDeleteDialogOpen: true });
    };

    closeDeleteDialog = () => {
        this.setState({ isDeleteDialogOpen: false });
    };

    render() {
        const { logs, lang, classes, css, role, auth } = this.props;
        const { menu, isDeleteDialogOpen } = this.state;

        return (
            <Fragment>
                <div
                    className={classNames("dom history-dom", {
                        "history-loader": !logs.areComparisonsLoaded
                    })}
                    style={{ top: "48px", position: "relative" }}
                >
                    {role.selectedCustomer === null && auth.user.role === "advisor" ? (
                        <Typography variant={"subtitle1"} style={{ padding: "6px" }}>
                            {`No history can be shown during the Demo Mode.\n If you want to see the history of a customer, please select the customer from the customer panel.`}
                        </Typography>
                    ) : !logs.areComparisonsLoaded ? (
                        <CircularProgress />
                    ) : logs.comparisons.length > 0 ? (
                        <Fragment>
                            {logs.comparisons.map((comparisonsForDays, index) => {
                                const today = new Date();

                                let formatedDate = comparisonsForDays.formatedDate;
                                formatedDate = translateDate(formatedDate, lang);

                                if (comparisonsForDays.date.getDate() === today.getDate()) {
                                    formatedDate = `${lang.history.date_prefix_today} - ` + formatedDate;
                                } else if (Math.abs(comparisonsForDays.date.getDate() - today.getDate()) === 1) {
                                    formatedDate = `${lang.history.date_prefix_yesterday} - ` + formatedDate;
                                }

                                return (
                                    <div className={"history-day"} key={index}>
                                        <Typography
                                            classes={{ root: classes.titleText }}
                                            style={{ marginTop: index === 0 ? 20 : 40, marginBottom: 10 }}
                                            variant={"h5"}
                                        >
                                            {formatedDate}
                                        </Typography>
                                        {comparisonsForDays.comparisons.map((comparison, comparisonIndex) => {
                                            return (
                                                <div key={comparisonIndex}>
                                                    <Paper
                                                        className={
                                                            this.state.selectedComparison.comparisonID === comparison.id
                                                                ? "comparison-header-row-selected"
                                                                : "comparison-header-row"
                                                        }
                                                    >
                                                        <div className={"comparison-header-cell"}>
                                                            <Checkbox
                                                                color={"secondary"}
                                                                checked={this.state.selectedComparison.comparisonID === comparison.id}
                                                                onClick={event => {
                                                                    this.selectComparison(event, index, comparisonIndex, comparison.id);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={"comparison-header-cell"}>
                                                            {moment(comparison.created_at, "x").format("HH:mm")}
                                                        </div>
                                                        <div className={"comparison-header-cell"}>
                                                            <IconButton
                                                                aria-owns={"menu"}
                                                                aria-haspopup="true"
                                                                onClick={event => {
                                                                    this.handleOpenMenu(event, index, comparisonIndex, comparison.id);
                                                                }}
                                                            >
                                                                <MoreVert />
                                                            </IconButton>
                                                        </div>
                                                    </Paper>
                                                    {css.isMobile ? (
                                                        // Mobile version
                                                        <Paper
                                                            className={
                                                                this.state.selectedComparison.comparisonID === comparison.id
                                                                    ? "comparison-body-selected"
                                                                    : "comparison-body"
                                                            }
                                                        >
                                                            <div className={"table-mobile"}>
                                                                <div className={"header-row"}>
                                                                    <Paper className={"paper-header-cell"}>
                                                                        <div className={"header-cell"} style={{ width: "25%" }}>
                                                                            Date
                                                                        </div>
                                                                        <div className={"header-cell"} style={{ width: "15%" }}>
                                                                            {lang.history.column_time_header}
                                                                        </div>
                                                                        <div className={"header-cell"} style={{ width: "15%" }}>
                                                                            {lang.history.column_check_header}
                                                                        </div>
                                                                        <div className={"header-cell"} style={{ width: "30%" }}>
                                                                            {lang.history.column_mortgage_amount_header}
                                                                        </div>
                                                                    </Paper>
                                                                </div>
                                                                <div className={"row-body"}>
                                                                    <MobileHistoryRow log={comparison.first_comparison} />
                                                                    <MobileHistoryRow
                                                                        log={comparison.second_comparison}
                                                                        LastRowRef={
                                                                            typeof logs.comparisons !== "undefined" &&
                                                                            typeof comparisonsForDays.comparisons !== "undefined"
                                                                                ? logs.comparisons.length - 1 === index &&
                                                                                  comparisonIndex ===
                                                                                      comparisonsForDays.comparisons.length - 1
                                                                                    ? this.lastComparison
                                                                                    : null
                                                                                : null
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Paper>
                                                    ) : (
                                                        // Desktop version
                                                        <Paper
                                                            className={
                                                                this.state.selectedComparison.comparisonID === comparison.id
                                                                    ? "comparison-body-selected"
                                                                    : "comparison-body"
                                                            }
                                                        >
                                                            <Table className="history-table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className={"small-item"}>Date</TableCell>
                                                                        <TableCell className={"smaller-item"}>
                                                                            {lang.history.column_time_header}
                                                                        </TableCell>
                                                                        <TableCell className={"smaller-item"}>
                                                                            {lang.history.column_check_header}
                                                                        </TableCell>
                                                                        <TableCell className={"small-item"} component="th" align="left">
                                                                            {lang.history.column_house_header}
                                                                        </TableCell>
                                                                        <TableCell className={"small-item"} component="th" align="left">
                                                                            {lang.history.column_mortgage_amount_header}
                                                                        </TableCell>
                                                                        <TableCell className={"smaller-item"} component="th" align="left">
                                                                            {lang.history.column_parts_header}
                                                                        </TableCell>
                                                                        <TableCell className={"small-item"} component="th" align="left">
                                                                            {lang.history.column_part_1_amount_header}
                                                                        </TableCell>
                                                                        <TableCell className={"smaller-item"} component="th" align="left">
                                                                            {lang.history.column_part_1_type_header}
                                                                        </TableCell>
                                                                        <TableCell className={"smaller-item"} component="th" align="left">
                                                                            {lang.history.column_part_1_interest_fixed_period_header}
                                                                        </TableCell>
                                                                        <TableCell className={"small-item"} component="th" align="left">
                                                                            {lang.history.column_part_2_amount_header}
                                                                        </TableCell>
                                                                        <TableCell className={"smaller-item"} component="th" align="left">
                                                                            {lang.history.column_part_2_type_header}
                                                                        </TableCell>
                                                                        <TableCell className={"smaller-item"} component="th" align="left">
                                                                            {lang.history.column_part_2_interest_fixed_period_header}
                                                                        </TableCell>
                                                                        <TableCell className={"small-item"} component="th" align="left">
                                                                            {lang.history.column_creator_header}
                                                                        </TableCell>
                                                                        <TableCell className={"large-item"} component="th" align="left">
                                                                            {lang.history.column_product_header}
                                                                        </TableCell>
                                                                        <TableCell className={"smaller-item"} component="th" align="left" />
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody className={"table-body"}>
                                                                    <HistoryRow log={comparison.first_comparison} />
                                                                    <HistoryRow
                                                                        log={comparison.second_comparison}
                                                                        LastRowRef={
                                                                            typeof logs.comparisons !== "undefined" &&
                                                                            typeof comparisonsForDays.comparisons !== "undefined"
                                                                                ? logs.comparisons.length - 1 === index &&
                                                                                  comparisonIndex ===
                                                                                      comparisonsForDays.comparisons.length - 1
                                                                                    ? this.lastComparison
                                                                                    : null
                                                                                : null
                                                                        }
                                                                    />
                                                                </TableBody>
                                                            </Table>
                                                        </Paper>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </Fragment>
                    ) : (
                        <Typography variant={"subtitle1"}>No Comparisons.</Typography>
                    )}
                </div>
                {this.state.selectedComparison.element !== null ? <div style={{ marginTop: 40 }} /> : undefined}
                <BottomSelectionLogBar
                    selectedLog={this.state.selectedComparison.element}
                    primaryAction={this.loadComparison}
                    primaryActionText={lang.history.bar_calculation_selected_button_view}
                    secondaryAction={this.openDeleteDialog}
                    secondaryActionText={lang.history.bar_calculation_selected_button_remove}
                    closeAction={this.disselectComparison}
                    text={css.isMobile ? "" : lang.history.bar_item_selected_comparison}
                />
                <Menu anchorEl={menu.anchorEl} id={"menu"} open={Boolean(menu.anchorEl)} onClose={this.handleCloseMenu}>
                    <MenuItem onClick={this.openInformationDialog}>
                        <ListItemIcon>
                            <Info />
                        </ListItemIcon>
                        <ListItemText inset primary={lang.history.row_menu_info} />
                    </MenuItem>
                    <MenuItem onClick={this.openDeleteDialog}>
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText inset primary={lang.history.row_menu_delete} />
                    </MenuItem>
                </Menu>
                <Dialog fullWidth={true} maxWidth={"md"} open={this.state.infoDialog.isOpen}>
                    <DialogTitle>Log information</DialogTitle>
                    <DialogContent>asdasda</DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={this.closeInformationDialog}>
                            ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isDeleteDialogOpen}
                    onClose={this.closeDeleteDialog}
                    PaperComponent={props => {
                        return (
                            <Draggable>
                                <Paper {...props} />
                            </Draggable>
                        );
                    }}
                    aria-labelledby="draggable-delete-dialog"
                >
                    <DialogTitle id="draggable-delete-dialog">{lang.history.modal_delete_comparison_title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{lang.history.modal_delete_comparison_question}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDeleteDialog} color="secondary">
                            {lang.history.bar_calculation_loaded_title_button_close}
                        </Button>
                        <Button
                            onClick={() => {
                                if (this.state.menu.anchorEl !== null) {
                                    this.deleteComparison();
                                } else {
                                    this.deleteComparisonFromSelectionLogBar();
                                }
                                this.closeDeleteDialog();
                            }}
                            variant="contained"
                            color="secondary"
                        >
                            {lang.history.bar_calculation_selected_button_remove}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const styles = theme => ({
    cardContent: {
        width: "100%",
        padding: "0 !important"
    },
    paper: {
        [theme.breakpoints.down(767)]: {
            width: "100%"
        }
    },
    titleText: {
        [theme.breakpoints.down(767)]: {
            fontSize: "1.2rem"
        }
    },
    tableHeader: {
        position: "fixed",
        width: "96%",
        zIndex: "2"
    }
});

export default connect(mapStateToProps, { getComparisons, getLog, deleteComparisonLocal, deleteComparison, unloadLog })(
    withStyles(styles)(HistoryComparisons)
);
