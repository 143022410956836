import React, { forwardRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Edit } from "@material-ui/icons";

const useStyles = makeStyles(() => {
    return {
        container: {
            display: "flex",
            alignItems: "center",
        },
        symbol: {
            display: "inline",
            paddingRight: 3,
        },
        value: {
            display: "inline",
        },
        valueWrapper: {
            display: "inline",
            marginLeft: 5,
        },
        icon: {
            padding: 5,
        },
        iconDisabled: {
            color: "lightgray",
        },
    };
});

const Label = forwardRef((props, ref) => {
    const { label, value, symbol, onClick, disabled, className } = props;

    const classes = useStyles();

    return (
        <div className={clsx(classes.container, className)} ref={ref}>
            {label ? <Typography className={classes.label}>{label}:</Typography> : null}

            <div className={classes.valueWrapper}>
                {symbol ? <Typography className={classes.symbol}>{symbol}</Typography> : null}
                <Typography className={classes.value}>{value}</Typography>
            </div>

            {onClick ? (
                <IconButton color={"secondary"} onClick={!disabled ? onClick : undefined} className={classes.icon}>
                    <Edit
                        className={clsx({
                            [disabled]: classes.iconDisabled,
                        })}
                    ></Edit>
                </IconButton>
            ) : null}
        </div>
    );
});

Label.defaultProps = {
    onClick: null,
    symbol: null,
    value: "",
    label: null,
    disabled: false,
};

Label.propTypes = {
    onClick: PropTypes.func,
    symbol: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Label;
