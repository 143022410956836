import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow, Checkbox, IconButton } from "@material-ui/core";
import { Warning, MoreVert, CheckCircle } from "@material-ui/icons";
import moment from "moment";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import "../../Views/History/HistoryStyles.css";

class HistoryRow extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    shouldComponentUpdate(prevProps, prevState) {
        const { lang, logs } = this.props;

        return prevProps.isSelected !== this.props.isSelected || prevProps.lang !== lang || prevProps.logs.logs !== logs.logs;
    }

    changeTypeLanguage = (value) => {
        const { lang } = this.props;

        return value === "annuity"
            ? lang.dashboard.input_mortgage_type_part_value_annuity
            : lang.dashboard.input_mortgage_type_part_value_linear;
    };

    getDate = (log) => {
        let date = moment(log.created_at, "x").format("DD:MM:YY");
        date = date.replace(/:/gi, "-");
        return date;
    };

    changeProductDescriptionLanguage = (productDescription) => {
        const { lang } = this.props;

        return productDescription === "Example product with the lowest rates"
            ? lang.dashboard.input_product_default_value
            : productDescription;
    };

    thousandSeparator = (number) => {
        const parts = number.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
    };

    render() {
        const { log, onClick, LastRowRef, isSelected, action, parentIndex, logIndex, css, auth, displayedOn, lang } = this.props;

        return css.isMobile ? (
            // Mobile version
            <Fragment>
                <div
                    ref={LastRowRef !== null ? LastRowRef : undefined}
                    style={onClick === undefined ? { paddingLeft: "5px", width: "25%" } : null}
                >
                    {onClick !== undefined ? (
                        <Checkbox
                            color={"secondary"}
                            checked={isSelected}
                            onClick={(event) => {
                                onClick(event, parentIndex, logIndex, log.id);
                            }}
                        />
                    ) : (
                        this.getDate(log)
                    )}
                </div>
                <div style={onClick === undefined ? { width: "15%" } : null}>{moment(log.created_at, "x").format("HH:mm")}</div>
                <div style={onClick === undefined ? { width: "15%" } : null}>
                    {log.input.mortgage_amount < log.calculations.max_mortgage_from_income &&
                    log.input.mortgage_amount <= log.input.value_house ? (
                        <CheckCircle className={"icon-check"} />
                    ) : (
                        <Warning className={"icon-warning"} />
                    )}
                </div>
                <div style={onClick === undefined ? { width: "30%" } : null}>{this.thousandSeparator(log.input.mortgage_amount)}</div>
            </Fragment>
        ) : (
            //Desktop Version
            <TableRow
                className={isSelected ? "history-row" : ""}
                hover
                onClick={
                    onClick !== undefined
                        ? (event) => {
                              onClick(event, parentIndex, logIndex, log.id);
                          }
                        : null
                }
                component={(props) => {
                    return (
                        <tr
                            className={props.className}
                            onClick={onClick !== undefined ? props.onClick : null}
                            ref={LastRowRef !== null ? LastRowRef : undefined}
                        >
                            {displayedOn === "ComparisonDetails" ? (
                                <TableCell>
                                    <div className={"comparison-details-color-box"}>
                                        <div
                                            style={
                                                logIndex === 1
                                                    ? { backgroundColor: this.props.theme.palette.primary.main, width: 20, height: 20 }
                                                    : { backgroundColor: "#448AFF", width: 20, height: 20 }
                                            }
                                        ></div>
                                    </div>
                                </TableCell>
                            ) : (
                                <Fragment />
                            )}
                            <TableCell
                                padding="checkbox"
                                style={
                                    onClick === undefined
                                        ? displayedOn === "ComparisonDetails"
                                            ? { minWidth: "69px" }
                                            : { paddingLeft: "5px", minWidth: "69px" }
                                        : null
                                }
                            >
                                {onClick !== undefined ? <Checkbox color={"secondary"} checked={isSelected} /> : this.getDate(log)}
                            </TableCell>
                            <TableCell align="left">{moment(log.created_at, "x").format("HH:mm")}</TableCell>
                            <TableCell align="left">
                                {log.input.mortgage_amount < log.calculations.max_mortgage_from_income &&
                                log.input.mortgage_amount <= log.input.value_house ? (
                                    <CheckCircle className={"icon-check"} />
                                ) : (
                                    <Warning className={"icon-warning"} />
                                )}
                            </TableCell>
                            <TableCell align="left">
                                {log.input.purchase_price_house !== null ? this.thousandSeparator(log.input.purchase_price_house) : "-"}
                            </TableCell>
                            <TableCell align="left">{this.thousandSeparator(log.input.mortgage_amount)}</TableCell>
                            <TableCell align="left">{log.input.parts.length}</TableCell>
                            <TableCell align="left">{this.thousandSeparator(log.input.parts[0].mortgage_amount)}</TableCell>
                            <TableCell align="left">{this.changeTypeLanguage(log.input.parts[0].mortgage_type)}</TableCell>
                            <TableCell align="left">{log.input.parts[0].interest_fixed_period}</TableCell>
                            <TableCell align="left">
                                {log.input.parts.length > 1 ? this.thousandSeparator(log.input.parts[1].mortgage_amount) : "-"}
                            </TableCell>
                            <TableCell align="left">
                                {log.input.parts.length > 1 ? this.changeTypeLanguage(log.input.parts[1].mortgage_type) : "-"}
                            </TableCell>
                            <TableCell align="left">
                                {log.input.parts.length > 1 ? log.input.parts[1].interest_fixed_period : "-"}
                            </TableCell>
                            <TableCell align="left">
                                {log.is_mine
                                    ? lang.history.column_creator_option_me
                                    : auth.user.role === "advisor"
                                    ? lang.history.column_creator_option_customer_advisor_account
                                    : lang.history.column_creator_option_advisor_customer_account}
                            </TableCell>
                            <TableCell align="left">{this.changeProductDescriptionLanguage(log.product.description)}</TableCell>
                            {onClick !== undefined ? (
                                <TableCell align="left">
                                    <IconButton
                                        aria-owns={"menu"}
                                        aria-haspopup="true"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            if (action !== null) {
                                                action(event, parentIndex, logIndex, log.id);
                                            }
                                        }}
                                    >
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            ) : (
                                <TableCell align="left" />
                            )}
                        </tr>
                    );
                }}
            />
        );
    }
}

HistoryRow.propTypes = {
    isSelected: PropTypes.bool,
    log: PropTypes.object.isRequired,
    parentIndex: PropTypes.number,
    logIndex: PropTypes.number,
    action: PropTypes.func,
    LastRowRef: PropTypes.object,
    onClick: PropTypes.func,
};

HistoryRow.defaultProps = {
    isSelected: false,
    action: null,
    LastRowRef: null,
};

export default connect(mapStateToProps)(withTheme(HistoryRow));
