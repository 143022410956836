import React, { Fragment, useContext, useEffect } from "react";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";
import {
    Button,
    Paper,
    Stepper,
    Step,
    StepLabel,
    Typography,
    MobileStepper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import Draggable from "react-draggable";

import { createLog, getLastLog, checkIfFirstTimeUser } from "../../Actions/logActions/actions";
import { getAdvisorAsCustomer } from "../../Actions/roleActions/actions";

import Template from "../../Components/Template/Template";
import { CalculatorContext } from "../../Contexts/CalculatorContext";
import FirstTimeVisitContent from "../../Components/FirstTimeVisitContent/FirstTimeVisitContent";
import FirstTimeVisitIncomeContent from "../../Components/FirstTimeVisitContent/FirstTimeVisitIncomeContent";

import "./FirstTimeVisit.css";

function getSteps(lang) {
    return [
        lang.inputDataSteps.start_1,
        lang.inputDataSteps.price_2,
        lang.inputDataSteps.value_3,
        lang.inputDataSteps.investment_4,
        lang.inputDataSteps.start_5,
        lang.inputDataSteps.mortgage_type_6,
        lang.inputDataSteps.interest_fixed_7,
        lang.inputDataSteps.income_8,
        lang.inputDataSteps.summary_9,
    ];
}

function getStepContent(
    stepIndex,
    props,
    purchasePriceHouse,
    setPurchasePriceHouse,
    valueHouse,
    setValueHouse,
    investment,
    setInvestment,
    startDate,
    setStartDate,
    mortgageType,
    setMortgageType,
    interestFixedPeriod,
    setInterestFixedPeriod,
    errorMessage,
    setErrorMessage,
    lang,
    context,
) {
    const thousandSeparator = (number) => {
        const parts = number.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
    };

    const checkPurchasePriceValueHouse = () => {
        return new Promise((resolve, reject) => {
            try {
                let check = false;
                if (!purchasePriceHouse.isRadioTrue) {
                    check = true;
                    if (valueHouse.isRadioTrue) {
                        context.valueHouse.setValue(valueHouse.value, () => {
                            context.purchasePriceHouse.setValue(
                                valueHouse.value,
                                () => {
                                    context.functions.nhg.default().then((data) => {
                                        setInvestment({ ...investment, value: data.required_savings, min: data.required_savings });
                                    });
                                },
                                true,
                            );
                        });
                        setPurchasePriceHouse({ ...purchasePriceHouse, value: valueHouse.value });
                    }
                }
                if (!valueHouse.isRadioTrue) {
                    check = true;
                    if (purchasePriceHouse.isRadioTrue) {
                        context.purchasePriceHouse.setValue(purchasePriceHouse.value, () => {
                            context.valueHouse.setValue(
                                purchasePriceHouse.value,
                                () => {
                                    context.functions.nhg.default().then((data) => {
                                        setInvestment({ ...investment, value: data.required_savings, min: data.required_savings });
                                    });
                                },
                                true,
                            );
                        });
                        setValueHouse({ ...valueHouse, value: purchasePriceHouse.value });
                    }
                }
                if (!valueHouse.isRadioTrue && !purchasePriceHouse.isRadioTrue) {
                    context.purchasePriceHouse.setValue(
                        290000,
                        () => {
                            context.valueHouse.setValue(
                                290000,
                                () => {
                                    context.functions.nhg.default().then((data) => {
                                        setInvestment({ ...investment, value: data.required_savings, min: data.required_savings });
                                    });
                                },
                                true,
                            );
                        },
                        true,
                    );
                }
                resolve(check);
            } catch (error) {
                reject(error);
            }
        });
    };

    const validation = (value, minValue, maxValue) => {
        const parsedValue = parseInt(value);
        const newValue = isNaN(parsedValue) ? 0 : parsedValue;
        let error = false;

        if (minValue !== undefined) {
            if (newValue < minValue) {
                setErrorMessage(`Minimal ${thousandSeparator(minValue)}`);
                error = true;
            }
        }
        if (maxValue !== undefined) {
            if (newValue > maxValue) {
                setErrorMessage(`Maximum ${thousandSeparator(maxValue)}`);
                error = true;
            }
        }
        if (!error) {
            setErrorMessage("");
        }

        return newValue;
    };

    switch (stepIndex) {
        case 0:
            return (
                <Fragment>
                    <Typography variant="h6" color="inherit" noWrap className={"first-time-visit-step-title"}>
                        {lang.inputDataSteps.step_1.title}
                    </Typography>
                    <Typography className={"first-time-visit-step-info"}>{lang.inputDataSteps.step_1.intro_par_1}</Typography>
                </Fragment>
            );
        case 1:
            return (
                <FirstTimeVisitContent
                    id={"price-of-house"}
                    title={lang.inputDataSteps.step_2.title}
                    textUnderTitle={lang.inputDataSteps.step_2.intro_par_1}
                    radioQuestion={lang.inputDataSteps.step_2.purchase_price_known_label}
                    inputQuestion={lang.inputDataSteps.step_2.purchase_price_label}
                    tooltip={lang.inputDataSteps.step_2.purchase_price_known_help}
                    state={purchasePriceHouse}
                    setState={setPurchasePriceHouse}
                    optionalState={valueHouse}
                    setOptionalState={setValueHouse}
                    validation={validation}
                    //change translation
                    errorMessage={errorMessage}
                    onMount={() => {
                        validation(purchasePriceHouse.value, purchasePriceHouse.min, purchasePriceHouse.max);
                    }}
                    panelType={"input"}
                />
            );
        case 2:
            return (
                <FirstTimeVisitContent
                    id={"value-of-house"}
                    title={lang.inputDataSteps.step_3.title}
                    textUnderTitle={lang.inputDataSteps.step_3.intro_par_1}
                    radioQuestion={lang.inputDataSteps.step_3.value_house_known_label}
                    inputQuestion={lang.inputDataSteps.step_3.value_house_label}
                    tooltip={lang.inputDataSteps.step_3.value_house_known_help}
                    state={valueHouse}
                    setState={setValueHouse}
                    validation={validation}
                    errorMessage={errorMessage}
                    onMount={() => {
                        validation(valueHouse.value, valueHouse.min, valueHouse.max);
                    }}
                    panelType={"input"}
                />
            );
        case 3:
            return (
                <FirstTimeVisitContent
                    id={"investment"}
                    title={lang.inputDataSteps.step_4.title}
                    textUnderTitle={lang.inputDataSteps.step_4.intro_par_1}
                    radioQuestion={lang.inputDataSteps.step_4.investment_known_label}
                    inputQuestion={lang.inputDataSteps.step_4.investment_label}
                    tooltip={lang.inputDataSteps.step_4.investment_known_help}
                    state={investment}
                    setState={setInvestment}
                    validation={validation}
                    errorMessage={errorMessage}
                    onMount={() => {
                        checkPurchasePriceValueHouse().then((check) => {
                            if (!check) {
                                context.purchasePriceHouse.setValue(purchasePriceHouse.value, () => {
                                    context.valueHouse.setValue(
                                        valueHouse.value,
                                        () => {
                                            context.functions.nhg.default().then((data) => {
                                                setInvestment({ ...investment, value: data.required_savings, min: data.required_savings });
                                            });
                                        },
                                        true,
                                    );
                                });
                            }
                        });
                    }}
                    panelType={"input"}
                />
            );
        case 4:
            return (
                <FirstTimeVisitContent
                    id={"start-date"}
                    title={lang.inputDataSteps.step_5.title}
                    textUnderTitle={lang.inputDataSteps.step_5.intro_par_1}
                    radioQuestion={lang.inputDataSteps.step_5.start_date_known_label}
                    inputQuestion={lang.inputDataSteps.step_5.start_date_label}
                    tooltip={lang.inputDataSteps.step_5.start_date_known_help}
                    state={startDate}
                    setState={setStartDate}
                    validation={validation}
                    panelType={"date"}
                />
            );
        case 5:
            return (
                <FirstTimeVisitContent
                    id={"mortgage-type"}
                    title={lang.inputDataSteps.step_6.title}
                    textUnderTitle={lang.inputDataSteps.step_6.intro_par_1}
                    radioQuestion={lang.inputDataSteps.step_6.mortgage_type_known_label}
                    inputQuestion={lang.inputDataSteps.step_6.mortgage_type_label}
                    tooltip={lang.inputDataSteps.step_6.mortgage_type_known_help}
                    state={mortgageType}
                    setState={setMortgageType}
                    panelType={"mortgageType"}
                />
            );
        case 6:
            return (
                <FirstTimeVisitContent
                    id={"ifp"}
                    title={lang.inputDataSteps.step_7.title}
                    textUnderTitle={lang.inputDataSteps.step_7.intro_par_1}
                    radioQuestion={lang.inputDataSteps.step_7.interest_fixed_known_label}
                    inputQuestion={lang.inputDataSteps.step_7.interest_fixed_label}
                    tooltip={lang.inputDataSteps.step_7.interest_fixed_known_help}
                    state={interestFixedPeriod}
                    setState={setInterestFixedPeriod}
                    panelType={"select"}
                />
            );
        case 7:
            return (
                <FirstTimeVisitIncomeContent
                    id={"applicants"}
                    title={lang.inputDataSteps.step_8.title}
                    textUnderTitle={lang.inputDataSteps.step_8.intro_par_1}
                    radioQuestion={lang.inputDataSteps.step_8.applicant_is_1_label}
                    inputQuestion={lang.inputDataSteps.step_8.income_1_label}
                />
            );
        default:
            return "Unknown stepIndex";
    }
}

function FirstTimeVisit(props) {
    const currentDate = new Date();
    const context = useContext(CalculatorContext);

    const [activeStep, setActiveStep] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isCancelDialogOpen, setIsCancelDialogOpen] = React.useState(false);

    const [purchasePriceHouse, setPurchasePriceHouse] = React.useState({
        value: 290000,
        isRadioTrue: false,
        min: 50000,
        max: 5000000,
    });
    const [valueHouse, setValueHouse] = React.useState({
        value: 290000,
        isRadioTrue: false,
        min: 50000,
        max: 5000000,
    });
    const [investment, setInvestment] = React.useState({
        value: 0,
        isRadioTrue: false,
        min: 0,
        max: 5000000,
    });
    const [startDate, setStartDate] = React.useState({
        startDate: {
            day: 1,
            month: currentDate.getMonth() === 11 ? 1 : currentDate.getMonth() + 2,
            year: currentDate.getMonth() === 11 ? currentDate.getFullYear() + 1 : currentDate.getFullYear(),
        },
        isRadioTrue: false,
    });
    const [mortgageType, setMortgageType] = React.useState({
        value: "annuity",
        isRadioTrue: false,
    });
    const [interestFixedPeriod, setInterestFixedPeriod] = React.useState({
        value: 10,
        isRadioTrue: false,
    });

    const [changedRequiredSavings, setChangedRequiredSavings] = React.useState(false);
    const [changedStartDate, setChangedStartDate] = React.useState(false);
    const [changedMortgageType, setChangedMortgageType] = React.useState(false);
    const [changedInterestFixedPeriod, setChangedInterestFixedPeriod] = React.useState(false);

    const steps = getSteps(props.lang);

    useEffect(() => {
        if (activeStep === 0) setErrorMessage("");
    }, [activeStep]);

    useEffect(() => {
        const { functions } = context;
        functions.createDefaultState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { auth, checkIfFirstTimeUser } = props;
        const { functions } = context;

        if (changedRequiredSavings && changedStartDate && changedMortgageType && changedInterestFixedPeriod) {
            let newProduct = {};

            props.getAdvisorAsCustomer(auth.token).then((res) => {
                return functions
                    .requestProduct(null)
                    .then((newProductProps) => {
                        newProduct = newProductProps;
                        return functions.requestCalculation();
                    })
                    .then(() => {
                        return functions.requestMaxMortgageFromIncomeCalculation();
                    })
                    .then((newMaxMortgageIncome) => {
                        props
                            .createLog(
                                auth,
                                {
                                    ...context,
                                    product: newProduct,
                                    maxMortgageAmountIncome: newMaxMortgageIncome,
                                },
                                { selectedAdvisor: res },
                            )
                            .then(() => {
                                checkIfFirstTimeUser(auth);
                                //Remove before push
                                // props.history.push("/dashboard");
                                //Remove until here
                            });
                    });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changedRequiredSavings, changedStartDate, changedMortgageType, changedInterestFixedPeriod]);

    const thousandSeparator = (number) => {
        const parts = number.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCancel = () => {
        const { auth, checkIfFirstTimeUser } = props;
        const { functions } = context;

        functions.loadFromDefaultState().then(() => {
            let newProduct = {};

            props.getAdvisorAsCustomer(auth.token).then((res) => {
                return functions
                    .requestProduct(null)
                    .then((newProductProps) => {
                        newProduct = newProductProps;
                        return functions.requestCalculation();
                    })
                    .then(() => {
                        return functions.requestMaxMortgageFromIncomeCalculation();
                    })
                    .then((newMaxMortgageIncome) => {
                        props
                            .createLog(
                                auth,
                                {
                                    ...context,
                                    product: newProduct,
                                    maxMortgageAmountIncome: newMaxMortgageIncome,
                                },
                                { selectedAdvisor: res },
                            )
                            .then(() => {
                                checkIfFirstTimeUser(auth);
                                //Remove before push
                                // props.history.push("/dashboard");
                                //Remove until here
                            });
                    });
            });
        });
    };

    const handleGetStarted = () => {
        if (investment.isRadioTrue) {
            context.requiredSavings.setValue(investment.value, () => {
                setChangedRequiredSavings(true);
            });
        } else {
            setChangedRequiredSavings(true);
        }
        if (startDate.isRadioTrue) {
            context.startDate.setValue(startDate.startDate.day, startDate.startDate.month, startDate.startDate.year).then(() => {
                setChangedStartDate(true);
            });
        } else {
            setChangedStartDate(true);
        }
        if (mortgageType.isRadioTrue) {
            context.parts.setPartMortgageType(mortgageType.value, 0, () => {
                setChangedMortgageType(true);
            });
        } else {
            setChangedMortgageType(true);
        }
        if (interestFixedPeriod.isRadioTrue) {
            context.parts.setPartInterestFixedPeriod(interestFixedPeriod.value, 0, () => {
                setChangedInterestFixedPeriod(true);
            });
        } else {
            setChangedInterestFixedPeriod(true);
        }
    };

    const { css, lang } = props;

    return (
        <Template title={lang.inputDataSteps.top_bar_title} history={props.history}>
            <div className={"wrapper-first-time-visit"}>
                <Paper className={"paper-first-time-visit"}>
                    {css.isMobile ? null : (
                        <Stepper activeStep={activeStep} alternativeLabel className={"first-time-visit-stepper"}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    )}
                    <div>
                        {activeStep === steps.length - 1 ? (
                            <div className={"first-time-final-step"}>
                                <div className={"stepper-content-final-step"}>
                                    <div className={"first-time-visit-title-wrapper"}>
                                        <Typography variant="h6" color="inherit" noWrap className={"first-time-visit-step-title"}>
                                            {lang.inputDataSteps.step_9.title}
                                        </Typography>
                                        <Typography className={"first-time-visit-step-info"}>
                                            {lang.inputDataSteps.step_9.intro_par_1}
                                        </Typography>
                                    </div>
                                    <div className={"first-time-visit-final-page"}>
                                        <div className={"first-time-visit-final-text-row"}>
                                            <div className={"first-time-visit-final-text"}>
                                                {lang.inputDataSteps.step_9.purchase_price_label}
                                            </div>
                                            <div className={"first-time-visit-final-result"}>
                                                {thousandSeparator(context.purchasePriceHouse.value)}
                                            </div>
                                        </div>

                                        <div className={"first-time-visit-final-text-row"}>
                                            <div className={"first-time-visit-final-text"}>{lang.inputDataSteps.step_9.value_label}</div>
                                            <div className={"first-time-visit-final-result"}>
                                                {thousandSeparator(context.valueHouse.value)}
                                            </div>
                                        </div>

                                        <div className={"first-time-visit-final-text-row"}>
                                            <div className={"first-time-visit-final-text"}>
                                                {lang.inputDataSteps.step_9.investment_label}
                                            </div>
                                            <div className={"first-time-visit-final-result"}>{thousandSeparator(investment.value)}</div>
                                        </div>

                                        <div className={"first-time-visit-final-text-row"}>
                                            <div className={"first-time-visit-final-text"}>
                                                {lang.inputDataSteps.step_9.start_date_label}
                                            </div>
                                            <div className={"first-time-visit-final-result"}>
                                                {`${startDate.startDate.day}-${startDate.startDate.month}-${startDate.startDate.year}`}
                                            </div>
                                        </div>

                                        <div className={"first-time-visit-final-text-row"}>
                                            <div className={"first-time-visit-final-text"}>
                                                {lang.inputDataSteps.step_9.mortgage_type_label}
                                            </div>
                                            <div className={"first-time-visit-final-result"}>{mortgageType.value}</div>
                                        </div>

                                        <div className={"first-time-visit-final-text-row"}>
                                            <div className={"first-time-visit-final-text"}>
                                                {lang.inputDataSteps.step_9.interest_fixed_label}
                                            </div>
                                            <div className={"first-time-visit-final-result"}>{interestFixedPeriod.value}</div>
                                        </div>

                                        <div className={"first-time-visit-final-text-row"}>
                                            <div className={"first-time-visit-final-text"}>{`${
                                                lang.inputDataSteps.step_9.income_applicant_1_label
                                            }${context.applicants.incomes.length === 1 ? "" : " 1"}`}</div>
                                            <div className={"first-time-visit-final-result"}>
                                                {thousandSeparator(context.applicants.incomes[0].value)}
                                            </div>
                                        </div>

                                        {context.applicants.incomes.length === 2 ? (
                                            <div className={"first-time-visit-final-text-row"}>
                                                <div
                                                    className={"first-time-visit-final-text"}
                                                >{`${lang.inputDataSteps.step_9.income_applicant_1_label} 2`}</div>
                                                <div className={"first-time-visit-final-result"}>
                                                    {thousandSeparator(context.applicants.incomes[1].value)}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                {css.isMobile ? null : (
                                    <div className={"stepper-content-final-buttons"}>
                                        <div>
                                            <Button
                                                color="secondary"
                                                onClick={() => {
                                                    setIsCancelDialogOpen(true);
                                                }}
                                            >
                                                {lang.inputDataSteps.button_cancel}
                                            </Button>
                                        </div>
                                        <div>
                                            <Button disabled={activeStep === 0} onClick={handleBack} color="secondary">
                                                {lang.inputDataSteps.button_back}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleGetStarted}
                                                disabled={errorMessage !== ""}
                                            >
                                                {lang.inputDataSteps.button_start}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className={"stepper-content"}>
                                {getStepContent(
                                    activeStep,
                                    props,
                                    purchasePriceHouse,
                                    setPurchasePriceHouse,
                                    valueHouse,
                                    setValueHouse,
                                    investment,
                                    setInvestment,
                                    startDate,
                                    setStartDate,
                                    mortgageType,
                                    setMortgageType,
                                    interestFixedPeriod,
                                    setInterestFixedPeriod,
                                    errorMessage,
                                    setErrorMessage,
                                    lang,
                                    context,
                                )}
                            </div>
                        )}
                    </div>
                    {css.isMobile ? null : activeStep !== steps.length - 1 ? (
                        <div className={"stepper-content-buttons"}>
                            <div>
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setIsCancelDialogOpen(true);
                                    }}
                                >
                                    {lang.inputDataSteps.button_cancel}
                                </Button>
                            </div>
                            <div>
                                <Button disabled={activeStep === 0} onClick={handleBack} color="secondary">
                                    {lang.inputDataSteps.button_back}
                                </Button>
                                <Button variant="contained" color="secondary" onClick={handleNext} disabled={errorMessage !== ""}>
                                    {lang.inputDataSteps.button_next}
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </Paper>
                {css.isMobile ? (
                    <Fragment>
                        <div className={"first-time-visit-padding-under-paper"}></div>
                        <Paper className={"first-time-visit-mobile-stepper-paper"}>
                            <MobileStepper
                                variant="dots"
                                steps={9}
                                position="static"
                                activeStep={activeStep}
                                className={"first-time-visit-mobile-stepper"}
                                nextButton={
                                    activeStep === steps.length - 1 ? (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleGetStarted}
                                            disabled={errorMessage !== ""}
                                        >
                                            {lang.inputDataSteps.button_start}
                                        </Button>
                                    ) : (
                                        <Button variant="contained" color="secondary" onClick={handleNext} disabled={errorMessage !== ""}>
                                            {lang.inputDataSteps.button_next}
                                        </Button>
                                    )
                                }
                                backButton={
                                    activeStep === 0 ? (
                                        <div>
                                            <Button
                                                color="secondary"
                                                onClick={() => {
                                                    setIsCancelDialogOpen(true);
                                                }}
                                            >
                                                {lang.inputDataSteps.button_cancel}
                                            </Button>
                                        </div>
                                    ) : (
                                        <Button disabled={activeStep === 0} color="secondary" onClick={handleBack}>
                                            {lang.inputDataSteps.button_back}
                                        </Button>
                                    )
                                }
                            />
                        </Paper>
                    </Fragment>
                ) : null}
            </div>
            <Dialog
                open={isCancelDialogOpen}
                onClose={() => {
                    setIsCancelDialogOpen(false);
                }}
                PaperComponent={(props) => {
                    return (
                        <Draggable>
                            <Paper {...props} />
                        </Draggable>
                    );
                }}
                aria-labelledby="draggable-delete-dialog"
            >
                <DialogTitle id="draggable-delete-dialog">Skip the first time visit</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to skip the first time visit.</DialogContentText>
                    <DialogContentText>If you decide to continue, you will be presented with an example calculation.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setIsCancelDialogOpen(false);
                        }}
                        color="secondary"
                    >
                        {lang.history.bar_calculation_loaded_title_button_close}
                    </Button>
                    <Button onClick={handleCancel} variant="contained" color="secondary">
                        {"Skip"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Template>
    );
}

export default connect(mapStateToProps, {
    createLog,
    getLastLog,
    getAdvisorAsCustomer,
    checkIfFirstTimeUser,
})(withTheme(FirstTimeVisit));
